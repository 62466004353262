
const messages = {
    "en":{
      menu_1:"Home",
      menu_2:"Trade",
      menu_3:"My",
      title1:'Currency',
      title2:"Latest price",
      title3:"Partners",
      title4:'Language',
      title5:'Home',
    welcome: 'Welcome',
    hello: 'Hello',
    title6:'Available balance',
    title7:'Daily receive limit',
    title8:'Monthly receive limit',
    title9:'Deposit',
    title10:'Withdraw',
    title11:"Internal Transfer",
    title12:'Membership Upgrade',
    title13:"Reference annualized income",
    title14:"Investitionsperiode",
    title15:"Starting quantity",
    title16:" Participate now",
    title17:" Platform records ",
    title18:"withdraw",
    title19:"recharge",
    title20:"My",
    title21:"My assets",
    title22:"Deposit",
    title23:"Withdrawal",
    title24:"Transfer",
    title25:"Vip",
    title26:"Message Notification",
    title27:"Quick Buy",
    title28:"Help center",
    title29:"Exit",
    title30:'Settings',
    title31:"Security Center",
    title32:'About Us',
    title33:'Email authentication',
    title34:'Change the password',
    title35:'Modify email',
    context1:"This site is a decentralized DeFi financial platform with high anonymity and security. You don't need to worry about your personal information being leaked or KYC verification. Users can freely store and withdraw their assets. The decentralized financial products purchased on this site offer high returns and stability, with easy and fast services.",
    context2:" （About DeFi decentralized financial applications）",
    context3:"Decentralized Finance (DeFi), short for Decentralized Finance, is one of the applications of blockchain technology in Fintech. DeFi creates an open and transparent financial system through open-source and decentralized blockchain platforms.",
    context4:"DeFi refers to using open-source software and decentralized networks to transform traditional financial products into trustless and transparent protocols that operate without unnecessary intermediaries. DeFi itself is a concept and architecture centered on 'decentralization,' meaning there is no administrator, every user is equal, and has the same permissions. If anyone wants to change the content, it must be agreed upon by everyone. Therefore, DeFi's characteristics include distributed ownership, belonging to no one, and being difficult to tamper with. DeFi also has the advantages of blockchain, such as transparency, censorship resistance, and immutability.",
    context5:"DeFi has many advantages, including a trustless foundation, no need for institutional permission, and asset control in the hands of the individual. Its main advantage is eliminating 'trusted third parties' through decentralization, maintaining ledger transparency and immutability.",
    context6:"The biggest difference between DeFi and Fintech is their 'trust' mechanism. Fintech applies blockchain technology within the existing financial system, still relying on centralized institutions, and all operations are controlled by humans, obtaining user trust through third-party mechanisms. In contrast, DeFi's main feature is decentralization, using smart contracts. Its code is open-source and core logic is executed on the blockchain, making it unchangeable and enforceable, so users can directly trust its structure.",
    context7:"Visible DeFi applications include decentralized trading protocols, stablecoins, lending, derivatives, funds, prediction markets, tokenization protocols, KYC/AML, and identity verification.",
    title36:'Deposit',
    title37:'NetWork',
    title38:'Amount',
    title39:'Deposit',
    title40:" Use wallet or exchange transfer recharge address. After the top-up is completed, the amount will be automatically added to your account. ",
    title41:'Withdraw',
    title42:'Wallet address',
    title43:'NetWork',
    title44:'Amount',
    title45:'Available balance',
    title46:' Security Tip: Please fill in your withdrawal account accurately, assets can not be returned after transferring out ',
    title47:'Withdraw',
    title48:'Enter wallet address',
    title49:'5-1000000',
    title50:'Internal Transfer',
    title51:'Account',
    title52:'VIP Members Can Use One-click Transfer Without Key',
    title53:'The recipient needs at least Vip1',
    title54:'Service charge',
    title55:'Internal Transfer',
    title56:'Purchase',
    title57:"Deposit",
    title58:'Level Rules',
    title59:'Deposit',
    title60:'Daily Collection Limit',
    title61:'Monthly Collection Limit',
    title62:'Users Need to Pay a Deposit to Use VIP Services. VIP1 is Valid for 1 Month. It Will Be Automatically Canceled Upon Expiry. VIP2 and Above Users, After 2 Months of Use, Can Contact Customer Service to Return the Deposit. For More Details, Please Refer to the Help Center.',
    title63:'record',
    title64:'Membership Upgrade',
    title65:'VIP1 needs to wait for 30 days for automatic refund (cannot apply for refund).',
    title66:'VIP2-VIP6 can contact customer service to apply for a refund. Upon successful refund, the deposit will be returned to the balance immediately and the VIP level will be canceled.',
    title67:'Message Notification',
    title68:'Hello! Welcome to login!',
    title69:'Account',
    title70:'Password',
    title71:'Please enter your username or email address',
    title72:'Please enter your password',
    title73:'Enter captcha',
    title74:'Login',
    title75:'No account',
    title76:'REGISTER',
    title77:'RETRIEVE PASSWORD',
    title78:'password',
    title79:'Password',
    title80:'Confirm password',
    title81:'KEY',
    title82:'Please enter KEY',
    title83:'Please confirm the password again',
    title84:'Please enter your password',
    title85:'Confirm',
    title86:' Create a account ',
    title87:'User name',
    title88:'Please enter your user name',
    title89:'Email',
    title90:'Please enter your email address',
    title91:'Country',
    title92:'Mobile',
    title93:'Please enter the phone number',
    title94:'Register',
    title95:'LOGIN',
    title96:'Have an account',
    title97:'Quick Buy',
    title98:'Buy Tether (USDT) with a credit or debit card on Paxful',
    title99:'Buy Tether (USDT) with a credit or debit card on ChangeNow',
    title100:'Buy Tether (USDT) with a credit or debit card on Changelly',
    title101:'Buy Tether (USDT) with a credit or debit card on Binance Exchange',
    title102:'Buy Tether (USDT) with a credit or debit card on Bybit Exchange',
    title103:'Buy Tether (USDT) with a credit or debit card on OKX Exchange',
    title104:'Help center', 
    title105:'1. Platform usage instructions',
    title106:'2. How to activate VIP',
    title107:'3.VIP transfer (no KEY required)',
    title108:'4.How to recharge?',
    title109:'5.How to withdraw cash?',
    title110:'6. What should I do if I forget my KEY?',
    title111:'7. How to change password, email?',
    title112:'8. How to register an account?',
    title113:'9. How to invest?',
    title114:'10.VIP investment services',
    title115:'Submit',
    title116:'Please Enter Key Password',
    title117:'Please enter your wallet address',
    title118:'Please enter the withdrawal amount',
    title119:`Use wallet or exchange transfer recharge address.
    After the top-up is completed, the amount will be automatically added to your account.`,
    title120:'Submitting',
    title121:'The minimum recharge amount cannot be less than 50',
    title122:'Apply for Refund',
    title123:'Please enter the amount you want to tranfer',
    title124:'Please enter username',
    title125:`Use wallet or exchange transfer recharge address.
    After the top-up is completed, the amount will be automatically added to your account. `,
    title126:'Wallet QR code',
    title127:'waiting',
    title128:'Please enter your mobile phone number',
    title129:'Please enter your email address',
    title130:'Please input a password',
    title131:'Please enter the password again',
    title132:'The passwords you entered twice do not match',
    title133:'Please enter the key password',
    title134:'Please enter the key password again',
    title135:'The key password you entered twice do not match',
    title136:'Please enter the picture verification code',
    title137:'register failed',
    title138:'Please enter the login password',
    title139:'logining',
    title140:'Please enter your password again',
    title141:'Please enter your key password',
    title142:'The passwords you entered twice do not match',
    title143:'submiting',
    content1:`The USDT team has officially entered the digital asset trading service platform.
    With reliable and scalable technical design, high-quality services and more support, the USDT digital asset trading platform aims to provide its special users with more convenient, safer and convenient USDT exchange transaction services and high-quality blockchain assets. Global VIP customers provide deposits, transfers, withdrawals, financial management and investment services. VIP exclusive financial management, investment and 24-hour customer service.
    1. The platform is open for audience registration. Anyone can register their own account \n
    2. The VIP level of the newly registered account is 0 (stored value, transfer, withdrawal, etc.). These are free and you don't need to upgrade your account to operate them. If your account needs to receive transfers from others, you need to use payment limits (at this time, level 0 accounts will not be able to receive payments), and you need to upgrade your account level to VIP to use the payment function; the higher the level, everyone’s daily and monthly allowances will be If different, please upgrade as needed.
    3. We can also provide various services such as currency exchange and USDT mortgage loans for VIP customers.`,
    content2:`     1. Click the VIP button
    2. Select the VIP level you want to purchase
    3. Pay the VIP deposit and purchase successfully
    4. Deposit required for VIP level (you can cancel at any time and get your deposit returned)
    VIP1 deposit: 50 USDT (VIP1 is a novice experience service, valid for 1 months, the deposit cannot be refunded and cannot be cancelled)
    VIP2 Deposit: 1000 USDT
    VIP3 Deposit: 3000 USDT
    VIP4 Deposit: 30000 USDT
    VIP5 Deposit: 50000 USDT
    VIP6 deposit: 100000 USDT
    The VIP level of the newly registered account is 0 (stored value, transfer, withdrawal,). These are free and you don't need to upgrade your account to operate them. If your account needs to receive transfers from others, you need to use payment limits (at this time, level 0 accounts will not be able to receive payments), and you need to upgrade your account level to VIP to use the payment function; the higher the level, everyone’s daily and monthly allowances will be If different, please upgrade as needed.
    VIP1: Payment limit 10 USDT per day and 30 USDT per month
    VIP2: Payment limit is 100 USDT per day and 3,000 USDT per month
    VIP3: Payment limit is 30,000 USDT per day and 900,000 USDT per month
    VIP4: Payment limit is 100,000 USDT per day and 3,000,000 USDT per month
    VIP5: Payment limit is 200,000 USDT per day and 6,000,000 USDT per month
    VIP6: Payment limit is 2,000,000 USDT per day and 60,000,000 USDT per month`,
    content3:`Note: VIPs can use the platform transfer function. Transfers can only be made between platform VIPs (successful transfers cannot be returned)
    1.Click the transfer button
    2. Correctly filled in VIP username, email address and mobile phone number
    3. Click Confirm to complete the transfer (no need to enter KEY)
    4. If your account needs to receive transfers from others, you need to use payment limits
    VIP1: Payment limit 10 USDT per day and 30 USDT per month
    VIP2: Payment limit is 100 USDT per day and 3,000 USDT per month
    VIP3: Payment limit is 30,000 USDT per day and 900,000 USDT per month
    VIP4: Payment limit is 100,000 USDT per day and 3,000,000 USDT per month
    VIP5: Payment limit is 200,000 USDT per day and 6,000,000 USDT per month
    VIP6: Payment limit is 2,000,000 USDT per day and 60,000,000 USDT per month
    Transfer limit example: If your account is VIP5
    VIP5 transfer to VIP1 (10USDT)
    VIP5 transfer to VIP2 (100USDT)
    VIP5 transfer to VIP3 (30000USDT)
    VIP5 transfer to VIP4 (100000USDT)
    VIP5 transfer to VIP5 (200000USDT)
    VIP6 Transfer to VIP6 (2000000USDT)`,
    content4:`      1. Click the recharge button
    2. Select the amount to be stored
    3. Click Confirm. Enter (KEY)
    4. You will get an exclusive stored value address for your account, which you can transfer from any exchange or wallet.
    5. The amount of your stored value will be automatically added to your account balance`,
    content5:`      1. Click the Withdraw button
    2. Enter the USDT withdrawal address (TRC20)
    3. Enter the withdrawal amount
    4. Click the Confirm button and enter KEY
    5. The withdrawal is successful and your withdrawal will arrive within 2 hours.`,
    content6:`      1. The KEY is set by yourself and cannot be obtained by others. The KEY is unique and cannot be reset.
    2.KEY is the only proof of your account. It cannot be modified. Unable to retrieve
    3. If you forget the KEY. Your account still has funds to withdraw, you can re-register a new account and upgrade VIP, and then transfer funds from your old account to your new account for withdrawal. No KEY is required for transfers within the VIP platform
    KEY is the only credential for your account and cannot be modified or retrieved. In order to protect customer privacy, the platform will not record the user's account number, password, mobile phone number, email, or KEY. Please keep your login information safe`,
    content7:`      1. Click on my homepage
    2. Click the Security Center button
    3. Select the password or email that needs to be changed
    4. Enter KEY to complete`,
    content8:`      (Note: When registering, please do not open the translation software. If you open the translation software, the button will not be confirmed. If you cannot register, please change the browser)
    1. Click the registration button on the homepage
    2. Click to register
    3. Fill in your registration information
    4. Please keep it properly when setting the KEY. It cannot be modified or retrieved (loss may cause property damage)`,
    content9:`      Store safely and earn easily
    1. Minimum storage amount: 5,000 USDT (account funds below 5,000 cannot access financial services)
    2: Your assets will receive income according to different levels, and the total income at each level is added up.
    3: The expected annualized income will be adjusted at any time based on the actual income of the mining pool.
    4: The income will be calculated one day after PoS is opened.
    5: No need to freeze mortgage assets, you can trade or withdraw coins at any time.
    Represents the pledge date. Earnings will start on T+1 (the next day) and will be distributed to your account on T+2.
    No income will be generated on the day you release your pledged assets. Earnings will be distributed to your spot account on a daily basis. Current financial management can be canceled at any time, and the income will be settled until the day before exiting PoS.
    For other products, when the minimum storage period is reached, you can choose to redeem or continue to store. The final income will be calculated based on the actual number of storage days, and the principal will be unlocked on the redemption unlocking day.
    Among the financial products provided, you can choose from dozens of digital assets (such as Bitcoin, Ethereum) and stable coins.`,
    content10:`      1. Thank you again for becoming our member, we will wholeheartedly provide you with better service and support!
    2. You will get transfer and collection services
    3. You will enjoy exclusive VIP rates and more favorable transaction costs.
    4. You will receive a VIP privileged badge to show your identity and status.
    5. You will join our exclusive VIP service group and receive more exclusive customer support.
    6. You will receive regular industry reports to understand market dynamics and investment opportunities.
    7. You will enjoy exclusive festive benefits and customized souvenir privileges, allowing you to feel our care.
    8. You will have the opportunity to participate in exclusive VIP activities and get to know more successful investors.
    9. You will have exclusive 24-hour VIP customer service channel and receive faster and priority solution support.
    10. You will get an independent green channel and enjoy more convenient deposit and withdrawal services.`
    // 其他英文翻译
  },
    "tc":{
      "menu_1": "首頁",
      "menu_2": "交易",
      "menu_3": "我的",
      "title1": "貨幣",
      "title2": "最新價格",
      "title3": "合作夥伴",
      "title4": "語言",
      "title5": "首頁",
      "welcome": "歡迎",
      "hello": "你好",
      "title6": "可用餘額",
      "title7": "每日接收限額",
      "title8": "每月接收限額",
      "title9": "存款",
      "title10": "提現",
      "title11": "內部轉賬",
      "title12": "會員升級",
      "title13": "參考年化收益",
      "title14": "投資期限",
      "title15": "起始數量",
      "title16": "立即參與",
      "title17": "平台記錄",
      "title18": "提現",
      "title19": "充值",
      "title20": "我的",
      "title21": "我的資產",
      "title22": "存款",
      "title23": "提現",
      "title24": "轉賬",
      "title25": "VIP",
      "title26": "消息通知",
      "title27": "快速購買",
      "title28": "幫助中心",
      "title29": "退出",
      "title30": "設置",
      "title31": "安全中心",
      "title32": "關於我們",
      "title33": "郵箱驗證",
      "title34": "更改密碼",
      "title35": "修改郵箱",
      "context1": "本站是一個去中心化的DeFi金融平台，具有高度的匿名性和安全性。您無需擔心您的個人信息被泄露或進行KYC驗證。用戶可以自由存儲和提取他們的資產。本站購買的去中心化金融產品提供高回報和穩定性，服務簡單快捷。",
      "context2": "（關於DeFi去中心化金融應用）",
      "context3": "去中心化金融（DeFi），簡稱去中心化金融，是區塊鏈技術在金融科技領域的一個應用。DeFi通過開源和去中心化的區塊鏈平台創建一個開放和透明的金融系統。",
      "context4": "DeFi指的是使用開源軟件和去中心化網絡，將傳統的金融產品轉變為無信任且透明的協議，並且運行時不需要不必要的中介。DeFi本身是一個以“去中心化”為核心的概念和架構，這意味著沒有管理者，每個用戶都是平等的，擁有相同的權限。如果任何人想改變內容，必須經過所有人同意。因此，DeFi的特點包括分佈式所有權、屬於誰都不是，且難以篡改。DeFi還具有區塊鏈的優勢，如透明性、抗審查性和不可篡改性。",
      "context5": "DeFi有許多優勢，包括無信任的基礎、不需要機構許可以及資產由個人掌控。其主要優勢是通過去中心化消除了‘可信第三方’，保持帳本的透明性和不可篡改性。",
      "context6": "DeFi和金融科技之間的最大區別是它們的‘信任’機制。金融科技將區塊鏈技術應用於現有的金融體系，仍然依賴於集中化機構，所有操作由人控制，通過第三方機制獲取用戶的信任。相比之下，DeFi的主要特徵是去中心化，使用智能合約。其代碼是開源的，核心邏輯在區塊鏈上執行，無法更改且強制執行，因此用戶可以直接信任其結構。",
      "context7": "可見的DeFi應用包括去中心化交易協議、穩定幣、借貸、衍生品、基金、預測市場、代幣化協議、KYC/AML和身份驗證。",
      "title36": "存款",
      "title37": "網絡",
      "title38": "金額",
      "title39": "存款",
      "title40": "使用錢包或交易所轉賬充值地址。充值完成後，金額將自動添加到您的帳戶。",
      "title41": "提現",
      "title42": "錢包地址",
      "title43": "網絡",
      "title44": "金額",
      "title45": "可用餘額",
      "title46": "安全提示：請準確填寫您的提現賬戶，轉賬後資產無法退回。",
      "title47": "提現",
      "title48": "輸入錢包地址",
      "title49": "5-1000000",
      "title50": "內部轉賬",
      "title51": "帳戶",
      "title52": "VIP會員可使用一鍵轉賬無需密鑰",
      "title53": "收款方至少需要VIP1",
      "title54": "服務費",
      "title55": "內部轉賬",
      "title56": "購買",
      "title57": "存款",
      "title58": "等級規則",
      "title59": "存款",
      "title60": "每日收集限額",
      "title61": "每月收集限額",
      "title62": "用戶需要支付押金以使用VIP服務。VIP1有效期為1個月，期滿後自動取消。VIP2及以上用戶在使用2個月後可聯繫客服申請退回押金。詳情請參閱幫助中心。",
      "title63": "記錄",
      "title64": "會員升級",
      "title65": "VIP1需要等待30天後自動退款（無法申請退款）。",
      "title66": "VIP2-VIP6可聯繫客服申請退款，退款成功後，押金將立即返回到賬戶餘額並取消VIP等級。",
      "title67": "消息通知",
      "title68": "你好！歡迎登錄！",
      "title69": "帳號",
      "title70": "密碼",
      "title71": "請輸入您的用戶名或電子郵箱地址",
      "title72": "請輸入您的密碼",
      "title73": "輸入驗證碼",
      "title74": "登錄",
      "title75": "沒有帳號",
      "title76": "註冊",
      "title77": "找回密碼",
      "title78": "密碼",
      "title79": "密碼",
      "title80": "確認密碼",
      "title81": "密鑰",
      "title82": "請輸入密鑰",
      "title83": "請再次確認密碼",
      "title84": "請輸入您的密碼",
      "title85": "確認",
      "title86": "創建帳號",
      "title87": "用戶名",
      "title88": "請輸入您的用戶名",
      "title89": "電子郵箱",
      "title90": "請輸入您的電子郵箱地址",
      "title91": "國家",
      "title92": "手機",
      "title93": "請輸入手機號碼",
      "title94": "註冊",
      "title95": "登錄",
      "title96": "已有帳號",
      "title97": "快速購買",
      "title98": "使用信用卡或借記卡在Paxful購買Tether (USDT)",
      "title99": "使用信用卡或借記卡在ChangeNow購買Tether (USDT)",
      "title100": "使用信用卡或借記卡在Changelly購買Tether (USDT)",
      "title101": "使用信用卡或借記卡在Binance交易所購買Tether (USDT)",
      "title102": "使用信用卡或借記卡在Bybit交易所購買Tether (USDT)",
      "title103": "使用信用卡或借記卡在OKX交易所購買Tether (USDT)",
      "title104": "幫助中心",
      "title105": "1. 平台使用說明",
      "title106": "2. 如何激活VIP",
      "title107": "3. VIP轉賬（無需密鑰）",
      "title108": "4. 如何充值？",
      "title109": "5. 如何提現？",
      "title110": "6. 忘記密鑰該怎麼辦？",
      "title111": "7. 如何更改密碼、郵箱？",
      "title112": "8. 如何註冊帳號？",
      "title113": "9. 如何投資？",
      "title114": "10. VIP投資服務",
      "title115": "提交",
      "title116": "請輸入密鑰密碼",
      "title117": "請輸入您的錢包地址",
      "title118": "請輸入提現金額",
      "title119": "使用錢包或交易所轉賬充值地址。充值完成後，金額將自動添加到您的帳戶。",
      "title120": "提交中",
      "title121": "最小充值金額不得低於50",
      "title122": "申請退款",
      "title123": "請輸入您想轉賬的金額",
      "title124": "請輸入用戶名",
      "title125": "使用錢包或交易所轉賬充值地址。充值完成後，金額將自動添加到您的帳戶。",
      "title126": "錢包二維碼",
      "title127": "等待中",
      "title128": "請輸入您的手機號碼",
      "title129": "請輸入您的電子郵箱地址",
      "title130": "請輸入密碼",
      "title131": "請再次輸入密碼",
      "title132": "您輸入的密碼不一致",
      "title133": "請輸入密鑰密碼",
      "title134": "請再次輸入密鑰密碼",
      "title135": "您輸入的密鑰密碼不一致",
      "title136": "請輸入圖片驗證碼",
      "title137": "註冊失敗",
      "title138": "請輸入登錄密碼",
      "title139": "登錄中",
      "title140": "請再次輸入您的密碼",
      "title141": "請輸入您的密鑰密碼",
      "title142": "您輸入的密碼不一致",
      "title143": "提交中",
      'content1': `USDT 團隊正式進入數位資產交易服務平台。
    透過可靠且可擴展的技術設計、高品質服務及更多支援，USDT 數位資產交易平台旨在為特殊用戶提供更便捷、更安全的 USDT 交易服務及高品質區塊鏈資產。全球 VIP 客戶提供存款、轉帳、提款、財務管理和投資服務。VIP 專屬財務管理、投資及 24 小時客服。
    1. 平台已開放觀眾註冊，任何人都可以註冊自己的帳戶 \n
    2. 新註冊帳戶的 VIP 等級為 0（儲值、轉帳、提款等），這些服務是免費的，您無需升級帳戶即可操作。如果您的帳戶需要接收他人轉帳，則需要使用支付限額（此時，等級 0 帳戶無法接收支付），您需要將帳戶升級為 VIP 才能使用支付功能；等級越高，每個人的每日和每月限額會不同，請根據需要升級。
    3. 我們還能為 VIP 客戶提供各種服務，如貨幣兌換和 USDT 抵押貸款。`,

'content2': `     1. 點擊 VIP 按鈕
    2. 選擇您想要購買的 VIP 等級
    3. 支付 VIP 存款並成功購買
    4. VIP 等級所需存款（可隨時取消並退還存款）
    VIP1 存款：50 USDT（VIP1 為新手體驗服務，有效期 1 個月，存款不可退還且不可取消）
    VIP2 存款：1000 USDT
    VIP3 存款：3000 USDT
    VIP4 存款：30000 USDT
    VIP5 存款：50000 USDT
    VIP6 存款：100000 USDT
    新註冊帳戶的 VIP 等級為 0（儲值、轉帳、提款等），這些服務是免費的，您無需升級帳戶即可操作。如果您的帳戶需要接收他人轉帳，則需要使用支付限額（此時，等級 0 帳戶無法接收支付），您需要將帳戶升級為 VIP 才能使用支付功能；等級越高，每個人的每日和每月限額會不同，請根據需要升級。
    VIP1：每日支付限額 10 USDT，每月 30 USDT
    VIP2：每日支付限額 100 USDT，每月 3000 USDT
    VIP3：每日支付限額 30000 USDT，每月 900000 USDT
    VIP4：每日支付限額 100000 USDT，每月 3000000 USDT
    VIP5：每日支付限額 200000 USDT，每月 6000000 USDT
    VIP6：每日支付限額 2000000 USDT，每月 60000000 USDT`,

'content3' : `注意：VIP 可以使用平台轉帳功能。轉帳僅能在平台 VIP 之間進行（成功的轉帳不可退回）
    1. 點擊轉帳按鈕
    2. 正確填寫 VIP 使用者名稱、電子郵件地址及手機號碼
    3. 點擊確認完成轉帳（無需輸入 KEY）
    4. 如果您的帳戶需要接收他人轉帳，則需要使用支付限額
    VIP1：每日支付限額 10 USDT，每月 30 USDT
    VIP2：每日支付限額 100 USDT，每月 3000 USDT
    VIP3：每日支付限額 30000 USDT，每月 900000 USDT
    VIP4：每日支付限額 100000 USDT，每月 3000000 USDT
    VIP5：每日支付限額 200000 USDT，每月 6000000 USDT
    VIP6：每日支付限額 2000000 USDT，每月 60000000 USDT
    轉帳限額範例：如果您的帳戶是 VIP5
    VIP5 轉帳給 VIP1（10USDT）
    VIP5 轉帳給 VIP2（100USDT）
    VIP5 轉帳給 VIP3（30000USDT）
    VIP5 轉帳給 VIP4（100000USDT）
    VIP5 轉帳給 VIP5（200000USDT）
    VIP6 轉帳給 VIP6（2000000USDT）`,

'content4': `      1. 點擊充值按鈕
    2. 選擇儲值金額
    3. 點擊確認，輸入（KEY）
    4. 您將獲得一個專屬的儲值地址，您可以從任何交易所或錢包轉帳
    5. 您的儲值金額將自動加入到您的帳戶餘額`,

'content5' : `      1. 點擊提款按鈕
    2. 輸入 USDT 提款地址（TRC20）
    3. 輸入提款金額
    4. 點擊確認按鈕並輸入 KEY
    5. 提款成功，您的提款將在 2 小時內到達。`,

'content6' : `      1. KEY 由您自行設定，無法由他人獲取。KEY 是唯一的，且無法重置。
    2. KEY 是您帳戶的唯一證明，無法修改且無法恢復。
    3. 如果您忘記了 KEY，您的帳戶仍有資金可提取，您可以重新註冊一個新帳戶並升級 VIP，然後將資金從舊帳戶轉移到新帳戶進行提款。VIP 平台內的轉帳無需 KEY。
    KEY 是您帳戶的唯一憑證，無法修改或恢復。為了保護客戶隱私，平台不會記錄使用者帳號、密碼、手機號碼、電子郵件或 KEY。請保管好您的登入資訊`,

'content7' : `      1. 點擊我的首頁
    2. 點擊安全中心按鈕
    3. 選擇需要更改的密碼或電子郵件
    4. 輸入 KEY 完成`,

'content8' : `      （注意：註冊時請勿開啟翻譯軟體。如果您開啟翻譯軟體，按鈕將無法確認。如果無法註冊，請更換瀏覽器）
    1. 點擊首頁的註冊按鈕
    2. 點擊註冊
    3. 填寫您的註冊資料
    4. 設定 KEY 時請妥善保管，無法修改或恢復（遺失可能造成財產損失）`,

'content9' : `      安全存儲，輕鬆賺取
    1. 最低儲值金額：5000 USDT（帳戶資金低於 5000 無法使用財務服務）
    2: 您的資產將根據不同等級獲得收入，每個等級的總收入會加總。
    3: 預期年化收入會根據挖礦池的實際收入隨時調整。
    4: 收益會在 PoS 開放後的一天開始計算。
    5: 無需凍結抵押資產，您可以隨時交易或提款。
    代表質押日期，收益將在 T+1（次日）開始並於 T+2 分配到您的帳戶。
    解除質押資產當天不會產生收入，收益將每日分配至您的現貨帳戶。目前的財務管理可隨時取消，收入將結算至退出 PoS 的前一天。
    對於其他產品，當達到最短儲存期限時，您可以選擇兌換或繼續儲存。最終收入將根據實際儲存天數計算，本金將在兌換解鎖日解鎖。
    在提供的財務產品中，您可以選擇多種數位資產（如比特幣、以太幣）和穩定幣。`,

'content10' : `      1. 再次感謝您成為我們的會員，我們將全心全意為您提供更好的服務與支援！
    2. 您將獲得轉帳和收款服務
    3. 您將享有專屬 VIP 費率和更優惠的交易成本。
    4. 您將獲得 VIP 尊貴徽章，展示您的身份與地位。
    5. 您將加入我們的專屬 VIP 服務群組，並獲得更多專屬客服支援。
    6. 您將定期收到行業報告，了解市場動態與投資機會。
    7. 您將享有專屬節日福利與定制紀念品特權，讓您感受我們的關懷。
    8. 您將有機會參與專屬 VIP 活動，結識更多成功投資者。
    9. 您將擁有專屬 24 小時 VIP 客戶服務通道，並獲得更快速的優先解決支援。
    10. 您將獲得獨立綠色通道，享受更便捷的存取款服務。`

    },
    de:{
      "menu_1": "Startseite",
      "menu_2": "Handel",
      "menu_3": "Mein",
      "title1": "Währung",
      "title2": "Aktueller Preis",
      "title3": "Partner",
      "title4": "Sprache",
      "title5": "Startseite",
      "welcome": "Willkommen",
      "hello": "Hallo",
      "title6": "Verfügbares Guthaben",
      "title7": "Tägliches Empfangslimit",
      "title8": "Monatliches Empfangslimit",
      "title9": "Einzahlung",
      "title10": "Auszahlung",
      "title11": "Interne Übertragung",
      "title12": "Mitgliedschaft Upgrade",
      "title13": "Jährliches Einkommen durch Empfehlungen",
      "title14": "Investitionsperiode",
      "title15": "Startmenge",
      "title16": "Jetzt teilnehmen",
      "title17": "Plattformaufzeichnungen",
      "title18": "Auszahlung",
      "title19": "Aufladen",
      "title20": "Mein",
      "title21": "Meine Vermögenswerte",
      "title22": "Einzahlung",
      "title23": "Auszahlung",
      "title24": "Übertragung",
      "title25": "VIP",
      "title26": "Nachricht Benachrichtigung",
      "title27": "Schnell kaufen",
      "title28": "Hilfezentrum",
      "title29": "Abmelden",
      "title30": "Einstellungen",
      "title31": "Sicherheitszentrum",
      "title32": "Über uns",
      "title33": "E-Mail-Authentifizierung",
      "title34": "Passwort ändern",
      "title35": "E-Mail ändern",
      "context1": "Diese Seite ist eine dezentrale DeFi-Finanzplattform mit hoher Anonymität und Sicherheit. Sie müssen sich keine Sorgen machen, dass Ihre persönlichen Daten durchgesickert oder KYC-Verifizierungen erforderlich sind. Benutzer können ihre Vermögenswerte frei speichern und abheben. Die dezentralen Finanzprodukte, die auf dieser Seite gekauft werden, bieten hohe Renditen und Stabilität, mit einfachen und schnellen Diensten.",
      "context2": "（Über DeFi dezentrale Finanzanwendungen）",
      "context3": "Dezentralisierte Finanzen (DeFi), kurz für Decentralized Finance, ist eine der Anwendungen der Blockchain-Technologie im Fintech-Bereich. DeFi schafft ein offenes und transparentes Finanzsystem durch Open-Source- und dezentrale Blockchain-Plattformen.",
      "context4": "DeFi bezieht sich auf die Nutzung von Open-Source-Software und dezentralen Netzwerken zur Transformation traditioneller Finanzprodukte in vertrauenslose und transparente Protokolle, die ohne unnötige Vermittler betrieben werden. DeFi selbst ist ein Konzept und eine Architektur, die auf 'Dezentralisierung' ausgerichtet ist, was bedeutet, dass es keinen Administrator gibt, jeder Benutzer ist gleichberechtigt und hat die gleichen Rechte. Wenn jemand den Inhalt ändern möchte, muss dies von allen zugestimmt werden. Daher sind die Merkmale von DeFi verteiltes Eigentum, niemand besitzt es, und es ist schwer zu manipulieren. DeFi bietet auch die Vorteile der Blockchain, wie Transparenz, Zensurresistenz und Unveränderlichkeit.",
      "context5": "DeFi hat viele Vorteile, darunter eine vertrauenslose Grundlage, keine Notwendigkeit einer institutionellen Genehmigung und die Kontrolle der Vermögenswerte in den Händen des Einzelnen. Der größte Vorteil ist die Beseitigung von 'vertrauenswürdigen Dritten' durch Dezentralisierung, wobei die Buchhaltung transparent und unveränderlich bleibt.",
      "context6": "Der größte Unterschied zwischen DeFi und Fintech ist ihr 'Vertrauensmechanismus'. Fintech wendet Blockchain-Technologie innerhalb des bestehenden Finanzsystems an, das immer noch auf zentralisierte Institutionen angewiesen ist und alle Operationen von Menschen gesteuert werden, um Vertrauen durch Dritte zu gewinnen. Im Gegensatz dazu ist das Hauptmerkmal von DeFi die Dezentralisierung, die durch Smart Contracts umgesetzt wird. Der Code ist Open Source und die Kernlogik wird auf der Blockchain ausgeführt, was sie unveränderlich und durchsetzbar macht, sodass Benutzer der Struktur direkt vertrauen können.",
      "context7": "Sichtbare DeFi-Anwendungen umfassen dezentrale Handelsprotokolle, Stablecoins, Kreditvergabe, Derivate, Fonds, Prognosemärkte, Tokenisierungsprotokolle, KYC/AML und Identitätsverifikation.",
      "title36": "Einzahlung",
      "title37": "Netzwerk",
      "title38": "Betrag",
      "title39": "Einzahlung",
      "title40": "Verwenden Sie die Wallet- oder Exchange-Überweisungsadresse zum Aufladen. Nach dem Aufladen wird der Betrag automatisch Ihrem Konto gutgeschrieben.",
      "title41": "Auszahlung",
      "title42": "Wallet-Adresse",
      "title43": "Netzwerk",
      "title44": "Betrag",
      "title45": "Verfügbares Guthaben",
      "title46": "Sicherheitshinweis: Bitte geben Sie Ihr Auszahlungs-Konto genau an. Vermögenswerte können nach der Übertragung nicht zurückgegeben werden.",
      "title47": "Abheben",
      "title48": "Geben Sie die Wallet-Adresse ein",
      "title49": "5-1000000",
      "title50": "Interne Übertragung",
      "title51": "Konto",
      "title52": "VIP-Mitglieder können die Übertragung mit einem Klick ohne Schlüssel verwenden",
      "title53": "Der Empfänger benötigt mindestens Vip1",
      "title54": "Servicegebühr",
      "title55": "Interne Übertragung",
      "title56": "Kauf",
      "title57": "Einzahlung",
      "title58": "Level-Regeln",
      "title59": "Einzahlung",
      "title60": "Tägliches Sammellimit",
      "title61": "Monatliches Sammellimit",
      "title62": "Benutzer müssen eine Einzahlung tätigen, um VIP-Dienste zu nutzen. VIP1 ist für 1 Monat gültig. Es wird nach Ablauf automatisch gekündigt. VIP2 und höhere Benutzer können nach 2 Monaten den Kundendienst kontaktieren, um die Einzahlung zurückzugeben. Weitere Details finden Sie im Hilfezentrum.",
      "title63": "Aufzeichnung",
      "title64": "Mitgliedschaft Upgrade",
      "title65": "VIP1 muss 30 Tage auf die automatische Rückerstattung warten (kann keine Rückerstattung beantragen).",
      "title66": "VIP2-VIP6 können den Kundendienst kontaktieren, um eine Rückerstattung zu beantragen. Nach erfolgreicher Rückerstattung wird die Einzahlung sofort wieder dem Guthaben hinzugefügt und das VIP-Level wird aufgehoben.",
      "title67": "Nachricht Benachrichtigung",
      "title68": "Hallo! Willkommen beim Login!",
      "title69": "Konto",
      "title70": "Passwort",
      "title71": "Bitte geben Sie Ihren Benutzernamen oder Ihre E-Mail-Adresse ein",
      "title72": "Bitte geben Sie Ihr Passwort ein",
      "title73": "Geben Sie den Captcha ein",
      "title74": "Login",
      "title75": "Kein Konto",
      "title76": "REGISTRIEREN",
      "title77": "PASSWORT WIEDERHERSTELLEN",
      "title78": "Passwort",
      "title79": "Passwort",
      "title80": "Passwort bestätigen",
      "title81": "SCHLÜSSEL",
      "title82": "Bitte geben Sie den Schlüssel ein",
      "title83": "Bitte bestätigen Sie das Passwort erneut",
      "title84": "Bitte geben Sie Ihr Passwort erneut ein",
      "title85": "Bestätigen",
      "title86": "Ein Konto erstellen",
      "title87": "Benutzername",
      "title88": "Bitte geben Sie Ihren Benutzernamen ein",
      "title89": "E-Mail",
      "title90": "Bitte geben Sie Ihre E-Mail-Adresse ein",
      "title91": "Land",
      "title92": "Mobil",
      "title93": "Bitte geben Sie die Telefonnummer ein",
      "title94": "Registrieren",
      "title95": "LOGIN",
      "title96": "Haben Sie ein Konto",
      "title97": "Schnell kaufen",
      "title98": "Tether (USDT) mit einer Kredit- oder Debitkarte auf Paxful kaufen",
      "title99": "Tether (USDT) mit einer Kredit- oder Debitkarte auf ChangeNow kaufen",
      "title100": "Tether (USDT) mit einer Kredit- oder Debitkarte auf Changelly kaufen",
      "title101": "Tether (USDT) mit einer Kredit- oder Debitkarte auf Binance Exchange kaufen",
      "title102": "Tether (USDT) mit einer Kredit- oder Debitkarte auf Bybit Exchange kaufen",
      "title103": "Tether (USDT) mit einer Kredit- oder Debitkarte auf OKX Exchange kaufen",
      "title104": "Hilfezentrum",
      "title105": "1. Plattform-Benutzungsanweisungen",
      "title106": "2. Wie man VIP aktiviert",
      "title107": "3. VIP-Übertragung (kein Schlüssel erforderlich)",
      "title108": "4. Wie man auflädt?",
      "title109": "5. Wie man Geld abhebt?",
      "title110": "6. Was soll ich tun, wenn ich mein Schlüsselpasswort vergesse?",
      "title111": "7. Wie man Passwort und E-Mail ändert?",
      "title112": "8. Wie man ein Konto registriert?",
      "title113": "9. Wie investiert man?",
      "title114": "10. VIP-Investitionsdienste",
      "title115": "Absenden",
      "title116": "Bitte geben Sie das Schlüsselpasswort ein",
      "title117": "Bitte geben Sie Ihre Wallet-Adresse ein",
      "title118": "Bitte geben Sie den Auszahlungsbetrag ein",
      "title119": "Verwenden Sie die Wallet- oder Exchange-Überweisungsadresse zum Aufladen. Nach dem Aufladen wird der Betrag automatisch Ihrem Konto gutgeschrieben.",
      "title120": "Wird gesendet",
      "title121": "Der Mindestbetrag für die Einzahlung darf nicht weniger als 50 betragen",
      "title122": "Rückerstattung beantragen",
      "title123": "Bitte geben Sie den Betrag ein, den Sie übertragen möchten",
      "title124": "Bitte geben Sie den Benutzernamen ein",
      "title125": "Verwenden Sie die Wallet- oder Exchange-Überweisungsadresse zum Aufladen. Nach dem Aufladen wird der Betrag automatisch Ihrem Konto gutgeschrieben.",
      "title126": "Wallet QR-Code",
      "title127": "Warten",
      "title128": "Bitte geben Sie Ihre Telefonnummer ein",
      "title129": "Bitte geben Sie Ihre E-Mail-Adresse ein",
      "title130": "Bitte geben Sie ein Passwort ein",
      "title131": "Bitte geben Sie das Passwort erneut ein",
      "title132": "Die Passwörter, die Sie zweimal eingegeben haben, stimmen nicht überein",
      "title133": "Bitte geben Sie das Schlüsselpasswort ein",
      "title134": "Bitte geben Sie das Schlüsselpasswort erneut ein",
      "title135": "Das Schlüsselpasswort, das Sie zweimal eingegeben haben, stimmt nicht überein",
      "title136": "Bitte geben Sie den Bildbestätigungscode ein",
      "title137": "Registrierung fehlgeschlagen",
      "title138": "Bitte geben Sie das Login-Passwort ein",
      "title139": "Loggt ein",
      "title140": "Bitte geben Sie Ihr Passwort erneut ein",
      "title141": "Bitte geben Sie das Schlüsselpasswort ein",
      "title142": "Die Passwörter, die Sie zweimal eingegeben haben, stimmen nicht überein",
      "title143": "Absenden",
      'content1' : `Das USDT-Team ist offiziell auf der digitalen Asset-Handelsplattform eingetreten.
    Mit zuverlässigem und skalierbarem technischem Design, hochwertigen Dienstleistungen und mehr Unterstützung zielt die USDT digitale Asset-Handelsplattform darauf ab, ihren speziellen Nutzern bequemere, sicherere und praktischere USDT Exchange-Transaktionsdienste sowie hochwertige Blockchain-Vermögenswerte zu bieten. Globale VIP-Kunden erhalten Einzahlungs-, Transfer-, Auszahlungs-, Finanzverwaltungs- und Investitionsdienstleistungen. VIP-exklusive Finanzverwaltung, Investitionen und 24-Stunden-Kundendienst.
    1. Die Plattform ist für die Registrierung von Zuschauern geöffnet. Jeder kann ein eigenes Konto registrieren \n
    2. Das VIP-Level des neu registrierten Kontos ist 0 (Wertaufbewahrung, Übertragung, Abhebung usw.). Diese sind kostenlos, und Sie müssen Ihr Konto nicht upgraden, um sie zu verwenden. Wenn Ihr Konto Zahlungen von anderen empfangen muss, müssen Sie Zahlungsgrenzen verwenden (zu diesem Zeitpunkt kann ein Konto der Stufe 0 keine Zahlungen empfangen), und Sie müssen Ihr Konto auf VIP upgraden, um die Zahlungsfunktion zu nutzen. Je höher das Level, desto unterschiedlich sind die täglichen und monatlichen Limits für alle. Bitte upgraden Sie nach Bedarf.
    3. Wir können auch verschiedene Dienstleistungen wie Währungsumtausch und USDT-Immobilienkredite für VIP-Kunden anbieten.`,

'content2' : `     1. Klicken Sie auf die VIP-Schaltfläche
    2. Wählen Sie das gewünschte VIP-Level aus
    3. Zahlen Sie die VIP-Einzahlung und kaufen Sie erfolgreich ein
    4. Erforderliche Einzahlung für das VIP-Level (kann jederzeit storniert und die Einzahlung zurückerstattet werden)
    VIP1 Einzahlung: 50 USDT (VIP1 ist ein Einsteigererlebnis-Service, gültig für 1 Monat, die Einzahlung ist nicht rückerstattbar und kann nicht storniert werden)
    VIP2 Einzahlung: 1000 USDT
    VIP3 Einzahlung: 3000 USDT
    VIP4 Einzahlung: 30000 USDT
    VIP5 Einzahlung: 50000 USDT
    VIP6 Einzahlung: 100000 USDT
    Das VIP-Level des neu registrierten Kontos ist 0 (Wertaufbewahrung, Übertragung, Abhebung usw.). Diese sind kostenlos, und Sie müssen Ihr Konto nicht upgraden, um sie zu verwenden. Wenn Ihr Konto Zahlungen von anderen empfangen muss, müssen Sie Zahlungsgrenzen verwenden (zu diesem Zeitpunkt kann ein Konto der Stufe 0 keine Zahlungen empfangen), und Sie müssen Ihr Konto auf VIP upgraden, um die Zahlungsfunktion zu nutzen. Je höher das Level, desto unterschiedlich sind die täglichen und monatlichen Limits für alle. Bitte upgraden Sie nach Bedarf.
    VIP1: Zahlungslimit 10 USDT pro Tag und 30 USDT pro Monat
    VIP2: Zahlungslimit 100 USDT pro Tag und 3000 USDT pro Monat
    VIP3: Zahlungslimit 30000 USDT pro Tag und 900000 USDT pro Monat
    VIP4: Zahlungslimit 100000 USDT pro Tag und 3000000 USDT pro Monat
    VIP5: Zahlungslimit 200000 USDT pro Tag und 6000000 USDT pro Monat
    VIP6: Zahlungslimit 2000000 USDT pro Tag und 60000000 USDT pro Monat`,

'content3' : `Hinweis: VIPs können die Plattform-Übertragungsfunktion nutzen. Übertragungen können nur zwischen Plattform-VIPs durchgeführt werden (erfolgreiche Übertragungen können nicht zurückgegeben werden)
    1. Klicken Sie auf die Übertragungs-Schaltfläche
    2. Geben Sie korrekt den VIP-Benutzernamen, die E-Mail-Adresse und die Telefonnummer ein
    3. Klicken Sie auf Bestätigen, um die Übertragung abzuschließen (KEY ist nicht erforderlich)
    4. Wenn Ihr Konto Zahlungen von anderen empfangen muss, müssen Sie Zahlungsgrenzen verwenden
    VIP1: Zahlungslimit 10 USDT pro Tag und 30 USDT pro Monat
    VIP2: Zahlungslimit 100 USDT pro Tag und 3000 USDT pro Monat
    VIP3: Zahlungslimit 30000 USDT pro Tag und 900000 USDT pro Monat
    VIP4: Zahlungslimit 100000 USDT pro Tag und 3000000 USDT pro Monat
    VIP5: Zahlungslimit 200000 USDT pro Tag und 6000000 USDT pro Monat
    VIP6: Zahlungslimit 2000000 USDT pro Tag und 60000000 USDT pro Monat
    Beispiel für Übertragungslimits: Wenn Ihr Konto VIP5 ist
    VIP5 überträgt an VIP1 (10 USDT)
    VIP5 überträgt an VIP2 (100 USDT)
    VIP5 überträgt an VIP3 (30000 USDT)
    VIP5 überträgt an VIP4 (100000 USDT)
    VIP5 überträgt an VIP5 (200000 USDT)
    VIP6 überträgt an VIP6 (2000000 USDT)`,

'content4' : `      1. Klicken Sie auf die Einzahlungsschaltfläche
    2. Wählen Sie den Einzahlungsbetrag aus
    3. Klicken Sie auf Bestätigen, geben Sie (KEY) ein
    4. Sie erhalten eine exklusive Einzahlungsadresse für Ihr Konto, die Sie von jeder Börse oder Wallet überweisen können
    5. Der Betrag Ihrer Einzahlung wird automatisch Ihrem Kontostand hinzugefügt`,

'content5' : `      1. Klicken Sie auf die Abhebungsschaltfläche
    2. Geben Sie die USDT-Abhebungsadresse (TRC20) ein
    3. Geben Sie den Abhebungsbetrag ein
    4. Klicken Sie auf die Bestätigungsschaltfläche und geben Sie KEY ein
    5. Die Abhebung war erfolgreich und wird innerhalb von 2 Stunden auf Ihrem Konto gutgeschrieben.`,

'content6' : `      1. Das KEY wird von Ihnen selbst festgelegt und kann nicht von anderen abgerufen werden. Das KEY ist einzigartig und kann nicht zurückgesetzt werden.
    2. KEY ist der einzige Nachweis für Ihr Konto. Es kann nicht geändert oder zurückgesetzt werden.
    3. Wenn Sie das KEY vergessen, Ihr Konto jedoch noch über Guthaben zum Abheben verfügt, können Sie ein neues Konto registrieren und VIP upgraden, um dann Gelder von Ihrem alten Konto auf Ihr neues Konto zu überweisen und abzuheben. Für Übertragungen innerhalb der VIP-Plattform wird kein KEY benötigt.
    KEY ist der einzige Nachweis für Ihr Konto und kann nicht geändert oder abgerufen werden. Zum Schutz der Privatsphäre der Kunden wird die Plattform keine Kontonummer, Passwort, Telefonnummer, E-Mail oder KEY des Nutzers aufzeichnen. Bitte bewahren Sie Ihre Anmeldeinformationen sicher auf`,

'content7' : `      1. Klicken Sie auf meine Homepage
    2. Klicken Sie auf die Schaltfläche Sicherheitszentrum
    3. Wählen Sie das Passwort oder die E-Mail aus, die geändert werden muss
    4. Geben Sie das KEY ein, um den Vorgang abzuschließen`,

'content8' : `      (Hinweis: Bitte öffnen Sie während der Registrierung keine Übersetzungssoftware. Wenn Sie die Übersetzungssoftware öffnen, wird der Button nicht bestätigt. Wenn Sie sich nicht registrieren können, ändern Sie bitte den Browser)
    1. Klicken Sie auf die Registrierungs-Schaltfläche auf der Homepage
    2. Klicken Sie auf Registrieren
    3. Füllen Sie Ihre Registrierungsdaten aus
    4. Bewahren Sie Ihr KEY sicher auf, da es nicht geändert oder abgerufen werden kann (Verluste können zu finanziellen Schäden führen)`,

'content9' : `      Sicher speichern und einfach verdienen
    1. Mindestbetrag für die Speicherung: 5000 USDT (Kontoguthaben unter 5000 kann keine Finanzdienstleistungen nutzen)
    2: Ihre Vermögenswerte erhalten Einkommen gemäß verschiedenen Stufen, und das Gesamteinkommen jeder Stufe wird addiert.
    3: Das erwartete jährliche Einkommen wird jederzeit basierend auf den tatsächlichen Einnahmen des Mining-Pools angepasst.
    4: Das Einkommen wird einen Tag nach dem Öffnen des PoS berechnet.
    5: Es ist nicht erforderlich, die Pfandvermögen zu sperren. Sie können jederzeit mit den Coins handeln oder sie abheben.
    Der Pfandzeitpunkt wird dargestellt. Die Erträge beginnen am T+1 (dem nächsten Tag) und werden an T+2 Ihrem Konto gutgeschrieben.
    Es wird kein Einkommen am Tag des Freigabens des pfandrechtlich gesicherten Vermögens generiert. Erträge werden täglich auf Ihr Spot-Konto verteilt. Die derzeitige Finanzverwaltung kann jederzeit abgebrochen werden, und das Einkommen wird bis zum Tag vor dem Verlassen von PoS abgerechnet.
    Bei anderen Produkten können Sie nach Erreichen des Mindestaufbewahrungszeitraums entscheiden, ob Sie sie einlösen oder weiterhin aufbewahren möchten. Das endgültige Einkommen wird basierend auf den tatsächlichen Speichertagen berechnet, und das Kapital wird am Tag der Einlösefreigabe freigegeben.
    Unter den angebotenen Finanzprodukten`,
  'content10' : `      1. Vielen Dank, dass Sie erneut Mitglied bei uns geworden sind. Wir werden Ihnen jederzeit mit besseren Dienstleistungen und Unterstützung zur Seite stehen!
    2. Sie erhalten Überweisungs- und Empfangsdienste
    3. Sie genießen exklusive VIP-Raten und günstigere Transaktionskosten.
    4. Sie erhalten ein exklusives VIP-Abzeichen, um Ihre Identität und Ihren Status zu zeigen.
    5. Sie treten unserer exklusiven VIP-Servicegruppe bei und erhalten mehr exklusiven Kundensupport.
    6. Sie erhalten regelmäßig Branchenberichte, um die Marktdynamik und Investitionsmöglichkeiten zu verstehen.
    7. Sie genießen exklusive Festtagsvorteile und maßgeschneiderte Souvenirprivilegien, die Ihnen unsere Fürsorge näherbringen.
    8. Sie haben die Möglichkeit, an exklusiven VIP-Aktivitäten teilzunehmen und mehr erfolgreiche Investoren kennenzulernen.
    9. Sie haben Zugang zu einem exklusiven 24-Stunden-VIP-Kundenservicekanal und erhalten schnellere und vorrangige Unterstützung.
    10. Sie erhalten einen unabhängigen grünen Kanal und genießen bequemere Einzahlungs- und Abhebungsdienste.`,



  },
  fr:{
    "menu_1": "Accueil",
    "menu_2": "Commerce",
    "menu_3": "Mon compte",
    "title1": "Monnaie",
    "title2": "Dernier prix",
    "title3": "Partenaires",
    "title4": "Langue",
    "title5": "Accueil",
    "welcome": "Bienvenue",
    "hello": "Bonjour",
    "title6": "Solde disponible",
    "title7": "Limite de réception quotidienne",
    "title8": "Limite de réception mensuelle",
    "title9": "Dépôt",
    "title10": "Retrait",
    "title11": "Transfert interne",
    "title12": "Mise à niveau de l'adhésion",
    "title13": "Revenu annualisé de la référence",
    "title14": "Période d'investissement",
    "title15": "Quantité de départ",
    "title16": "Participer maintenant",
    "title17": "Enregistrements de la plateforme",
    "title18": "Retirer",
    "title19": "Recharger",
    "title20": "Mon compte",
    "title21": "Mes actifs",
    "title22": "Dépôt",
    "title23": "Retrait",
    "title24": "Transfert",
    "title25": "VIP",
    "title26": "Notification de message",
    "title27": "Achat rapide",
    "title28": "Centre d'aide",
    "title29": "Déconnexion",
    "title30": "Paramètres",
    "title31": "Centre de sécurité",
    "title32": "À propos de nous",
    "title33": "Authentification par e-mail",
    "title34": "Changer le mot de passe",
    "title35": "Modifier l'e-mail",
    "context1": "Ce site est une plateforme financière décentralisée DeFi avec une grande confidentialité et sécurité. Vous n'avez pas à vous soucier de la fuite de vos informations personnelles ou de la vérification KYC. Les utilisateurs peuvent librement stocker et retirer leurs actifs. Les produits financiers décentralisés achetés sur ce site offrent des rendements élevés et une grande stabilité, avec des services rapides et faciles.",
    "context2": "（À propos des applications financières décentralisées DeFi）",
    "context3": "La finance décentralisée (DeFi), abrégée en DeFi, est l'une des applications de la technologie blockchain dans Fintech. DeFi crée un système financier ouvert et transparent grâce à des plateformes blockchain open-source et décentralisées.",
    "context4": "DeFi fait référence à l'utilisation de logiciels open-source et de réseaux décentralisés pour transformer les produits financiers traditionnels en protocoles transparents et sans confiance qui fonctionnent sans intermédiaires inutiles. DeFi est en soi un concept et une architecture centrés sur la 'décentralisation', ce qui signifie qu'il n'y a pas d'administrateur, chaque utilisateur est égal et dispose des mêmes permissions. Si quelqu'un souhaite modifier le contenu, cela doit être approuvé par tout le monde. Les caractéristiques de DeFi incluent la propriété distribuée, l'appartenance à personne et la difficulté de falsification. DeFi bénéficie également des avantages de la blockchain, tels que la transparence, la résistance à la censure et l'immuabilité.",
    "context5": "DeFi offre de nombreux avantages, notamment une base sans confiance, l'absence de permission institutionnelle, et le contrôle des actifs entre les mains de l'individu. Son principal avantage est d'éliminer les 'tiers de confiance' grâce à la décentralisation, tout en maintenant la transparence du grand livre et l'immuabilité.",
    "context6": "La principale différence entre DeFi et Fintech réside dans leur mécanisme de 'confiance'. Fintech applique la technologie blockchain au sein du système financier existant, en continuant de s'appuyer sur des institutions centralisées, et toutes les opérations sont contrôlées par des humains, obtenant la confiance des utilisateurs par des mécanismes tiers. En revanche, la caractéristique principale de DeFi est la décentralisation, utilisant des contrats intelligents. Son code est open-source et la logique centrale est exécutée sur la blockchain, la rendant inaltérable et exécutable, de sorte que les utilisateurs peuvent directement faire confiance à sa structure.",
    "context7": "Les applications visibles de DeFi incluent les protocoles de trading décentralisés, les stablecoins, les prêts, les dérivés, les fonds, les marchés de prédiction, les protocoles de tokenisation, la KYC/AML, et la vérification d'identité.",
    "title36": "Dépôt",
    "title37": "Réseau",
    "title38": "Montant",
    "title39": "Dépôt",
    "title40": "Utilisez l'adresse de recharge du portefeuille ou de l'échange. Une fois le dépôt effectué, le montant sera automatiquement ajouté à votre compte.",
    "title41": "Retirer",
    "title42": "Adresse du portefeuille",
    "title43": "Réseau",
    "title44": "Montant",
    "title45": "Solde disponible",
    "title46": "Avertissement de sécurité : veuillez remplir avec précision votre compte de retrait, les actifs ne peuvent pas être récupérés après le transfert",
    "title47": "Retirer",
    "title48": "Entrez l'adresse du portefeuille",
    "title49": "5-1000000",
    "title50": "Transfert interne",
    "title51": "Compte",
    "title52": "Les membres VIP peuvent utiliser le transfert en un clic sans clé",
    "title53": "Le destinataire doit avoir au moins le niveau VIP1",
    "title54": "Frais de service",
    "title55": "Transfert interne",
    "title56": "Acheter",
    "title57": "Dépôt",
    "title58": "Règles de niveau",
    "title59": "Dépôt",
    "title60": "Limite de collecte quotidienne",
    "title61": "Limite de collecte mensuelle",
    "title62": "Les utilisateurs doivent payer un dépôt pour utiliser les services VIP. Le VIP1 est valable pendant 1 mois. Il sera automatiquement annulé à l'expiration. Les utilisateurs VIP2 et supérieurs, après 2 mois d'utilisation, peuvent contacter le service client pour demander le remboursement du dépôt. Pour plus de détails, veuillez consulter le centre d'aide.",
    "title63": "Enregistrement",
    "title64": "Mise à niveau de l'adhésion",
    "title65": "Le VIP1 doit attendre 30 jours pour un remboursement automatique (aucune demande de remboursement possible).",
    "title66": "Les VIP2-VIP6 peuvent contacter le service client pour demander un remboursement. Après un remboursement réussi, le dépôt sera retourné au solde immédiatement et le niveau VIP sera annulé.",
    "title67": "Notification de message",
    "title68": "Bonjour ! Bienvenue sur la page de connexion!",
    "title69": "Nom du compte",
    "title70": "Mot de passe",
    "title71": "Veuillez entrer votre nom d'utilisateur ou votre adresse e-mail",
    "title72": "Veuillez entrer votre mot de passe",
    "title73": "Entrez le captcha",
    "title74": "Connexion",
    "title75": "Pas de compte",
    "title76": "S'INSCRIRE",
    "title77": "RÉINITIALISER LE MOT DE PASSE",
    "title78": "mot de passe",
    "title79": "Mot de passe",
    "title80": "Confirmer le mot de passe",
    "title81": "CLÉ",
    "title82": "Veuillez entrer la clé",
    "title83": "Veuillez confirmer à nouveau le mot de passe",
    "title84": "Veuillez entrer votre mot de passe",
    "title85": "Confirmer",
    "title86": "Créer un compte",
    "title87": "Nom d'utilisateur",
    "title88": "Veuillez entrer votre nom d'utilisateur",
    "title89": "E-mail",
    "title90": "Veuillez entrer votre adresse e-mail",
    "title91": "Pays",
    "title92": "Mobile",
    "title93": "Veuillez entrer votre numéro de téléphone",
    "title94": "S'inscrire",
    "title95": "SE CONNECTER",
    "title96": "Vous avez un compte",
    "title97": "Achat rapide",
    "title98": "Achetez Tether (USDT) avec une carte de crédit ou de débit sur Paxful",
    "title99": "Achetez Tether (USDT) avec une carte de crédit ou de débit sur ChangeNow",
    "title100": "Achetez Tether (USDT) avec une carte de crédit ou de débit sur Changelly",
    "title101": "Achetez Tether (USDT) avec une carte de crédit ou de débit sur Binance Exchange",
    "title102": "Achetez Tether (USDT) avec une carte de crédit ou de débit sur Bybit Exchange",
    "title103": "Achetez Tether (USDT) avec une carte de crédit ou de débit sur OKX Exchange",
    "title104": "Centre d'aide",
    "title105": "1. Instructions d'utilisation de la plateforme",
    "title106": "2. Comment activer VIP",
    "title107": "3. Transfert VIP (aucune clé nécessaire)",
    "title108": "4. Comment recharger ?",
    "title109": "5. Comment retirer de l'argent ?",
    "title110": "6. Que faire si j'oublie ma clé ?",
    "title111": "7. Comment changer le mot de passe, l'email ?",
    "title112": "8. Comment enregistrer un compte ?",
    "title113": "9. Comment investir ?",
    "title114": "10. Services d'investissement VIP",
    "title115": "Soumettre",
    "title116": "Veuillez entrer le mot de passe de clé",
    "title117": "Veuillez entrer l'adresse de votre portefeuille",
    "title118": "Veuillez entrer le montant du retrait",
    "title119": "Utilisez l'adresse de recharge du portefeuille ou de l'échange. Une fois le dépôt effectué, le montant sera automatiquement ajouté à votre compte.",
    "title120": "Soumission",
    "title121": "Le montant minimum de recharge ne peut pas être inférieur à 50",
    "title122": "Demander un remboursement",
    "title123": "Veuillez entrer le montant que vous souhaitez transférer",
    "title124": "Veuillez entrer le nom d'utilisateur",
    "title125": "Utilisez l'adresse de recharge du portefeuille ou de l'échange. Une fois le dépôt effectué, le montant sera automatiquement ajouté à votre compte.",
    "title126": "QR Code du portefeuille",
    "title127": "En attente",
    "title128": "Veuillez entrer votre numéro de téléphone portable",
    "title129": "Veuillez entrer votre adresse e-mail",
    "title130": "Veuillez entrer un mot de passe",
    "title131": "Veuillez entrer à nouveau le mot de passe",
    "title132": "Les mots de passe que vous avez saisis ne correspondent pas",
    "title133": "Veuillez entrer le mot de passe clé",
    "title134": "Veuillez entrer à nouveau le mot de passe clé",
    "title135": "Les mots de passe clés que vous avez saisis ne correspondent pas",
    "title136": "Veuillez entrer le code de vérification de l'image",
    "title137": "Échec de l'enregistrement",
    "title138": "Veuillez entrer le mot de passe de connexion",
    "title139": "Connexion",
    "title140": "Veuillez entrer à nouveau votre mot de passe",
    "title141": "Veuillez entrer votre mot de passe clé",
    "title142": "Les mots de passe que vous avez saisis ne correspondent pas",
    "title143": "Soumission",
    'content1' : `L'équipe USDT est officiellement entrée dans la plateforme de services de trading d'actifs numériques.
    Grâce à un design technique fiable et évolutif, des services de haute qualité et un meilleur soutien, la plateforme de trading d'actifs numériques USDT a pour objectif de fournir à ses utilisateurs spéciaux des services de transaction d'échange USDT plus pratiques, plus sûrs et plus pratiques, ainsi que des actifs blockchain de haute qualité. Les clients VIP mondiaux bénéficient des services de dépôt, de transfert, de retrait, de gestion financière et d'investissement. Gestion financière exclusive pour VIP, investissements et service client 24 heures sur 24.
    1. La plateforme est ouverte à l'inscription du public. Tout le monde peut enregistrer son propre compte.
    2. Le niveau VIP du compte nouvellement inscrit est 0 (stockage, transfert, retrait, etc.). Ces services sont gratuits et vous n'avez pas besoin de mettre à niveau votre compte pour les utiliser. Si votre compte doit recevoir des transferts d'autres personnes, vous devez utiliser des limites de paiement (à ce moment-là, les comptes de niveau 0 ne pourront pas recevoir de paiements), et vous devez mettre à niveau votre niveau de compte pour devenir VIP pour utiliser la fonction de paiement ; plus le niveau est élevé, les allocations quotidiennes et mensuelles de chacun seront différentes, veuillez mettre à niveau si nécessaire.
    3. Nous pouvons également fournir divers services tels que l'échange de devises et des prêts hypothécaires en USDT pour les clients VIP.`,

'content2' : `     1. Cliquez sur le bouton VIP
    2. Sélectionnez le niveau VIP que vous souhaitez acheter
    3. Payez le dépôt VIP et achetez avec succès
    4. Dépôt requis pour le niveau VIP (vous pouvez annuler à tout moment et récupérer votre dépôt)
    Dépôt VIP1 : 50 USDT (VIP1 est un service d'expérience pour débutants, valable pendant 1 mois, le dépôt ne peut pas être remboursé et ne peut pas être annulé)
    Dépôt VIP2 : 1000 USDT
    Dépôt VIP3 : 3000 USDT
    Dépôt VIP4 : 30000 USDT
    Dépôt VIP5 : 50000 USDT
    Dépôt VIP6 : 100000 USDT
    Le niveau VIP du compte nouvellement inscrit est 0 (stockage, transfert, retrait, etc.). Ces services sont gratuits et vous n'avez pas besoin de mettre à niveau votre compte pour les utiliser. Si votre compte doit recevoir des transferts d'autres personnes, vous devez utiliser des limites de paiement (à ce moment-là, les comptes de niveau 0 ne pourront pas recevoir de paiements), et vous devez mettre à niveau votre niveau de compte pour devenir VIP pour utiliser la fonction de paiement ; plus le niveau est élevé, les allocations quotidiennes et mensuelles de chacun seront différentes, veuillez mettre à niveau si nécessaire.
    VIP1 : Limite de paiement de 10 USDT par jour et 30 USDT par mois
    VIP2 : Limite de paiement de 100 USDT par jour et 3000 USDT par mois
    VIP3 : Limite de paiement de 30000 USDT par jour et 900000 USDT par mois
    VIP4 : Limite de paiement de 100000 USDT par jour et 3000000 USDT par mois
    VIP5 : Limite de paiement de 200000 USDT par jour et 6000000 USDT par mois
    VIP6 : Limite de paiement de 2000000 USDT par jour et 60000000 USDT par mois`,

'content3' : `Note : Les VIP peuvent utiliser la fonction de transfert de la plateforme. Les transferts peuvent uniquement être effectués entre les VIP de la plateforme (les transferts réussis ne peuvent pas être retournés)
    1. Cliquez sur le bouton de transfert
    2. Remplissez correctement le nom d'utilisateur VIP, l'adresse e-mail et le numéro de téléphone
    3. Cliquez sur Confirmer pour terminer le transfert (pas besoin de saisir le KEY)
    4. Si votre compte doit recevoir des transferts d'autres personnes, vous devez utiliser des limites de paiement
    VIP1 : Limite de paiement de 10 USDT par jour et 30 USDT par mois
    VIP2 : Limite de paiement de 100 USDT par jour et 3000 USDT par mois
    VIP3 : Limite de paiement de 30000 USDT par jour et 900000 USDT par mois
    VIP4 : Limite de paiement de 100000 USDT par jour et 3000000 USDT par mois
    VIP5 : Limite de paiement de 200000 USDT par jour et 6000000 USDT par mois
    VIP6 : Limite de paiement de 2000000 USDT par jour et 60000000 USDT par mois
    Exemple de limite de transfert : Si votre compte est VIP5
    VIP5 transfert vers VIP1 (10USDT)
    VIP5 transfert vers VIP2 (100USDT)
    VIP5 transfert vers VIP3 (30000USDT)
    VIP5 transfert vers VIP4 (100000USDT)
    VIP5 transfert vers VIP5 (200000USDT)
    VIP6 transfert vers VIP6 (2000000USDT)`,

'content4' : `      1. Cliquez sur le bouton de recharge
    2. Sélectionnez le montant à recharger
    3. Cliquez sur Confirmer. Entrez (KEY)
    4. Vous obtiendrez une adresse de stockage exclusive pour votre compte, depuis laquelle vous pouvez effectuer un transfert depuis n'importe quelle plateforme ou portefeuille.
    5. Le montant de votre recharge sera automatiquement ajouté au solde de votre compte`,

'content5' : `      1. Cliquez sur le bouton de retrait
    2. Entrez l'adresse de retrait USDT (TRC20)
    3. Entrez le montant du retrait
    4. Cliquez sur Confirmer et entrez le KEY
    5. Le retrait est réussi et votre retrait arrivera dans les 2 heures.`,

'content6' : `      1. Le KEY est défini par vous-même et ne peut être obtenu par d'autres. Le KEY est unique et ne peut pas être réinitialisé.
    2. Le KEY est la seule preuve de votre compte. Il ne peut pas être modifié et ne peut pas être récupéré.
    3. Si vous oubliez le KEY, et que votre compte a encore des fonds à retirer, vous pouvez enregistrer un nouveau compte et mettre à niveau le VIP, puis transférer les fonds de votre ancien compte vers votre nouveau compte pour le retrait. Aucun KEY n'est requis pour les transferts au sein de la plateforme VIP.
    Le KEY est le seul identifiant de votre compte et ne peut être modifié ni récupéré. Afin de protéger la confidentialité des clients, la plateforme ne conservera pas les informations relatives au numéro de compte, mot de passe, numéro de téléphone, e-mail ou KEY de l'utilisateur. Veuillez garder vos informations de connexion en toute sécurité.`,

'content7' : `      1. Cliquez sur ma page d'accueil
    2. Cliquez sur le bouton du centre de sécurité
    3. Sélectionnez le mot de passe ou l'e-mail que vous souhaitez modifier
    4. Entrez le KEY pour compléter`,

'content8' : `      (Note : Lors de l'inscription, veuillez ne pas ouvrir de logiciel de traduction. Si vous ouvrez un logiciel de traduction, le bouton ne sera pas confirmé. Si vous ne pouvez pas vous inscrire, veuillez changer de navigateur)
    1. Cliquez sur le bouton d'inscription sur la page d'accueil
    2. Cliquez pour vous inscrire
    3. Remplissez vos informations d'inscription
    4. Veuillez bien conserver le KEY lorsque vous le définissez. Il ne peut être modifié ou récupéré (sa perte peut entraîner des pertes de biens)`,

'content9' : `      Stockez en toute sécurité et gagnez facilement
    1. Montant minimum de stockage : 5 000 USDT (les fonds du compte inférieurs à 5 000 ne peuvent pas accéder aux services financiers)
    2. Vos actifs généreront des revenus selon différents niveaux, et le revenu total à chaque niveau est cumulatif.
    3. Le revenu annualisé prévu sera ajusté à tout moment en fonction des revenus réels du pool de minage.
    4. Les revenus seront calculés un jour après l'ouverture du PoS.
    5. Pas besoin de geler les actifs hypothéqués, vous pouvez échanger ou retirer des fonds à tout moment.
    Cela représente la date de la promesse. Les gains commenceront le T+1 (le lendemain) et seront distribués sur votre compte le T+2.
    Aucun revenu ne sera généré le jour de la libération des actifs hypothéqués. Les gains seront distribués à votre compte de trading chaque jour. La gestion financière actuelle peut être annulée à tout moment, et les gains seront réglés jusqu'au jour précédent la sortie du PoS.
    Pour d'autres produits, lorsque la période de stockage minimale est atteinte, vous pouvez choisir de racheter ou de continuer à stocker. Le revenu final sera calculé en fonction du nombre réel de jours de stockage, et le capital sera débloqué lors de la journée de rachat.`,

'content10' : `      1. Merci encore d'être devenu notre membre, nous vous fournirons des services et un soutien de meilleure qualité !
    2. Vous bénéficierez des services de transfert et de collecte
    3. Vous bénéficierez de tarifs VIP exclusifs et de coûts de transaction plus avantageux.
    4. Vous recevrez un badge VIP exclusif pour montrer votre identité et votre statut.
    5. Vous rejoindrez notre groupe de services VIP exclusifs et recevrez un support client plus exclusif.
    6. Vous recevrez des rapports réguliers sur l'industrie pour comprendre les dynamiques du marché et les opportunités d'investissement.
    7. Vous bénéficierez d'avantages exclusifs pour les fêtes et de privilèges de souvenirs personnalisés, vous permettant de ressentir nos attentions.
    8. Vous aurez l'opportunité de participer à des activités VIP exclusives et de rencontrer davantage d'investisseurs à succès.
    9. Vous bénéficierez d'un canal exclusif de service client VIP 24h/24 et recevrez un support plus rapide et prioritaire.
    10. Vous disposerez d'un canal vert indépendant et bénéficierez de services de dépôt et de retrait plus pratiques.`,


},
it:{
  "menu_1": "Home",
  "menu_2": "Commercio",
  "menu_3": "Il mio",
  "title1": "Valuta",
  "title2": "Ultimo prezzo",
  "title3": "Partner",
  "title4": "Lingua",
  "title5": "Home",
  "welcome": "Benvenuto",
  "hello": "Ciao",
  "title6": "Saldo disponibile",
  "title7": "Limite di ricezione giornaliero",
  "title8": "Limite di ricezione mensile",
  "title9": "Deposito",
  "title10": "Prelievo",
  "title11": "Trasferimento interno",
  "title12": "Aggiornamento dell'abbonamento",
  "title13": "Reddito annualizzato di riferimento",
  "title14": "Periodo di investimento",
  "title15": "Quantità iniziale",
  "title16": "Partecipa ora",
  "title17": "Registrazioni della piattaforma",
  "title18": "Preleva",
  "title19": "Ricarica",
  "title20": "Il mio",
  "title21": "I miei asset",
  "title22": "Deposito",
  "title23": "Prelievo",
  "title24": "Trasferimento",
  "title25": "VIP",
  "title26": "Notifica messaggi",
  "title27": "Acquisto rapido",
  "title28": "Centro assistenza",
  "title29": "Esci",
  "title30": "Impostazioni",
  "title31": "Centro sicurezza",
  "title32": "Chi siamo",
  "title33": "Autenticazione via e-mail",
  "title34": "Cambia la password",
  "title35": "Modifica e-mail",
  "context1": "Questo sito è una piattaforma finanziaria DeFi decentralizzata con alta anonimato e sicurezza. Non è necessario preoccuparsi della fuga di informazioni personali o della verifica KYC. Gli utenti possono liberamente depositare e prelevare i propri fondi. I prodotti finanziari decentralizzati acquistati su questo sito offrono alti ritorni e stabilità, con servizi facili e veloci.",
  "context2": "（Su DeFi e le applicazioni finanziarie decentralizzate）",
  "context3": "La Finanza Decentralizzata (DeFi), abbreviazione di Decentralized Finance, è una delle applicazioni della tecnologia blockchain in Fintech. DeFi crea un sistema finanziario aperto e trasparente tramite piattaforme blockchain open-source e decentralizzate.",
  "context4": "DeFi si riferisce all'utilizzo di software open-source e reti decentralizzate per trasformare i prodotti finanziari tradizionali in protocolli senza fiducia e trasparenti che operano senza intermediari inutili. DeFi è un concetto e un'architettura centrati sulla 'decentralizzazione', il che significa che non esiste un amministratore, ogni utente è uguale e ha gli stessi permessi. Se qualcuno vuole modificare il contenuto, deve essere approvato da tutti. Le caratteristiche di DeFi includono la proprietà distribuita, non appartiene a nessuno ed è difficile da manomettere. DeFi beneficia anche dei vantaggi della blockchain, come la trasparenza, la resistenza alla censura e l'immutabilità.",
  "context5": "DeFi offre numerosi vantaggi, tra cui una base senza fiducia, nessuna necessità di permesso da istituzioni, e il controllo degli asset nelle mani dell'individuo. Il suo principale vantaggio è eliminare i 'terzi di fiducia' attraverso la decentralizzazione, mantenendo la trasparenza e l'immutabilità del registro.",
  "context6": "La principale differenza tra DeFi e Fintech risiede nel loro meccanismo di 'fiducia'. Fintech applica la tecnologia blockchain all'interno del sistema finanziario esistente, continuando a fare affidamento su istituzioni centralizzate, e tutte le operazioni sono controllate da esseri umani, ottenendo la fiducia degli utenti tramite meccanismi di terzi. Al contrario, la caratteristica principale di DeFi è la decentralizzazione, che utilizza smart contracts. Il suo codice è open-source e la logica centrale è eseguita sulla blockchain, rendendola immutabile e applicabile, quindi gli utenti possono fidarsi direttamente della sua struttura.",
  "context7": "Le applicazioni visibili di DeFi includono protocolli di trading decentralizzati, stablecoins, prestiti, derivati, fondi, mercati di previsione, protocolli di tokenizzazione, KYC/AML, e verifiche dell'identità.",
  "title36": "Deposito",
  "title37": "Rete",
  "title38": "Importo",
  "title39": "Deposito",
  "title40": "Usa l'indirizzo di ricarica del portafoglio o dell'exchange. Dopo il deposito, l'importo verrà automaticamente aggiunto al tuo account.",
  "title41": "Prelievo",
  "title42": "Indirizzo del portafoglio",
  "title43": "Rete",
  "title44": "Importo",
  "title45": "Saldo disponibile",
  "title46": "Suggerimento di sicurezza: Si prega di compilare accuratamente il proprio account di prelievo, i fondi non possono essere restituiti dopo il trasferimento",
  "title47": "Preleva",
  "title48": "Inserisci l'indirizzo del portafoglio",
  "title49": "5-1000000",
  "title50": "Trasferimento interno",
  "title51": "Account",
  "title52": "I membri VIP possono utilizzare il trasferimento con un solo clic senza chiave",
  "title53": "Il destinatario deve avere almeno VIP1",
  "title54": "Commissione di servizio",
  "title55": "Trasferimento interno",
  "title56": "Acquista",
  "title57": "Deposito",
  "title58": "Regole di livello",
  "title59": "Deposito",
  "title60": "Limite di raccolta giornaliero",
  "title61": "Limite di raccolta mensile",
  "title62": "Gli utenti devono pagare un deposito per utilizzare i servizi VIP. VIP1 è valido per 1 mese. Viene annullato automaticamente alla scadenza. Gli utenti VIP2 e superiori, dopo 2 mesi di utilizzo, possono contattare il servizio clienti per chiedere il rimborso del deposito. Per maggiori dettagli, consulta il centro assistenza.",
  "title63": "Registrazioni",
  "title64": "Aggiornamento dell'abbonamento",
  "title65": "VIP1 deve aspettare 30 giorni per il rimborso automatico (non è possibile richiedere il rimborso).",
  "title66": "VIP2-VIP6 possono contattare il servizio clienti per richiedere il rimborso. Dopo il rimborso, il deposito verrà restituito al saldo immediatamente e il livello VIP verrà annullato.",
  "title67": "Notifica messaggio",
  "title68": "Ciao! Benvenuto nel login!",
  "title69": "Account",
  "title70": "Password",
  "title71": "Inserisci il tuo nome utente o indirizzo e-mail",
  "title72": "Inserisci la tua password",
  "title73": "Inserisci il captcha",
  "title74": "Accedi",
  "title75": "Nessun account",
  "title76": "REGISTRATI",
  "title77": "RECUPERA PASSWORD",
  "title78": "password",
  "title79": "Password",
  "title80": "Conferma password",
  "title81": "CHIAVE",
  "title82": "Inserisci la chiave",
  "title83": "Conferma la password di nuovo",
  "title84": "Inserisci la tua password",
  "title85": "Conferma",
  "title86": "Crea un account",
  "title87": "Nome utente",
  "title88": "Inserisci il tuo nome utente",
  "title89": "E-mail",
  "title90": "Inserisci il tuo indirizzo e-mail",
  "title91": "Paese",
  "title92": "Mobile",
  "title93": "Inserisci il numero di telefono",
  "title94": "Registrati",
  "title95": "ACCEDI",
  "title96": "Hai un account",
  "title97": "Acquisto rapido",
  "title98": "Acquista Tether (USDT) con carta di credito o debito su Paxful",
  "title99": "Acquista Tether (USDT) con carta di credito o debito su ChangeNow",
  "title100": "Acquista Tether (USDT) con carta di credito o debito su Changelly",
  "title101": "Acquista Tether (USDT) con carta di credito o debito su Binance Exchange",
  "title102": "Acquista Tether (USDT) con carta di credito o debito su Bybit Exchange",
  "title103": "Acquista Tether (USDT) con carta di credito o debito su OKX Exchange",
  "title104": "Centro assistenza",
  "title105": "1. Istruzioni per l'uso della piattaforma",
  "title106": "2. Come attivare VIP",
  "title107": "3. Trasferimento VIP (senza chiave)",
  "title108": "4. Come ricaricare?",
  "title109": "5. Come prelevare?",
  "title110": "6. Cosa fare se dimentico la chiave?",
  "title111": "7. Come cambiare la password, l'e-mail?",
  "title112": "8. Come registrarsi?",
  "title113": "9. Come investire?",
  "title114": "10. Servizi di investimento VIP",
  "title115": "Invia",
  "title116": "Inserisci la password della chiave",
  "title117": "Inserisci l'indirizzo del tuo portafoglio",
  "title118": "Indirizzo portafoglio",
  "title119": "Usa l'indirizzo di ricarica del portafoglio o dell'exchange. Dopo il deposito, l'importo verrà automaticamente aggiunto al tuo account.",
  "title120": "Inviando",
  "title121": "L'importo minimo di ricarica non può essere inferiore a 50",
  "title122": "Richiedi rimborso",
  "title123": "Inserisci l'importo che desideri trasferire",
  "title124": "Inserisci il nome utente",
  "title125": "Usa l'indirizzo di ricarica del portafoglio o dell'exchange. Dopo il deposito, l'importo verrà automaticamente aggiunto al tuo account.",
  "title126": "Codice QR del portafoglio",
  "title127": "In attesa",
  "title128": "Inserisci il tuo numero di telefono",
  "title129": "Inserisci il tuo indirizzo e-mail",
  "title130": "Inserisci una password",
  "title131": "Inserisci di nuovo la password",
  "title132": "Le password inserite non corrispondono",
  "title133": "Inserisci la password della chiave",
  "title134": "Errore di ricarica",
  "title135": "Modifica",
  "title136": "Invio",
  "title137": "Trattamento della piattaforma",
  "title138": "Accesso al servizio",
  "title139": "I tuoi soldi sono al sicuro",
  "title140": "Il servizio sta ricevendo attenzione",
  "title141": "Centro assistenza",
  "title142": "Opzioni di pagamento",
  "title143": "Sicurezza",
  'content1' : `Il team USDT è ufficialmente entrato nella piattaforma di servizi di trading di asset digitali.
    Con un design tecnico affidabile ed espandibile, servizi di alta qualità e maggiore supporto, la piattaforma di trading di asset digitali USDT ha l'obiettivo di offrire ai suoi utenti speciali servizi di transazione di scambio USDT più comodi, sicuri e convenienti, oltre a risorse blockchain di alta qualità. I clienti VIP globali godono di servizi di deposito, trasferimento, prelievo, gestione finanziaria e investimento. Gestione finanziaria esclusiva per VIP, investimenti e servizio clienti 24 ore su 24.
    1. La piattaforma è aperta alla registrazione del pubblico. Chiunque può registrare il proprio account.
    2. Il livello VIP del nuovo account registrato è 0 (archiviazione, trasferimento, prelievo, ecc.). Questi servizi sono gratuiti e non è necessario aggiornare il tuo account per utilizzarli. Se il tuo account deve ricevere trasferimenti da altri, devi usare i limiti di pagamento (in questo momento, gli account di livello 0 non possono ricevere pagamenti), e dovrai aggiornare il tuo livello di account a VIP per utilizzare la funzione di pagamento; più alto è il livello, più le allocazioni giornaliere e mensili saranno diverse, aggiorna come necessario.
    3. Possiamo anche fornire vari servizi come scambio di valute e prestiti ipotecari USDT per clienti VIP.`,

'content2' : `     1. Clicca sul pulsante VIP
    2. Seleziona il livello VIP che desideri acquistare
    3. Paga il deposito VIP e acquista con successo
    4. Deposito richiesto per il livello VIP (puoi annullarlo in qualsiasi momento e ricevere il tuo deposito indietro)
    Deposito VIP1: 50 USDT (VIP1 è un servizio di esperienza per principianti, valido per 1 mese, il deposito non è rimborsabile e non può essere annullato)
    Deposito VIP2: 1000 USDT
    Deposito VIP3: 3000 USDT
    Deposito VIP4: 30000 USDT
    Deposito VIP5: 50000 USDT
    Deposito VIP6: 100000 USDT
    Il livello VIP del nuovo account registrato è 0 (archiviazione, trasferimento, prelievo, ecc.). Questi servizi sono gratuiti e non è necessario aggiornare il tuo account per utilizzarli. Se il tuo account deve ricevere trasferimenti da altri, devi usare i limiti di pagamento (in questo momento, gli account di livello 0 non possono ricevere pagamenti), e dovrai aggiornare il tuo livello di account a VIP per utilizzare la funzione di pagamento; più alto è il livello, più le allocazioni giornaliere e mensili saranno diverse, aggiorna come necessario.
    VIP1: Limite di pagamento di 10 USDT al giorno e 30 USDT al mese
    VIP2: Limite di pagamento di 100 USDT al giorno e 3000 USDT al mese
    VIP3: Limite di pagamento di 30000 USDT al giorno e 900000 USDT al mese
    VIP4: Limite di pagamento di 100000 USDT al giorno e 3000000 USDT al mese
    VIP5: Limite di pagamento di 200000 USDT al giorno e 6000000 USDT al mese
    VIP6: Limite di pagamento di 2000000 USDT al giorno e 60000000 USDT al mese`,

'content3' : `Nota: I VIP possono utilizzare la funzione di trasferimento della piattaforma. I trasferimenti possono essere effettuati solo tra VIP della piattaforma (i trasferimenti riusciti non possono essere restituiti)
    1. Clicca sul pulsante di trasferimento
    2. Compila correttamente il nome utente VIP, l'indirizzo email e il numero di telefono
    3. Clicca su Conferma per completare il trasferimento (non è necessario inserire il KEY)
    4. Se il tuo account deve ricevere trasferimenti da altri, devi usare i limiti di pagamento
    VIP1: Limite di pagamento di 10 USDT al giorno e 30 USDT al mese
    VIP2: Limite di pagamento di 100 USDT al giorno e 3000 USDT al mese
    VIP3: Limite di pagamento di 30000 USDT al giorno e 900000 USDT al mese
    VIP4: Limite di pagamento di 100000 USDT al giorno e 3000000 USDT al mese
    VIP5: Limite di pagamento di 200000 USDT al giorno e 6000000 USDT al mese
    VIP6: Limite di pagamento di 2000000 USDT al giorno e 60000000 USDT al mese
    Esempio di limite di trasferimento: Se il tuo account è VIP5
    VIP5 trasferimento a VIP1 (10USDT)
    VIP5 trasferimento a VIP2 (100USDT)
    VIP5 trasferimento a VIP3 (30000USDT)
    VIP5 trasferimento a VIP4 (100000USDT)
    VIP5 trasferimento a VIP5 (200000USDT)
    VIP6 trasferimento a VIP6 (2000000USDT)`,

'content4' : `      1. Clicca sul pulsante di ricarica
    2. Seleziona l'importo da ricaricare
    3. Clicca su Conferma. Inserisci (KEY)
    4. Otterrai un indirizzo di archiviazione esclusivo per il tuo account, dal quale potrai effettuare un trasferimento da qualsiasi exchange o wallet.
    5. L'importo della tua ricarica verrà automaticamente aggiunto al saldo del tuo account`,

'content5' : `      1. Clicca sul pulsante di prelievo
    2. Inserisci l'indirizzo di prelievo USDT (TRC20)
    3. Inserisci l'importo del prelievo
    4. Clicca su Conferma e inserisci il KEY
    5. Il prelievo è riuscito e il tuo prelievo arriverà entro 2 ore.`,

'content6' : `      1. Il KEY è impostato da te e non può essere ottenuto da altri. Il KEY è unico e non può essere reimpostato.
    2. Il KEY è l'unica prova del tuo account. Non può essere modificato né recuperato.
    3. Se dimentichi il KEY, e il tuo account ha ancora fondi da prelevare, puoi registrare un nuovo account e aggiornare il VIP, quindi trasferire i fondi dal tuo vecchio account al tuo nuovo account per il prelievo. Non è richiesto KEY per i trasferimenti all'interno della piattaforma VIP.
    Il KEY è l'unica credenziale per il tuo account e non può essere modificato o recuperato. Per proteggere la privacy dei clienti, la piattaforma non registrerà il numero dell'account, la password, il numero di telefono, l'e-mail o il KEY dell'utente. Si prega di mantenere le informazioni di login al sicuro.`,

'content7' : `      1. Clicca sulla mia homepage
    2. Clicca sul pulsante Centro di Sicurezza
    3. Seleziona la password o l'email che desideri modificare
    4. Inserisci il KEY per completare`,

'content8' : `      (Nota: Quando ti registri, non aprire il software di traduzione. Se apri il software di traduzione, il pulsante non sarà confermato. Se non riesci a registrarti, cambia browser)
    1. Clicca sul pulsante di registrazione nella homepage
    2. Clicca per registrarti
    3. Compila le tue informazioni di registrazione
    4. Si prega di conservare correttamente il KEY quando lo imposti. Non può essere modificato o recuperato (la sua perdita può causare danni patrimoniali)`,

    'content9' : `      Archivia in modo sicuro e guadagna facilmente
    1. Importo minimo di archiviazione: 5.000 USDT (i fondi dell'account inferiori a 5.000 non possono accedere ai servizi finanziari)
    2. I tuoi asset genereranno reddito in base ai diversi livelli, e il reddito totale per ogni livello è cumulativo.
    3. Il reddito annualizzato previsto sarà regolato in qualsiasi momento in base ai redditi effettivi del pool di mining.
    4. I redditi saranno calcolati un giorno dopo l'apertura del PoS.
    5. Non è necessario congelare gli asset ipotecati, puoi scambiare o prelevare monete in qualsiasi momento.
    Rappresenta la data del pegno. I guadagni inizieranno il T+1 (il giorno successivo) e saranno distribuiti sul tuo account il T+2.
    Nessun guadagno verrà generato il giorno in cui rilasci gli asset ipotecati. I guadagni saranno distribuiti ogni giorno sul tuo account di trading. La gestione finanziaria attuale può essere annullata in qualsiasi momento e i guadagni saranno calcolati fino al giorno precedente il rilascio del PoS.
    Per altri prodotti, quando il periodo minimo di archiviazione è raggiunto, puoi scegliere di riscattare o continuare a depositare. Il reddito finale sarà calcolato in base al numero effettivo di giorni di deposito e il capitale sarà sbloccato al giorno del riscatto.`,

'content10' : `      1. Grazie ancora per essere diventato nostro membro, ti forniremo servizi e supporto di qualità superiore!
    2. Godrai dei servizi di trasferimento e raccolta
    3. Beneficerai di tariffe VIP esclusive e di costi di transazione più vantaggiosi.
    4. Riceverai un distintivo VIP esclusivo per mostrare la tua identità e il tuo status.
    5. Unirsi al nostro gruppo di servizi VIP esclusivi e ricevere un supporto clienti più esclusivo.
    6. Riceverai report regolari sull'industria per comprendere le dinamiche del mercato e le opportunità di investimento.
    7. Godrai di vantaggi esclusivi per le feste e privilegi di regali personalizzati, così da sentirti speciale.
    8. Avrai l'opportunità di partecipare a eventi VIP esclusivi e incontrare più investitori di successo.
    9. Godrai di un canale esclusivo di supporto clienti VIP 24 ore su 24 e riceverai assistenza più rapida e prioritaria.
    10. Disporrai di un canale indipendente e verde, con servizi di deposito e prelievo più comodi.`,

},
"ko":{
  "menu_1": "홈",
  "menu_2": "거래",
  "menu_3": "내",
  "title1": "통화",
  "title2": "최신 가격",
  "title3": "파트너",
  "title4": "언어",
  "title5": "홈",
  "welcome": "환영합니다",
  "hello": "안녕하세요",
  "title6": "사용 가능한 잔액",
  "title7": "일일 수신 한도",
  "title8": "월간 수신 한도",
  "title9": "입금",
  "title10": "출금",
  "title11": "내부 전송",
  "title12": "회원 등급 업그레이드",
  "title13": "추천 연간 수익",
  "title14": "투자 기간",
  "title15": "시작 수량",
  "title16": "지금 참여",
  "title17": "플랫폼 기록",
  "title18": "출금",
  "title19": "충전",
  "title20": "내",
  "title21": "내 자산",
  "title22": "입금",
  "title23": "출금",
  "title24": "전송",
  "title25": "VIP",
  "title26": "메시지 알림",
  "title27": "빠른 구매",
  "title28": "도움말 센터",
  "title29": "로그아웃",
  "title30": "설정",
  "title31": "보안 센터",
  "title32": "회사 소개",
  "title33": "이메일 인증",
  "title34": "비밀번호 변경",
  "title35": "이메일 수정",
  "context1": "이 사이트는 높은 익명성과 보안을 갖춘 분산형 DeFi 금융 플랫폼입니다. 개인 정보 유출이나 KYC 인증에 대해 걱정할 필요가 없습니다. 사용자는 자유롭게 자산을 입금하고 출금할 수 있습니다. 이 사이트에서 구매한 분산형 금융 상품은 높은 수익과 안정성을 제공하며, 빠르고 쉬운 서비스를 제공합니다.",
  "context2": "（DeFi 분산형 금융 애플리케이션에 대해）",
  "context3": "분산형 금융(DeFi)은 블록체인 기술이 핀테크(Fintech) 분야에서 응용된 형태 중 하나입니다. DeFi는 오픈 소스 및 분산형 블록체인 플랫폼을 통해 개방적이고 투명한 금융 시스템을 만듭니다.",
  "context4": "DeFi는 오픈 소스 소프트웨어와 분산 네트워크를 사용하여 전통적인 금융 제품을 신뢰 없이 투명하게 작동하는 프로토콜로 변환하는 것을 의미합니다. DeFi는 '탈중앙화'에 중점을 둔 개념과 아키텍처로, 관리자 없이 모든 사용자가 동등한 권한을 가집니다. 내용을 변경하려면 모두의 동의를 받아야 하며, DeFi의 특징은 분산된 소유권과, 누구에게도 속하지 않고, 변경이 어렵다는 점입니다. 또한 DeFi는 블록체인의 투명성, 검열 저항성, 불변성 등의 장점을 갖추고 있습니다.",
  "context5": "DeFi는 신뢰할 수 있는 기초, 기관의 허가 없이도 자산을 관리할 수 있는 장점 등을 제공합니다. 가장 큰 장점은 탈중앙화를 통해 '신뢰할 수 있는 제3자'를 없애고, 원장 투명성과 불변성을 유지한다는 점입니다.",
  "context6": "DeFi와 핀테크의 가장 큰 차이점은 '신뢰' 메커니즘에 있습니다. 핀테크는 블록체인 기술을 기존 금융 시스템에 적용하지만 여전히 중앙 집중화된 기관에 의존하며, 모든 운영은 인간에 의해 통제되고 제3자를 통해 사용자 신뢰를 얻습니다. 반면 DeFi는 탈중앙화를 특징으로 하며, 스마트 계약을 사용합니다. 코드가 오픈 소스이며 핵심 로직이 블록체인에서 실행되어 변경 불가능하고 강제 실행되므로 사용자들은 그 구조를 직접 신뢰할 수 있습니다.",
  "context7": "DeFi의 구체적인 응용 분야에는 탈중앙화된 거래 프로토콜, 스테이블코인, 대출, 파생상품, 펀드, 예측 시장, 토큰화 프로토콜, KYC/AML, 신원 인증 등이 포함됩니다.",
  "title36": "입금",
  "title37": "네트워크",
  "title38": "금액",
  "title39": "입금",
  "title40": "지갑 또는 거래소의 충전 주소를 사용하세요. 충전이 완료되면 금액이 자동으로 계정에 추가됩니다.",
  "title41": "출금",
  "title42": "지갑 주소",
  "title43": "네트워크",
  "title44": "금액",
  "title45": "사용 가능한 잔액",
  "title46": "보안 팁: 출금 계정을 정확하게 입력하세요. 전송 후 자산은 반환되지 않습니다.",
  "title47": "출금",
  "title48": "지갑 주소 입력",
  "title49": "5-1000000",
  "title50": "내부 전송",
  "title51": "계정",
  "title52": "VIP 회원은 키 없이 원클릭 전송을 사용할 수 있습니다.",
  "title53": "수신자는 최소 VIP1 등급이어야 합니다.",
  "title54": "수수료",
  "title55": "내부 전송",
  "title56": "구매",
  "title57": "입금",
  "title58": "레벨 규칙",
  "title59": "입금",
  "title60": "일일 수집 한도",
  "title61": "월간 수집 한도",
  "title62": "사용자는 VIP 서비스를 사용하기 위해 입금을 해야 합니다. VIP1은 1개월 동안 유효하며 만료 시 자동 취소됩니다. VIP2 이상 사용자는 2개월 후 고객 서비스에 문의하여 입금 환불을 신청할 수 있습니다. 자세한 내용은 도움말 센터에서 확인하세요.",
  "title63": "기록",
  "title64": "회원 등급 업그레이드",
  "title65": "VIP1은 자동 환불을 위해 30일을 기다려야 합니다(환불 신청 불가).",
  "title66": "VIP2-VIP6은 고객 서비스에 문의하여 환불을 신청할 수 있습니다. 환불이 완료되면 입금액이 즉시 잔액으로 반환되고 VIP 등급이 취소됩니다.",
  "title67": "메시지 알림",
  "title68": "안녕하세요! 로그인에 오신 것을 환영합니다!",
  "title69": "계정",
  "title70": "비밀번호",
  "title71": "사용자 이름 또는 이메일 주소를 입력하세요.",
  "title72": "비밀번호를 입력하세요.",
  "title73": "캡차를 입력하세요.",
  "title74": "로그인",
  "title75": "계정이 없습니다.",
  "title76": "가입하기",
  "title77": "비밀번호 찾기",
  "title78": "비밀번호",
  "title79": "비밀번호",
  "title80": "비밀번호 확인",
  "title81": "키",
  "title82": "키를 입력하세요.",
  "title83": "비밀번호를 다시 확인하세요.",
  "title84": "비밀번호를 입력하세요.",
  "title85": "확인",
  "title86": "계정 만들기",
  "title87": "사용자 이름",
  "title88": "사용자 이름을 입력하세요.",
  "title89": "이메일",
  "title90": "이메일 주소를 입력하세요.",
  "title91": "국가",
  "title92": "전화번호",
  "title93": "전화번호를 입력하세요.",
  "title94": "등록",
  "title95": "로그인",
  "title96": "계정이 있으신가요?",
  "title97": "빠른 구매",
  "title98": "Paxful에서 신용카드 또는 직불카드로 테더(USDT) 구매",
  "title99": "ChangeNow에서 신용카드 또는 직불카드로 테더(USDT) 구매",
  "title100": "Changelly에서 신용카드 또는 직불카드로 테더(USDT) 구매",
  "title101": "Binance에서 신용카드 또는 직불카드로 테더(USDT) 구매",
  "title102": "Bybit에서 신용카드 또는 직불카드로 테더(USDT) 구매",
  "title103": "OKX에서 신용카드 또는 직불카드로 테더(USDT) 구매",
  "title104": "도움말 센터",
  "title105": "1. 플랫폼 사용 설명서",
  "title106": "2. VIP 활성화 방법",
  "title107": "3. VIP 전송 (키 없이)",
  "title108": "4. 충전 방법은?",
  "title109": "5. 출금 방법은?",
  "title110": "6. 키를 잊어버린 경우 어떻게 하나요?",
  "title111": "7. 비밀번호, 이메일을 어떻게 변경하나요?",
  "title112": "8. 계정을 어떻게 등록하나요?",
  "title113": "9. 어떻게 투자하나요?",
  "title114": "10. VIP 투자 서비스",
  "title115": "제출",
  "title116": "키 비밀번호를 입력하세요.",
  "title117": "출금 주소를 입력하세요.",
  "title118": "출금 금액을 입력하세요.",
  "title119": "지갑 또는 거래소의 충전 주소를 사용하세요. 충전이 완료되면 금액이 자동으로 계정에 추가됩니다.",
  "title120": "제출 중",
  "title121": "최소 충전 금액은 50 이하일 수 없습니다.",
  "title122": "환불 신청",
  "title123": "전송할 금액을 입력하세요.",
  "title124": "사용자 이름을 입력하세요.",
  "title125": "지갑 또는 거래소의 충전 주소를 사용하세요. 충전이 완료되면 금액이 자동으로 계정에 추가됩니다.",
  "title126": "지갑 QR 코드",
  "title127": "대기 중",
  "title128": "전화번호를 입력하세요.",
  "title129": "이메일 주소를 입력하세요.",
  "title130": "비밀번호를 입력하세요.",
  "title131": "비밀번호를 다시 입력하세요.",
  "title132": "입력한 두 비밀번호가 일치하지 않습니다.",
  "title133": "키 비밀번호를 입력하세요.",
  "title134": "키 비밀번호를 다시 입력하세요.",
  "title135": "입력한 두 키 비밀번호가 일치하지 않습니다.",
  "title136": "이미지 인증 코드를 입력하세요.",
  "title137": "등록 실패",
  "title138": "로그인 비밀번호를 입력하세요.",
  "title139": "로그인 중",
  "title140": "비밀번호를 다시 입력하세요.",
  "title141": "키 비밀번호를 입력하세요.",
  "title142": "입력한 두 비밀번호가 일치하지 않습니다.",
  "title143": "제출 중",
  'content1' : `USDT 팀은 디지털 자산 거래 서비스 플랫폼에 공식적으로 진입했습니다.
    신뢰할 수 있고 확장 가능한 기술 설계, 고품질 서비스 및 더 많은 지원을 통해 USDT 디지털 자산 거래 플랫폼은 특별한 사용자에게 더 편리하고 안전하며 편리한 USDT 교환 거래 서비스와 고품질 블록체인 자산을 제공하는 것을 목표로 하고 있습니다. 글로벌 VIP 고객에게는 예치, 송금, 출금, 금융 관리 및 투자 서비스가 제공됩니다. VIP 전용 금융 관리, 투자 및 24시간 고객 서비스.
    1. 플랫폼은 관람객 등록을 위해 열려 있습니다. 누구나 자신의 계정을 등록할 수 있습니다.
    2. 새로 등록된 계정의 VIP 레벨은 0입니다 (저장, 송금, 출금 등). 이러한 서비스는 무료이며 이를 사용하기 위해 계정을 업그레이드할 필요는 없습니다. 계정이 다른 사람으로부터 송금을 받으려면 결제 한도를 사용해야 합니다(이 시점에서 레벨 0 계정은 송금을 받을 수 없음). 결제 기능을 사용하려면 계정 레벨을 VIP로 업그레이드해야 합니다. 레벨이 높을수록 하루 및 월별 한도가 다를 수 있습니다. 필요에 따라 업그레이드해 주세요.
    3. 우리는 VIP 고객을 위해 다양한 서비스(통화 교환 및 USDT 담보 대출 등)를 제공할 수 있습니다.`,

'content2' : `     1. VIP 버튼을 클릭하세요
    2. 구매할 VIP 레벨을 선택하세요
    3. VIP 보증금을 지불하고 성공적으로 구매하세요
    4. VIP 레벨에 필요한 보증금 (언제든지 취소하고 보증금을 반환받을 수 있습니다)
    VIP1 보증금: 50 USDT (VIP1은 초보자 체험 서비스로 1개월 유효, 보증금은 환불되지 않으며 취소할 수 없음)
    VIP2 보증금: 1000 USDT
    VIP3 보증금: 3000 USDT
    VIP4 보증금: 30000 USDT
    VIP5 보증금: 50000 USDT
    VIP6 보증금: 100000 USDT
    새로 등록된 계정의 VIP 레벨은 0입니다 (저장, 송금, 출금 등). 이러한 서비스는 무료이며 이를 사용하기 위해 계정을 업그레이드할 필요는 없습니다. 계정이 다른 사람으로부터 송금을 받으려면 결제 한도를 사용해야 합니다(이 시점에서 레벨 0 계정은 송금을 받을 수 없음). 결제 기능을 사용하려면 계정 레벨을 VIP로 업그레이드해야 합니다. 레벨이 높을수록 하루 및 월별 한도가 다를 수 있습니다. 필요에 따라 업그레이드해 주세요.
    VIP1: 하루 10 USDT, 월 30 USDT 결제 한도
    VIP2: 하루 100 USDT, 월 3000 USDT 결제 한도
    VIP3: 하루 30000 USDT, 월 900000 USDT 결제 한도
    VIP4: 하루 100000 USDT, 월 3000000 USDT 결제 한도
    VIP5: 하루 200000 USDT, 월 6000000 USDT 결제 한도
    VIP6: 하루 2000000 USDT, 월 60000000 USDT 결제 한도`,

'content3' : `참고: VIP는 플랫폼 송금 기능을 사용할 수 있습니다. 송금은 플랫폼 VIP 간에만 가능합니다 (성공적인 송금은 반환되지 않습니다)
    1. 송금 버튼을 클릭하세요
    2. VIP 사용자 이름, 이메일 주소 및 전화번호를 올바르게 입력하세요
    3. 확인을 클릭하여 송금을 완료하세요 (KEY를 입력할 필요 없음)
    4. 계정이 다른 사람으로부터 송금을 받으려면 결제 한도를 사용해야 합니다
    VIP1: 하루 10 USDT, 월 30 USDT 결제 한도
    VIP2: 하루 100 USDT, 월 3000 USDT 결제 한도
    VIP3: 하루 30000 USDT, 월 900000 USDT 결제 한도
    VIP4: 하루 100000 USDT, 월 3000000 USDT 결제 한도
    VIP5: 하루 200000 USDT, 월 6000000 USDT 결제 한도
    VIP6: 하루 2000000 USDT, 월 60000000 USDT 결제 한도
    송금 한도 예시: VIP5 계정의 경우
    VIP5에서 VIP1로 송금 (10USDT)
    VIP5에서 VIP2로 송금 (100USDT)
    VIP5에서 VIP3로 송금 (30000USDT)
    VIP5에서 VIP4로 송금 (100000USDT)
    VIP5에서 VIP5로 송금 (200000USDT)
    VIP6에서 VIP6로 송금 (2000000USDT)`,

'content4' : `      1. 충전 버튼을 클릭하세요
    2. 충전할 금액을 선택하세요
    3. 확인을 클릭하고 (KEY)을 입력하세요
    4. 귀하의 계정에 대한 전용 충전 주소를 받게 됩니다. 이를 통해 어떤 거래소나 지갑에서든 송금할 수 있습니다.
    5. 충전된 금액은 자동으로 계정 잔액에 추가됩니다`,

'content5' : `      1. 출금 버튼을 클릭하세요
    2. USDT 출금 주소 (TRC20)를 입력하세요
    3. 출금 금액을 입력하세요
    4. 확인 버튼을 클릭하고 KEY를 입력하세요
    5. 출금이 성공하면 2시간 이내에 출금이 완료됩니다.`,

'content6' : `      1. KEY는 본인이 설정하며 다른 사람이 얻을 수 없습니다. KEY는 고유하며 재설정할 수 없습니다.
    2. KEY는 계정의 유일한 증명서입니다. 변경할 수 없으며 복구할 수 없습니다.
    3. 만약 KEY를 잊어버리면, 계정에 자산이 남아 있어도 새 계정을 등록하고 VIP를 업그레이드한 후 기존 계정에서 새 계정으로 자산을 이체하여 출금할 수 있습니다. VIP 플랫폼 내에서는 KEY 없이 송금할 수 있습니다.
    KEY는 계정의 유일한 인증서이며 변경하거나 복구할 수 없습니다. 고객의 개인정보를 보호하기 위해 플랫폼은 사용자의 계좌번호, 비밀번호, 전화번호, 이메일 또는 KEY를 기록하지 않습니다. 로그인 정보를 안전하게 보관해 주세요.`,

'content7' : `      1. 내 홈페이지를 클릭하세요
    2. 보안 센터 버튼을 클릭하세요
    3. 변경할 비밀번호 또는 이메일을 선택하세요
    4. KEY를 입력하여 완료하세요`,

'content8' : `      (참고: 등록 시 번역 소프트웨어를 열지 마세요. 번역 소프트웨어를 열면 버튼이 확인되지 않습니다. 등록이 안 될 경우 브라우저를 변경해 보세요)
    1. 홈페이지에서 등록 버튼을 클릭하세요
    2. 등록하기를 클릭하세요
    3. 등록 정보를 입력하세요
    4. KEY를 설정할 때 잘 보관하세요. 수정하거나 복구할 수 없습니다 (분실 시 자산에 피해를 줄 수 있습니다)`,

'content9' : `      안전하게 저장하고 쉽게 수익을 올리세요
    1. 최소 저장 금액: 5,000 USDT (5,000 이하의 계좌 자산은 금융 서비스를 이용할 수 없습니다)
    2: 자산은 다른 레벨에 따라 수익을 얻으며 각 레벨의 총 수익은 누적됩니다.
    3: 예상 연간 수익률은 실제 마이닝 풀의 수익에 따라 언제든지 조정됩니다.
    4: PoS가 열리고 1일 후 수익이 계산됩니다.
    5: 담보 자산을 동결할 필요 없이 언제든지 거래하거나 코인을 인출할 수 있습니다.
    이는 담보 날짜를 나타냅니다. 수익은 T+1(다음 날)부터 시작되며 T+2에 계정으로 분배됩니다.
    담보 자산을 해제하는 당일에는 수익이 발생하지 않습니다. 수익은 매일 귀하의 거래 계좌로 분배됩니다. 현재의 금융 관리는 언제든지 취소할 수 있으며, PoS를 종료하기 전까지의 수익은 모두 계산됩니다.
    다른 제품의 경우 최소 저장 기간에 도달하면 상환하거나 계속 보관할 수 있습니다. 최종 수익은 실제 보관 일수에 따라 계산되며, 원금은 상환 해제일에 해제됩니다.`,

'content10' : `      다시 한번 회원이 되어주셔서 감사드리며, 더 나은 서비스와 지원을 아낌없이 제공하겠습니다!
    2. 송금 및 수금 서비스를 이용할 수 있습니다.
    3. VIP 전용 요금과 더 유리한 거래 비용을 즐기세요.
    4. 당신의 신분과 지위를 나타내는 VIP 전용 배지를 받게 됩니다.
    5. 전용 VIP 서비스 그룹에 가입하여 더 특별한 고객 지원을 받게 됩니다.
    6. 산업 보고서를 정기적으로 받아 시장 동향과 투자 기회를 파악할 수 있습니다.
    7. 축제 혜택과 맞춤형 기념품 특권을 통해 특별함을 느낄 수 있습니다.
    8. VIP 전용 이벤트에 참여하고 더 많은 성공적인 투자자들을 만날 기회를 갖게 됩니다.
    9. 24시간 VIP 고객 서비스 전용 채널을 이용하여 더 빠르고 우선적인 지원을 받게 됩니다.
    10. 독립적인 그린 채널을 통해 더 편리한 입출금 서비스를 즐길 수 있습니다.`,


},
es:{
  "menu_1": "Inicio",
  "menu_2": "Comercio",
  "menu_3": "Mi",
  "title1": "Moneda",
  "title2": "Último precio",
  "title3": "Socios",
  "title4": "Idioma",
  "title5": "Inicio",
  "welcome": "Bienvenido",
  "hello": "Hola",
  "title6": "Saldo disponible",
  "title7": "Límite de recepción diaria",
  "title8": "Límite de recepción mensual",
  "title9": "Depósito",
  "title10": "Retiro",
  "title11": "Transferencia interna",
  "title12": "Mejorar membresía",
  "title13": "Ingreso anualizado de referencia",
  "title14": "Período de inversión",
  "title15": "Cantidad inicial",
  "title16": "Participa ahora",
  "title17": "Registros de la plataforma",
  "title18": "Retiro",
  "title19": "Recarga",
  "title20": "Mi",
  "title21": "Mis activos",
  "title22": "Depósito",
  "title23": "Retiro",
  "title24": "Transferencia",
  "title25": "Vip",
  "title26": "Notificación de mensajes",
  "title27": "Compra rápida",
  "title28": "Centro de ayuda",
  "title29": "Salir",
  "title30": "Configuración",
  "title31": "Centro de seguridad",
  "title32": "Sobre nosotros",
  "title33": "Autenticación por correo electrónico",
  "title34": "Cambiar la contraseña",
  "title35": "Modificar correo electrónico",
  "context1": "Este sitio es una plataforma financiera DeFi descentralizada con alta anonimidad y seguridad. No necesita preocuparse por la filtración de su información personal o la verificación KYC. Los usuarios pueden almacenar y retirar sus activos libremente. Los productos financieros descentralizados que se compran en este sitio ofrecen altos rendimientos y estabilidad, con servicios fáciles y rápidos.",
  "context2": "（Sobre las aplicaciones financieras descentralizadas DeFi）",
  "context3": "La Finanzas Descentralizadas (DeFi), abreviatura de Finanzas Descentralizadas, es una de las aplicaciones de la tecnología blockchain en Fintech. DeFi crea un sistema financiero abierto y transparente a través de plataformas blockchain descentralizadas y de código abierto.",
  "context4": "DeFi se refiere al uso de software de código abierto y redes descentralizadas para transformar productos financieros tradicionales en protocolos transparentes y sin confianza que operan sin intermediarios innecesarios. DeFi es un concepto y una arquitectura centrada en la 'descentralización', lo que significa que no hay administrador, cada usuario es igual y tiene los mismos permisos. Si alguien quiere cambiar el contenido, debe ser aprobado por todos. Por lo tanto, las características de DeFi incluyen la propiedad distribuida, la no pertenencia a nadie y la dificultad de manipulación. DeFi también tiene las ventajas de blockchain, como la transparencia, la resistencia a la censura y la inmutabilidad.",
  "context5": "DeFi tiene muchas ventajas, incluidas una base sin confianza, sin necesidad de permisos institucionales y el control de los activos en manos del individuo. Su principal ventaja es eliminar a los 'terceros de confianza' a través de la descentralización, manteniendo la transparencia e inmutabilidad del libro mayor.",
  "context6": "La principal diferencia entre DeFi y Fintech es su mecanismo de 'confianza'. Fintech aplica la tecnología blockchain dentro del sistema financiero existente, aún dependiendo de instituciones centralizadas, y todas las operaciones están controladas por humanos, obteniendo la confianza del usuario a través de mecanismos de terceros. En contraste, la principal característica de DeFi es la descentralización, utilizando contratos inteligentes. Su código es de código abierto y la lógica central se ejecuta en la blockchain, lo que lo hace inmutable y ejecutable, por lo que los usuarios pueden confiar directamente en su estructura.",
  "context7": "Las aplicaciones visibles de DeFi incluyen protocolos de intercambio descentralizado, stablecoins, préstamos, derivados, fondos, mercados de predicción, protocolos de tokenización, KYC/AML y verificación de identidad.",
  "title36": "Depósito",
  "title37": "Red",
  "title38": "Cantidad",
  "title39": "Depósito",
  "title40": "Utilice la dirección de recarga de su billetera o intercambio. Una vez que se complete la recarga, la cantidad se agregará automáticamente a su cuenta.",
  "title41": "Retiro",
  "title42": "Dirección de billetera",
  "title43": "Red",
  "title44": "Cantidad",
  "title45": "Saldo disponible",
  "title46": "Consejo de seguridad: complete correctamente su cuenta de retiro, los activos no se pueden devolver después de ser transferidos.",
  "title47": "Retirar",
  "title48": "Ingrese la dirección de la billetera",
  "title49": "5-1000000",
  "title50": "Transferencia interna",
  "title51": "Cuenta",
  "title52": "Los miembros VIP pueden usar la transferencia de un solo clic sin clave",
  "title53": "El destinatario debe ser al menos Vip1",
  "title54": "Cargo por servicio",
  "title55": "Transferencia interna",
  "title56": "Compra",
  "title57": "Depósito",
  "title58": "Reglas de nivel",
  "title59": "Depósito",
  "title60": "Límite de colección diaria",
  "title61": "Límite de colección mensual",
  "title62": "Los usuarios deben pagar un depósito para utilizar los servicios VIP. VIP1 es válido durante 1 mes. Se cancelará automáticamente al vencer. Los usuarios de VIP2 o superior, después de usarlo durante 2 meses, pueden contactar al servicio de atención al cliente para devolver el depósito. Para más detalles, consulte el centro de ayuda.",
  "title63": "registro",
  "title64": "Mejorar membresía",
  "title65": "VIP1 debe esperar 30 días para el reembolso automático (no se puede solicitar reembolso).",
  "title66": "VIP2-VIP6 puede contactar al servicio de atención al cliente para solicitar un reembolso. Una vez que se apruebe el reembolso, el depósito será devuelto al saldo inmediatamente y el nivel VIP será cancelado.",
  "title67": "Notificación de mensaje",
  "title68": "¡Hola! ¡Bienvenido al inicio de sesión!",
  "title69": "Cuenta",
  "title70": "Contraseña",
  "title71": "Por favor ingrese su nombre de usuario o dirección de correo electrónico",
  "title72": "Por favor ingrese su contraseña",
  "title73": "Ingrese el captcha",
  "title74": "Iniciar sesión",
  "title75": "¿No tiene cuenta?",
  "title76": "REGISTRARSE",
  "title77": "RECUPERAR CONTRASEÑA",
  "title78": "Contraseña",
  "title79": "Contraseña",
  "title80": "Confirmar contraseña",
  "title81": "CLAVE",
  "title82": "Por favor ingrese la clave",
  "title83": "Por favor confirme la contraseña nuevamente",
  "title84": "Por favor ingrese su contraseña",
  "title85": "Confirmar",
  "title86": "Crear cuenta",
  "title87": "Nombre de usuario",
  "title88": "Por favor ingrese su nombre de usuario",
  "title89": "Correo electrónico",
  "title90": "Por favor ingrese su dirección de correo electrónico",
  "title91": "País",
  "title92": "Móvil",
  "title93": "Por favor ingrese el número de teléfono",
  "title94": "Registrar",
  "title95": "INICIAR SESIÓN",
  "title96": "¿Ya tienes cuenta?",
  "title97": "Compra rápida",
  "title98": "Compra Tether (USDT) con tarjeta de crédito o débito en Paxful",
  "title99": "Compra Tether (USDT) con tarjeta de crédito o débito en ChangeNow",
  "title100": "Compra Tether (USDT) con tarjeta de crédito o débito en Changelly",
  "title101": "Compra Tether (USDT) con tarjeta de crédito o débito en Binance Exchange",
  "title102": "Compra Tether (USDT) con tarjeta de crédito o débito en Bybit Exchange",
  "title103": "Compra Tether (USDT) con tarjeta de crédito o débito en OKX Exchange",
  "title104": "Centro de ayuda",
  "title105": "1. Instrucciones de uso de la plataforma",
  "title106": "2. Cómo activar VIP",
  "title107": "3. Transferencia VIP (sin clave requerida)",
  "title108": "4. ¿Cómo recargar?",
  "title109": "5. ¿Cómo retirar?",
  "title110": "6. ¿Qué hacer si olvido mi clave?",
  "title111": "7. ¿Cómo cambiar la contraseña o el correo electrónico?",
  "title112": "8. ¿Cómo registrar una cuenta?",
  "title113": "9. ¿Cómo invertir?",
  "title114": "10. Servicios de inversión VIP",
  "title115": "Enviar",
  "title116": "Por favor ingrese la clave de la contraseña",
  "title117": "Por favor ingrese su dirección de billetera",
  "title118": "Por favor ingrese la cantidad de retiro",
  "title119": "Utilice la dirección de recarga de su billetera o intercambio. Una vez que se complete la recarga, la cantidad se agregará automáticamente a su cuenta.",
  "title120": "Enviando",
  "title121": "La cantidad mínima de recarga no puede ser inferior a 50",
  "title122": "Solicitar reembolso",
  "title123": "Por favor ingrese la cantidad que desea transferir",
  "title124": "Por favor ingrese el nombre de usuario",
  "title125": "Utilice la dirección de recarga de su billetera o intercambio. Una vez que se complete la recarga, la cantidad se agregará automáticamente a su cuenta.",
  "title126": "Código QR de la billetera",
  "title127": "Esperando",
  "title128": "Por favor ingrese su número de teléfono",
  "title129": "Por favor ingrese su dirección de correo electrónico",
  "title130": "Por favor ingrese una contraseña",
  "title131": "Por favor ingrese la contraseña nuevamente",
  "title132": "Las contraseñas que ingresó no coinciden",
  "title133": "Por favor ingrese la clave de la contraseña",
  "title134": "Por favor ingrese la clave nuevamente",
  "title135": "Las claves que ingresó no coinciden",
  "title136": "Por favor ingrese el código de verificación de imagen",
  "title137": "Registro fallido",
  "title138": "Por favor ingrese la contraseña de inicio de sesión",
  "title139": "Iniciando sesión",
  "title140": "Por favor ingrese su contraseña nuevamente",
  "title141": "Por favor ingrese la clave de la contraseña",
  "title142": "Las contraseñas ingresadas no coinciden",
  "title143": "Enviando",
  'content1' : `El equipo de USDT ha ingresado oficialmente a la plataforma de servicios de intercambio de activos digitales.
    Con un diseño técnico confiable y escalable, servicios de alta calidad y más soporte, la plataforma de intercambio de activos digitales USDT tiene como objetivo proporcionar a sus usuarios especiales servicios de intercambio de USDT más convenientes, seguros y accesibles, así como activos de blockchain de alta calidad. A los clientes VIP globales se les ofrecen servicios de depósito, transferencias, retiros, gestión financiera e inversión. Gestión financiera exclusiva VIP, inversión y servicio al cliente las 24 horas.
    1. La plataforma está abierta para el registro de usuarios. Cualquier persona puede registrar su propia cuenta.
    2. El nivel VIP de la cuenta recién registrada es 0 (almacenaje, transferencia, retiro, etc.). Estos son gratuitos y no necesita actualizar su cuenta para operarlos. Si su cuenta necesita recibir transferencias de otros, necesitará usar los límites de pago (en este momento, las cuentas de nivel 0 no podrán recibir pagos), y necesitará actualizar su cuenta a VIP para usar la función de pago; cuanto mayor sea el nivel, los límites diarios y mensuales serán diferentes, por favor, actualice según sea necesario.
    3. También podemos proporcionar varios servicios como intercambio de monedas y préstamos con garantía en USDT para clientes VIP.`,

'content2' : `     1. Haga clic en el botón VIP
    2. Seleccione el nivel VIP que desea comprar
    3. Pague el depósito VIP y compre con éxito
    4. Depósito requerido para el nivel VIP (puede cancelarlo en cualquier momento y recuperar su depósito)
    Depósito VIP1: 50 USDT (VIP1 es un servicio de experiencia para novatos, válido por 1 mes, el depósito no es reembolsable ni cancelable)
    Depósito VIP2: 1000 USDT
    Depósito VIP3: 3000 USDT
    Depósito VIP4: 30000 USDT
    Depósito VIP5: 50000 USDT
    Depósito VIP6: 100000 USDT
    El nivel VIP de la cuenta recién registrada es 0 (almacenaje, transferencia, retiro, etc.). Estos son gratuitos y no necesita actualizar su cuenta para operarlos. Si su cuenta necesita recibir transferencias de otros, necesitará usar los límites de pago (en este momento, las cuentas de nivel 0 no podrán recibir pagos), y necesitará actualizar su cuenta a VIP para usar la función de pago; cuanto mayor sea el nivel, los límites diarios y mensuales serán diferentes, por favor, actualice según sea necesario.
    VIP1: Límite de pago de 10 USDT por día y 30 USDT por mes
    VIP2: Límite de pago de 100 USDT por día y 3000 USDT por mes
    VIP3: Límite de pago de 30000 USDT por día y 900000 USDT por mes
    VIP4: Límite de pago de 100000 USDT por día y 3000000 USDT por mes
    VIP5: Límite de pago de 200000 USDT por día y 6000000 USDT por mes
    VIP6: Límite de pago de 2000000 USDT por día y 60000000 USDT por mes`,

'content3' : `Nota: Los VIPs pueden usar la función de transferencia de la plataforma. Las transferencias solo pueden realizarse entre VIPs de la plataforma (las transferencias exitosas no se pueden devolver)
    1. Haga clic en el botón de transferencia
    2. Complete correctamente el nombre de usuario VIP, la dirección de correo electrónico y el número de teléfono móvil
    3. Haga clic en Confirmar para completar la transferencia (no es necesario ingresar la KEY)
    4. Si su cuenta necesita recibir transferencias de otros, necesitará usar los límites de pago
    VIP1: Límite de pago de 10 USDT por día y 30 USDT por mes
    VIP2: Límite de pago de 100 USDT por día y 3000 USDT por mes
    VIP3: Límite de pago de 30000 USDT por día y 900000 USDT por mes
    VIP4: Límite de pago de 100000 USDT por día y 3000000 USDT por mes
    VIP5: Límite de pago de 200000 USDT por día y 6000000 USDT por mes
    VIP6: Límite de pago de 2000000 USDT por día y 60000000 USDT por mes
    Ejemplo de límite de transferencia: Si su cuenta es VIP5
    VIP5 transfiere a VIP1 (10USDT)
    VIP5 transfiere a VIP2 (100USDT)
    VIP5 transfiere a VIP3 (30000USDT)
    VIP5 transfiere a VIP4 (100000USDT)
    VIP5 transfiere a VIP5 (200000USDT)
    VIP6 transfiere a VIP6 (2000000USDT)`,

'content4' : `      1. Haga clic en el botón de recarga
    2. Seleccione la cantidad a almacenar
    3. Haga clic en Confirmar, ingrese (KEY)
    4. Obtendrá una dirección de recarga exclusiva para su cuenta, que puede transferir desde cualquier intercambio o billetera.
    5. La cantidad de su recarga se añadirá automáticamente a su saldo de cuenta`,

'content5' : `      1. Haga clic en el botón de retiro
    2. Ingrese la dirección de retiro USDT (TRC20)
    3. Ingrese la cantidad a retirar
    4. Haga clic en el botón Confirmar e ingrese la KEY
    5. El retiro es exitoso y su retiro llegará dentro de 2 horas.`,

'content6' : `      1. La KEY la establece usted mismo y no puede ser obtenida por otros. La KEY es única y no se puede restablecer.
    2. La KEY es la única prueba de su cuenta. No se puede modificar ni recuperar.
    3. Si olvida la KEY, su cuenta aún tiene fondos para retirar, puede volver a registrarse con una nueva cuenta y actualizar el VIP, luego transferir los fondos de su cuenta antigua a su nueva cuenta para retirarlos. No se necesita KEY para transferencias dentro de la plataforma VIP.
    La KEY es la única credencial para su cuenta y no puede modificarse ni recuperarse. Para proteger la privacidad del cliente, la plataforma no registrará el número de cuenta, la contraseña, el número de teléfono móvil, el correo electrónico ni la KEY del usuario. Por favor, mantenga su información de inicio de sesión segura.`,

'content7' : `      1. Haga clic en mi página de inicio
    2. Haga clic en el botón del Centro de Seguridad
    3. Seleccione la contraseña o correo electrónico que necesita cambiar
    4. Ingrese la KEY para completar`,

'content8' : `      (Nota: al registrarse, por favor no abra el software de traducción. Si abre el software de traducción, el botón no se confirmará. Si no puede registrarse, cambie de navegador)
    1. Haga clic en el botón de registro en la página de inicio
    2. Haga clic en registrar
    3. Complete su información de registro
    4. Por favor, guarde bien la KEY cuando la establezca. No se puede modificar ni recuperar (la pérdida puede causar daños a los fondos)`,

'content9' : `      Almacene de forma segura y gane fácilmente
    1. Monto mínimo de almacenamiento: 5,000 USDT (las cuentas con fondos por debajo de 5,000 no pueden acceder a servicios financieros)
    2: Sus activos recibirán ingresos según diferentes niveles, y el ingreso total en cada nivel se acumulará.
    3: El rendimiento anual esperado se ajustará en cualquier momento según el rendimiento real de la piscina de minería.
    4: Los ingresos se calcularán un día después de que se abra el PoS.
    5: No es necesario congelar los activos de la hipoteca, puede intercambiar o retirar monedas en cualquier momento.
    Esto representa la fecha de la garantía. Los ingresos comenzarán el T+1 (al día siguiente) y se distribuirán a su cuenta en T+2.
    No se generarán ingresos el día que libere sus activos hipotecados. Los ingresos se distribuirán a su cuenta spot diariamente. Los productos financieros actuales pueden cancelarse en cualquier momento, y los ingresos se liquidarán hasta el día antes de salir del PoS.
    Para otros productos, cuando se alcance el período mínimo de almacenamiento, puede elegir canjear o continuar almacenando. Los ingresos finales se calcularán según los días reales de almacenamiento, y el capital se desbloqueará el día de canje.`,

'content10' : `      ¡Gracias nuevamente por ser miembro nuestro, le proporcionaremos un mejor servicio y soporte!
    2. Obtendrá servicios de transferencia y cobro
    3. Disfrutará de tarifas VIP exclusivas y costos de transacción más favorables.
    4. Recibirá un distintivo VIP exclusivo que mostrará su identidad y estatus.
    5. Se unirá a nuestro grupo exclusivo de servicio VIP y recibirá un soporte al cliente más especial.
    6. Recibirá informes regulares de la industria para comprender las dinámicas del mercado y las oportunidades de inversión.
    7. Disfrutará de beneficios exclusivos de festivales y privilegios personalizados de recuerdos, para que sienta nuestro cuidado.
    8. Tendrá la oportunidad de participar en actividades VIP exclusivas y conocer a más inversionistas exitosos.
    9. Tendrá un canal exclusivo de servicio al cliente VIP 24/7 y recibirá un soporte más rápido y prioritario.
    10. Obtendrá un canal independiente y verde, disfrutando de servicios de depósito y retiro más convenientes.`,


},
"ar":{
  "menu_1": "الصفحة الرئيسية",
  "menu_2": "التجارة",
  "menu_3": "حسابي",
  "title1": "العملة",
  "title2": "أحدث الأسعار",
  "title3": "الشركاء",
  "title4": "اللغة",
  "title5": "الصفحة الرئيسية",
  "welcome": "أهلاً وسهلاً",
  "hello": "مرحباً",
  "title6": "الرصيد المتاح",
  "title7": "حد الاستلام اليومي",
  "title8": "حد الاستلام الشهري",
  "title9": "إيداع",
  "title10": "سحب",
  "title11": "التحويل الداخلي",
  "title12": "ترقية العضوية",
  "title13": "الدخل السنوي المرجعي",
  "title14": "فترة الاستثمار",
  "title15": "الكمية المبدئية",
  "title16": "شارك الآن",
  "title17": "سجلات المنصة",
  "title18": "السحب",
  "title19": "إعادة الشحن",
  "title20": "حسابي",
  "title21": "أصولي",
  "title22": "إيداع",
  "title23": "سحب",
  "title24": "التحويل",
  "title25": "في آي بي",
  "title26": "إشعارات الرسائل",
  "title27": "شراء سريع",
  "title28": "مركز المساعدة",
  "title29": "خروج",
  "title30": "الإعدادات",
  "title31": "مركز الأمان",
  "title32": "عنّا",
  "title33": "التحقق من البريد الإلكتروني",
  "title34": "تغيير كلمة المرور",
  "title35": "تعديل البريد الإلكتروني",
  "context1": "هذا الموقع هو منصة مالية DeFi لامركزية تتمتع بخصوصية وأمان عاليين. لا داعي للقلق بشأن تسريب معلوماتك الشخصية أو التحقق من KYC. يمكن للمستخدمين تخزين وسحب أصولهم بحرية. المنتجات المالية اللامركزية المشتراة على هذا الموقع تقدم عوائد مرتفعة واستقراراً مع خدمات سريعة وسهلة.",
  "context2": "（حول تطبيقات التمويل اللامركزي DeFi）",
  "context3": "التمويل اللامركزي (DeFi)، اختصاراً للتمويل اللامركزي، هو أحد تطبيقات تقنية blockchain في التكنولوجيا المالية. يخلق DeFi نظاماً مالياً مفتوحاً وشفافاً من خلال منصات blockchain لامركزية ومفتوحة المصدر.",
  "context4": "يشير DeFi إلى استخدام البرمجيات مفتوحة المصدر والشبكات اللامركزية لتحويل المنتجات المالية التقليدية إلى بروتوكولات شفافة وموثوقة تعمل دون وسطاء غير ضروريين. DeFi هو مفهوم وهندسة تركز على 'اللامركزية'، مما يعني أنه لا يوجد مسؤول إداري، كل مستخدم متساوٍ وله نفس الصلاحيات. إذا أراد شخص ما تغيير المحتوى، يجب أن يتم الموافقة عليه من قبل الجميع. لذلك، تشمل خصائص DeFi الملكية الموزعة، التي لا تنتمي إلى أحد، ويصعب التلاعب بها. كما أن لـ DeFi مزايا blockchain مثل الشفافية، مقاومة الرقابة، وعدم القابلية للتغيير.",
  "context5": "يتمتع DeFi بالعديد من المزايا، بما في ذلك الأساس الذي لا يعتمد على الثقة، وعدم الحاجة إلى إذن من المؤسسات، والسيطرة على الأصول في يد الفرد. ميزة DeFi الرئيسية هي القضاء على 'الأطراف الثالثة الموثوقة' من خلال اللامركزية، مما يحافظ على الشفافية وعدم قابلية التغيير في السجلات.",
  "context6": "أكبر فرق بين DeFi والتكنولوجيا المالية (Fintech) هو آلية 'الثقة'. تطبق التكنولوجيا المالية blockchain ضمن النظام المالي الحالي، ولا تزال تعتمد على المؤسسات المركزية، وتُدار جميع العمليات من قبل البشر، مع الحصول على ثقة المستخدم من خلال آليات الطرف الثالث. في المقابل، تتمثل السمة الرئيسية لـ DeFi في اللامركزية، حيث يستخدم العقود الذكية. الكود مفتوح المصدر ويتم تنفيذ المنطق الأساسي على الـ blockchain، مما يجعله غير قابل للتغيير وقابلاً للتنفيذ، لذا يمكن للمستخدمين الثقة في هيكلها بشكل مباشر.",
  "context7": "تتضمن التطبيقات المرئية لـ DeFi بروتوكولات التداول اللامركزية، العملات المستقرة، القروض، المشتقات، الصناديق، أسواق التنبؤ، بروتوكولات الترميز، KYC/AML، والتحقق من الهوية.",
  "title36": "إيداع",
  "title37": "الشبكة",
  "title38": "المبلغ",
  "title39": "إيداع",
  "title40": "استخدم عنوان إعادة الشحن من محفظتك أو من البورصة. بعد إتمام الشحن، سيتم إضافة المبلغ تلقائيًا إلى حسابك.",
  "title41": "سحب",
  "title42": "عنوان المحفظة",
  "title43": "الشبكة",
  "title44": "المبلغ",
  "title45": "الرصيد المتاح",
  "title46": "نصيحة أمان: يرجى ملء حساب السحب بدقة، فلا يمكن استرداد الأصول بعد التحويل.",
  "title47": "سحب",
  "title48": "أدخل عنوان المحفظة",
  "title49": "5-1000000",
  "title50": "التحويل الداخلي",
  "title51": "الحساب",
  "title52": "يمكن للأعضاء VIP استخدام التحويل بنقرة واحدة بدون مفتاح",
  "title53": "يجب أن يكون المستقبل على الأقل VIP1",
  "title54": "رسوم الخدمة",
  "title55": "التحويل الداخلي",
  "title56": "شراء",
  "title57": "إيداع",
  "title58": "قواعد المستوى",
  "title59": "إيداع",
  "title60": "حد جمع يومي",
  "title61": "حد جمع شهري",
  "title62": "يجب على المستخدمين دفع وديعة لاستخدام خدمات VIP. VIP1 صالح لمدة شهر واحد، وسينتهي تلقائيًا بعد انتهاء المدة. يمكن للمستخدمين من VIP2 وما فوق، بعد استخدام الخدمة لمدة شهرين، الاتصال بخدمة العملاء لاسترداد الوديعة. لمزيد من التفاصيل، يرجى الرجوع إلى مركز المساعدة.",
  "title63": "سجل",
  "title64": "ترقية العضوية",
  "title65": "يجب على VIP1 الانتظار لمدة 30 يومًا لاسترداد الوديعة تلقائيًا (لا يمكن التقديم للاسترداد).",
  "title66": "يمكن لـ VIP2-VIP6 الاتصال بخدمة العملاء لتقديم طلب استرداد. بعد الموافقة على الاسترداد، سيتم إعادة الوديعة إلى الرصيد على الفور ويتم إلغاء المستوى VIP.",
  "title67": "إشعار الرسائل",
  "title68": "مرحباً! مرحباً بك في تسجيل الدخول!",
  "title69": "الحساب",
  "title70": "كلمة المرور",
  "title71": "يرجى إدخال اسم المستخدم أو عنوان البريد الإلكتروني",
  "title72": "يرجى إدخال كلمة المرور",
  "title73": "أدخل الرمز المرئي",
  "title74": "تسجيل الدخول",
  "title75": "لا حساب لديك؟",
  "title76": "سجل الآن",
  "title77": "استرجاع كلمة المرور",
  "title78": "كلمة المرور",
  "title79": "كلمة المرور",
  "title80": "تأكيد كلمة المرور",
  "title81": "المفتاح",
  "title82": "يرجى إدخال المفتاح",
  "title83": "يرجى تأكيد كلمة المرور مرة أخرى",
  "title84": "يرجى إدخال كلمة المرور الخاصة بك",
  "title85": "تأكيد",
  "title86": "إنشاء حساب",
  "title87": "اسم المستخدم",
  "title88": "يرجى إدخال اسم المستخدم",
  "title89": "البريد الإلكتروني",
  "title90": "يرجى إدخال عنوان بريدك الإلكتروني",
  "title91": "الدولة",
  "title92": "الهاتف المحمول",
  "title93": "يرجى إدخال رقم الهاتف",
  "title94": "سجل",
  "title95": "تسجيل الدخول",
  "title96": "هل لديك حساب؟",
  "title97": "شراء سريع",
  "title98": "اشترِ تيثر (USDT) باستخدام بطاقة الائتمان أو الخصم في باكسفول",
  "title99": "اشترِ تيثر (USDT) باستخدام بطاقة الائتمان أو الخصم في ChangeNow",
  "title100": "اشترِ تيثر (USDT) باستخدام بطاقة الائتمان أو الخصم في Changelly",
  "title101": "اشترِ تيثر (USDT) باستخدام بطاقة الائتمان أو الخصم في Binance Exchange",
  "title102": "اشترِ تيثر (USDT) باستخدام بطاقة الائتمان أو الخصم في Bybit Exchange",
  "title103": "اشترِ تيثر (USDT) باستخدام بطاقة الائتمان أو الخصم في OKX Exchange",
  "title104": "مركز المساعدة",
  "title105": "1. تعليمات استخدام المنصة",
  "title106": "2. كيفية تفعيل VIP",
  "title107": "3. تحويل VIP (دون الحاجة للمفتاح)",
  "title108": "4. كيف يمكنني إعادة الشحن؟",
  "title109": "5. كيف يمكنني سحب الأموال؟",
  "title110": "6. ماذا أفعل إذا نسيت مفتاحي؟",
  "title111": "7. كيفية تغيير كلمة المرور أو البريد الإلكتروني؟",
  "title112": "8. كيفية تسجيل حساب؟",
  "title113": "9. كيفية الاستثمار؟",
  "title114": "10. خدمات الاستثمار VIP",
  "title115": "إرسال",
  "title116": "يرجى إدخال مفتاح كلمة المرور",
  "title117": "يرجى إدخال عنوان محفظتك",
  "title118": "يرجى إدخال كمية السحب",
  "title119": "استخدم عنوان إعادة الشحن من محفظتك أو من البورصة. بعد إتمام الشحن، سيتم إضافة المبلغ تلقائيًا إلى حسابك.",
  "title120": "جارٍ الإرسال",
  "title121": "الحد الأدنى للمبلغ المطلوب لإعادة الشحن هو 50",
  "title122": "طلب استرداد",
  "title123": "يرجى إدخال المبلغ الذي تريد تحويله",
  "title124": "يرجى إدخال اسم المستخدم",
  "title125": "استخدم عنوان إعادة الشحن من محفظتك أو من البورصة. بعد إتمام الشحن، سيتم إضافة المبلغ تلقائيًا إلى حسابك.",
  "title126": "رمز الاستجابة السريعة للمحفظة",
  "title127": "انتظار",
  "title128": "يرجى إدخال رقم الهاتف المحمول",
  "title129": "يرجى إدخال عنوان البريد الإلكتروني",
  "title130": "يرجى إدخال كلمة المرور",
  "title131": "يرجى إدخال كلمة المرور مرة أخرى",
  "title132": "كلمات المرور التي أدخلتها لا تتطابق",
  "title133": "يرجى إدخال مفتاح كلمة المرور",
  "title134": "يرجى إدخال المفتاح مرة أخرى",
  "title135": "المفاتيح التي أدخلتها لا تتطابق",
  "title136": "يرجى إدخال رمز التحقق من الصورة",
  "title137": "فشل التسجيل",
  "title138": "يرجى إدخال كلمة مرور تسجيل الدخول",
  "title139": "جاري تسجيل الدخول",
  "title140": "يرجى إدخال كلمة المرور مرة أخرى",
  "title141": "يرجى إدخال مفتاح كلمة المرور",
  "title142": "كلمات المرور المدخلة لا تتطابق",
  "title143": "جارٍ الإرسال",
  'content1' : `فريق USDT دخل رسميًا إلى منصة خدمات تداول الأصول الرقمية.
    مع تصميم تقني موثوق وقابل للتطوير، وخدمات عالية الجودة ودعم أكبر، تهدف منصة تداول الأصول الرقمية USDT إلى تقديم خدمات تبادل USDT أكثر راحة وأمانًا لعملائها المميزين، بالإضافة إلى الأصول الرقمية عالية الجودة. عملاء VIP العالميين يقدمون خدمات الإيداع والتحويل والسحب والإدارة المالية والاستثمار. إدارة مالية حصرية VIP، استثمار وخدمة عملاء على مدار الساعة.
    1. المنصة مفتوحة للتسجيل من قبل الجمهور. يمكن لأي شخص تسجيل حسابه الخاص.
    2. مستوى VIP للحساب المسجل حديثًا هو 0 (التخزين، التحويل، السحب، إلخ). هذه الخدمات مجانية ولا تحتاج إلى ترقية حسابك لاستخدامها. إذا كان حسابك يحتاج إلى تلقي تحويلات من الآخرين، يجب عليك استخدام حدود الدفع (في هذه الحالة، لن تتمكن الحسابات من المستوى 0 من تلقي المدفوعات)، ويجب عليك ترقية مستوى حسابك إلى VIP لاستخدام وظيفة الدفع؛ كلما زاد المستوى، ستكون الحصص اليومية والشهرية مختلفة، يرجى الترقية حسب الحاجة.
    3. يمكننا أيضًا تقديم خدمات متنوعة مثل تبادل العملات والقروض المرهونة باستخدام USDT للعملاء VIP.`,

'content2' : `     1. انقر على زر VIP
    2. اختر مستوى VIP الذي تريد شراؤه
    3. ادفع وديعة VIP وشراء بنجاح
    4. الوديعة المطلوبة لمستوى VIP (يمكنك إلغاءها في أي وقت واسترداد وديعتك)
    وديعة VIP1: 50 USDT (VIP1 هو خدمة تجربة للمبتدئين، صالحة لمدة شهر واحد، الوديعة غير قابلة للاسترداد وغير قابلة للإلغاء)
    وديعة VIP2: 1000 USDT
    وديعة VIP3: 3000 USDT
    وديعة VIP4: 30000 USDT
    وديعة VIP5: 50000 USDT
    وديعة VIP6: 100000 USDT
    مستوى VIP للحساب المسجل حديثًا هو 0 (التخزين، التحويل، السحب، إلخ). هذه الخدمات مجانية ولا تحتاج إلى ترقية حسابك لاستخدامها. إذا كان حسابك يحتاج إلى تلقي تحويلات من الآخرين، يجب عليك استخدام حدود الدفع (في هذه الحالة، لن تتمكن الحسابات من المستوى 0 من تلقي المدفوعات)، ويجب عليك ترقية مستوى حسابك إلى VIP لاستخدام وظيفة الدفع؛ كلما زاد المستوى، ستكون الحصص اليومية والشهرية مختلفة، يرجى الترقية حسب الحاجة.
    VIP1: حد الدفع 10 USDT يوميًا و30 USDT شهريًا
    VIP2: حد الدفع 100 USDT يوميًا و3000 USDT شهريًا
    VIP3: حد الدفع 30000 USDT يوميًا و900000 USDT شهريًا
    VIP4: حد الدفع 100000 USDT يوميًا و3000000 USDT شهريًا
    VIP5: حد الدفع 200000 USDT يوميًا و6000000 USDT شهريًا
    VIP6: حد الدفع 2000000 USDT يوميًا و60000000 USDT شهريًا`,

'content3' : `ملاحظة: يمكن لـ VIPs استخدام وظيفة التحويل على المنصة. يمكن إجراء التحويلات فقط بين VIPs على المنصة (التحويلات الناجحة لا يمكن إرجاعها)
    1. انقر على زر التحويل
    2. املأ بشكل صحيح اسم المستخدم VIP، عنوان البريد الإلكتروني ورقم الهاتف المحمول
    3. انقر على تأكيد لإتمام التحويل (لا حاجة لإدخال KEY)
    4. إذا كان حسابك يحتاج إلى تلقي تحويلات من الآخرين، يجب عليك استخدام حدود الدفع
    VIP1: حد الدفع 10 USDT يوميًا و30 USDT شهريًا
    VIP2: حد الدفع 100 USDT يوميًا و3000 USDT شهريًا
    VIP3: حد الدفع 30000 USDT يوميًا و900000 USDT شهريًا
    VIP4: حد الدفع 100000 USDT يوميًا و3000000 USDT شهريًا
    VIP5: حد الدفع 200000 USDT يوميًا و6000000 USDT شهريًا
    VIP6: حد الدفع 2000000 USDT يوميًا و60000000 USDT شهريًا
    مثال على حد التحويل: إذا كان حسابك VIP5
    VIP5 يحول إلى VIP1 (10USDT)
    VIP5 يحول إلى VIP2 (100USDT)
    VIP5 يحول إلى VIP3 (30000USDT)
    VIP5 يحول إلى VIP4 (100000USDT)
    VIP5 يحول إلى VIP5 (200000USDT)
    VIP6 يحول إلى VIP6 (2000000USDT)`,

'content4' : `      1. انقر على زر الشحن
    2. اختر المبلغ الذي ترغب في تخزينه
    3. انقر على تأكيد، أدخل (KEY)
    4. ستحصل على عنوان تخزين حصري لحسابك يمكنك من إجراء التحويل من أي بورصة أو محفظة.
    5. سيتم إضافة مبلغ الشحن إلى رصيد حسابك تلقائيًا`,

'content5' : `      1. انقر على زر السحب
    2. أدخل عنوان سحب USDT (TRC20)
    3. أدخل المبلغ المراد سحبه
    4. انقر على زر التأكيد وأدخل KEY
    5. تم السحب بنجاح وسوف تصل إلى حسابك خلال ساعتين.`,

'content6' : `      1. يتم تعيين KEY بواسطة نفسك ولا يمكن أن يتم الحصول عليها من قبل الآخرين. KEY هي فريدة ولا يمكن إعادة تعيينها.
    2. KEY هي الإثبات الوحيد لحسابك. لا يمكن تعديلها أو استعادتها.
    3. إذا نسيت KEY، لا يزال بإمكانك سحب الأموال من حسابك، يمكنك إعادة التسجيل باستخدام حساب جديد وتحديث VIP، ثم تحويل الأموال من حسابك القديم إلى حسابك الجديد للسحب. لا حاجة لـ KEY للتحويلات داخل منصة VIP.
    KEY هي الوثيقة الوحيدة لحسابك ولا يمكن تعديلها أو استعادتها. لحماية خصوصية العميل، لن تقوم المنصة بتسجيل رقم الحساب أو كلمة المرور أو رقم الهاتف المحمول أو البريد الإلكتروني أو KEY للمستخدم. يرجى الحفاظ على معلومات تسجيل الدخول الخاصة بك آمنة.`,

'content7' : `      1. انقر على صفحتي الرئيسية
    2. انقر على زر مركز الأمان
    3. اختر كلمة المرور أو البريد الإلكتروني الذي تحتاج إلى تغييره
    4. أدخل KEY لإتمام العملية`,

'content8' : `      (ملاحظة: عند التسجيل، يرجى عدم فتح برنامج الترجمة. إذا قمت بفتح برنامج الترجمة، لن يتم تأكيد الزر. إذا لم تتمكن من التسجيل، يرجى تغيير المتصفح)
    1. انقر على زر التسجيل في الصفحة الرئيسية
    2. انقر على التسجيل
    3. أكمل معلومات التسجيل الخاصة بك
    4. يرجى حفظ KEY عند إعداده. لا يمكن تعديله أو استعادته (الفقدان قد يسبب ضررًا للأموال)`,

'content9' : `      خزّن بأمان واربح بسهولة
    1. الحد الأدنى للمبلغ المخزن: 5000 USDT (الحسابات التي تحتوي على أموال أقل من 5000 لا يمكنها الوصول إلى الخدمات المالية)
    2: ستتلقى أصولك إيرادات وفقًا لمستويات مختلفة، وسيتم إضافة إجمالي الدخل في كل مستوى.
    3: سيتم تعديل العائد السنوي المتوقع في أي وقت استنادًا إلى الدخل الفعلي من تجمع التعدين.
    4: سيتم حساب الإيرادات بعد يوم من فتح PoS.
    5: لا حاجة لتجميد الأصول المرهونة، يمكنك تداول أو سحب العملات في أي وقت.
    تمثل هذه البيانات تاريخ الرهن. ستبدأ الإيرادات في T+1 (اليوم التالي) وسيتم توزيعها إلى حسابك في T+2.
    لن يتم توليد إيرادات في اليوم الذي ستقوم فيه بإطلاق أصولك المرهونة. سيتم توزيع الإيرادات على حسابك الفوري يوميًا. يمكن إلغاء المنتجات المالية الحالية في أي وقت، وسيتم تسوية الدخل حتى اليوم السابق لمغادرة PoS.
    بالنسبة للمنتجات الأخرى، عندما يتم الوصول إلى الحد الأدنى لفترة التخزين، يمكنك اختيار استرداد أو الاستمرار في التخزين. سيتم حساب الدخل النهائي بناءً على عدد الأيام الفعلية للتخزين، وسيتم فتح رأس المال في يوم استرداده.`,

'content10' : `      شكرًا لك مرة أخرى على أن تصبح عضوًا لدينا، سنوفر لك خدمة ودعمًا أفضل!
    2. ستحصل على خدمات التحويل والتحصيل
    3. ستستمتع بأسعار VIP الحصرية وتكاليف المعاملات الأكثر تفضيلًا.
    4. ستحصل على شارة VIP حصرية تظهر هويتك ووضعك.
    5. ستنضم إلى مجموعة خدمة VIP الحصرية لدينا وستتلقى دعمًا خاصًا للعملاء.
    6. ستتلقى تقارير منتظمة عن الصناعة لفهم ديناميكيات السوق وفرص الاستثمار.
    7. ستستمتع بمزايا حصرية للمهرجانات وامتيازات شخصية للذكريات، لتشعر برعايتنا.
    8. ستتاح لك الفرصة للمشاركة في أنشطة VIP الحصرية والتعرف على المزيد من المستثمرين الناجحين.
    9. سيكون لديك قناة خدمة العملاء VIP الحصرية 24/7 وستحصل على دعم أسرع وأولوية.
    10. ستحصل على قناة مستقلة وخضراء، مع الاستمتاع بخدمات إيداع وسحب أكثر سهولة.`,


},
"cs":{
  "menu_1": "Domov",
  "menu_2": "Obchod",
  "menu_3": "Můj účet",
  "title1": "Měna",
  "title2": "Nejnovější cena",
  "title3": "Partneři",
  "title4": "Jazyk",
  "title5": "Domov",
  "welcome": "Vítejte",
  "hello": "Ahoj",
  "title6": "Dostupný zůstatek",
  "title7": "Denní limit příjmu",
  "title8": "Měsíční limit příjmu",
  "title9": "Vklad",
  "title10": "Výběr",
  "title11": "Interní převod",
  "title12": "Upgrade členství",
  "title13": "Roční výnos z referencí",
  "title14": "Investiční období",
  "title15": "Počáteční množství",
  "title16": "Zúčastněte se nyní",
  "title17": "Platformové záznamy",
  "title18": "Výběr",
  "title19": "Dobití",
  "title20": "Můj účet",
  "title21": "Můj majetek",
  "title22": "Vklad",
  "title23": "Výběr",
  "title24": "Převod",
  "title25": "VIP",
  "title26": "Oznámení zpráv",
  "title27": "Rychlý nákup",
  "title28": "Centrum nápovědy",
  "title29": "Odhlásit se",
  "title30": "Nastavení",
  "title31": "Bezpečnostní centrum",
  "title32": "O nás",
  "title33": "Ověření e-mailu",
  "title34": "Změnit heslo",
  "title35": "Upravit e-mail",
  "context1": "Tento web je decentralizovaná DeFi finanční platforma s vysokou úrovní anonymity a bezpečnosti. Nemusíte se obávat úniku vašich osobních údajů nebo KYC ověření. Uživatelé mohou svobodně ukládat a vybírat své prostředky. Decentralizované finanční produkty zakoupené na tomto webu nabízejí vysoké výnosy a stabilitu s jednoduchými a rychlými službami.",
  "context2": "（O aplikacích decentralizovaného financování DeFi）",
  "context3": "Decentralizované financování (DeFi), zkráceně DeFi, je jednou z aplikací technologie blockchain ve finanční technologii (Fintech). DeFi vytváří otevřený a transparentní finanční systém pomocí decentralizovaných open-source blockchain platforem.",
  "context4": "DeFi označuje použití open-source softwaru a decentralizovaných sítí k transformaci tradičních finančních produktů do důvěryhodných a transparentních protokolů, které fungují bez zbytečných zprostředkovatelů. DeFi je samotný koncept a architektura soustředěná na 'decentralizaci', což znamená, že neexistuje žádný správce, každý uživatel je rovnocenný a má stejná práva. Pokud někdo chce změnit obsah, musí být na to dosaženo souhlasu všech. Proto vlastnosti DeFi zahrnují distribuované vlastnictví, které nepatří nikomu, a je těžké je manipulovat. DeFi má také výhody blockchainu, jako je transparentnost, odolnost vůči cenzuře a neměnnost.",
  "context5": "DeFi má mnoho výhod, včetně důvěryhodného základu, žádosti o povolení od institucí a kontroly aktiv v rukou jednotlivce. Hlavní výhodou DeFi je odstranění 'důvěryhodných třetích stran' prostřednictvím decentralizace, přičemž udržuje transparentnost a neměnnost účetních knih.",
  "context6": "Největší rozdíl mezi DeFi a Fintech je v jejich mechanismu 'důvěry'. Fintech aplikuje technologii blockchain v rámci stávajícího finančního systému, stále spoléhá na centralizované instituce, a všechny operace jsou řízeny lidmi, přičemž získávají důvěru uživatelů prostřednictvím mechanismů třetí strany. Naopak hlavní charakteristikou DeFi je decentralizace pomocí smart kontraktů. Jeho kód je open-source a hlavní logika je prováděna na blockchainu, což je nezměnitelné a vykonatelné, takže uživatelé mohou přímo důvěřovat jeho struktuře.",
  "context7": "Viditelné aplikace DeFi zahrnují decentralizované obchodní protokoly, stablecoiny, půjčky, deriváty, fondy, trhy s predikcemi, protokoly pro tokenizaci, KYC/AML a ověřování identity.",
  "title36": "Vklad",
  "title37": "Síť",
  "title38": "Množství",
  "title39": "Vklad",
  "title40": "Použijte adresu pro nabití z vaší peněženky nebo burzy. Po dokončení dobití bude částka automaticky přidána na váš účet.",
  "title41": "Výběr",
  "title42": "Adresa peněženky",
  "title43": "Síť",
  "title44": "Množství",
  "title45": "Dostupný zůstatek",
  "title46": "Bezpečnostní tip: Vyplňte správně adresu výběru, po převodu nebudou prostředky vráceny.",
  "title47": "Výběr",
  "title48": "Zadejte adresu peněženky",
  "title49": "5-1000000",
  "title50": "Interní převod",
  "title51": "Účet",
  "title52": "VIP členové mohou používat převod jedním kliknutím bez klíče",
  "title53": "Příjemce musí mít alespoň VIP1",
  "title54": "Poplatek za službu",
  "title55": "Interní převod",
  "title56": "Nákup",
  "title57": "Vklad",
  "title58": "Pravidla úrovní",
  "title59": "Vklad",
  "title60": "Denní limit sběru",
  "title61": "Měsíční limit sběru",
  "title62": "Uživatelé musí zaplatit vklad pro využívání VIP služeb. VIP1 je platný na 1 měsíc. Po uplynutí doby platnosti bude automaticky zrušen. Uživatelé VIP2 a vyšší, po používání služby po dobu 2 měsíců, mohou kontaktovat zákaznický servis pro vrácení vkladu. Pro více informací se podívejte do Centra nápovědy.",
  "title63": "Záznam",
  "title64": "Upgrade členství",
  "title65": "VIP1 musí počkat 30 dní na automatické vrácení (není možné požádat o vrácení).",
  "title66": "VIP2-VIP6 mohou kontaktovat zákaznický servis pro žádost o vrácení. Po úspěšném vrácení bude vklad okamžitě vrácen na zůstatek a úroveň VIP bude zrušena.",
  "title67": "Oznámení zpráv",
  "title68": "Ahoj! Vítejte při přihlášení!",
  "title69": "Účet",
  "title70": "Heslo",
  "title71": "Zadejte své uživatelské jméno nebo e-mailovou adresu",
  "title72": "Zadejte své heslo",
  "title73": "Zadejte ověřovací kód",
  "title74": "Přihlásit se",
  "title75": "Nemáte účet?",
  "title76": "Zaregistrujte se",
  "title77": "Obnovit heslo",
  "title78": "Heslo",
  "title79": "Heslo",
  "title80": "Potvrďte heslo",
  "title81": "Klíč",
  "title82": "Zadejte klíč",
  "title83": "Prosím, potvrďte heslo znovu",
  "title84": "Zadejte své heslo",
  "title85": "Potvrdit",
  "title86": "Vytvořit účet",
  "title87": "Uživatelské jméno",
  "title88": "Zadejte své uživatelské jméno",
  "title89": "E-mail",
  "title90": "Zadejte svou e-mailovou adresu",
  "title91": "Země",
  "title92": "Mobilní telefon",
  "title93": "Zadejte telefonní číslo",
  "title94": "Registrovat",
  "title95": "PŘIHLÁSIT SE",
  "title96": "Máte účet?",
  "title97": "Rychlý nákup",
  "title98": "Kupte Tether (USDT) pomocí kreditní nebo debetní karty na Paxful",
  "title99": "Kupte Tether (USDT) pomocí kreditní nebo debetní karty na ChangeNow",
  "title100": "Kupte Tether (USDT) pomocí kreditní nebo debetní karty na Changelly",
  "title101": "Kupte Tether (USDT) pomocí kreditní nebo debetní karty na Binance Exchange",
  "title102": "Kupte Tether (USDT) pomocí kreditní nebo debetní karty na Bybit Exchange",
  "title103": "Kupte Tether (USDT) pomocí kreditní nebo debetní karty na OKX Exchange",
  "title104": "Centrum nápovědy",
  "title105": "1. Pokyny pro používání platformy",
  "title106": "2. Jak aktivovat VIP",
  "title107": "3. VIP převod (bez potřeby klíče)",
  "title108": "4. Jak dobijete?",
  "title109": "5. Jak provést výběr?",
  "title110": "6. Co mám dělat, když zapomenu svůj klíč?",
  "title111": "7. Jak změnit heslo nebo e-mail?",
  "title112": "8. Jak registrovat účet?",
  "title113": "9. Jak investovat?",
  "title114": "10. VIP investiční služby",
  "title115": "Odeslat",
  "title116": "Prosím, zadejte klíč pro heslo",
  "title117": "Prosím, zadejte adresu peněženky",
  "title118": "Prosím, zadejte částku pro výběr",
  "title119": "Použijte adresu pro nabití z vaší peněženky nebo burzy. Po dokončení dobití bude částka automaticky přidána na váš účet.",
  "title120": "Odesílání",
  "title121": "Minimální částka pro nabití je 50",
  "title122": "Žádost o vrácení",
  "title123": "Zadejte částku, kterou chcete převést",
  "title124": "Zadejte uživatelské jméno",
  "title125": "Použijte adresu pro nabití z vaší peněženky nebo burzy. Po dokončení dobití bude částka automaticky přidána na váš účet.",
  "title126": "QR kód peněženky",
  "title127": "Čekání",
  "title128": "Zadejte mobilní číslo",
  "title129": "Zadejte e-mailovou adresu",
  "title130": "Zadejte heslo",
  "title131": "Zadejte heslo znovu",
  "title132": "Hesla, která jste zadali, se neshodují",
  "title133": "Zadejte heslo pro klíč",
  "title134": "Zadejte heslo pro klíč znovu",
  "title135": "Hesla pro klíč, která jste zadali, se neshodují",
  "title136": "Zadejte ověřovací kód obrázku",
  "title137": "Registrace selhala",
  "title138": "Zadejte přihlašovací heslo",
  "title139": "Přihlašování",
  "title140": "Zadejte heslo znovu",
  "title141": "Zadejte heslo pro klíč",
  "title142": "Hesla, která jste zadali, se neshodují",
  "title143": "Odesílání",
  'content1' : `Tým USDT oficiálně vstoupil na platformu pro obchodování s digitálními aktivy.
    Díky spolehlivému a škálovatelnému technickému návrhu, vysoce kvalitním službám a větší podpoře se platforma pro obchodování s digitálními aktivy USDT zaměřuje na poskytování svým speciálním uživatelům pohodlnějších, bezpečnějších a pohodlných služeb pro směnu USDT a kvalitních blockchainových aktiv. Klienti VIP po celém světě poskytují služby vkladů, převodů, výběrů, finančního řízení a investic. Exkluzivní finanční správa VIP, investice a zákaznická podpora 24 hodin denně.
    1. Platforma je otevřena pro registraci publika. Kdokoli si může zaregistrovat svůj vlastní účet.
    2. VIP úroveň nově registrovaného účtu je 0 (uložení hodnoty, převod, výběr atd.). Tyto služby jsou zdarma a není nutné účet upgradovat, abyste je používali. Pokud váš účet potřebuje přijímat převody od ostatních, je nutné použít limity platby (v tuto chvíli účty úrovně 0 nebudou moci přijímat platby) a je nutné upgradovat úroveň účtu na VIP, abyste mohli používat funkci platby; čím vyšší úroveň, tím se liší denní a měsíční limity, prosím upgradujte podle potřeby.
    3. Můžeme také poskytovat různé služby, jako je směna měn a půjčky založené na USDT pro zákazníky VIP.`,

'content2' : `     1. Klikněte na tlačítko VIP
    2. Vyberte úroveň VIP, kterou chcete zakoupit
    3. Zaplaťte VIP zálohu a úspěšně ji zakupte
    4. Požadovaný vklad pro úroveň VIP (můžete jej kdykoli zrušit a získat zpět svůj vklad)
    Vklad VIP1: 50 USDT (VIP1 je služba pro nováčky, platná po dobu 1 měsíce, vklad není vratný a nelze jej zrušit)
    Vklad VIP2: 1000 USDT
    Vklad VIP3: 3000 USDT
    Vklad VIP4: 30000 USDT
    Vklad VIP5: 50000 USDT
    Vklad VIP6: 100000 USDT
    Úroveň VIP nově registrovaného účtu je 0 (uložení hodnoty, převod, výběr atd.). Tyto služby jsou zdarma a není nutné účet upgradovat, abyste je používali. Pokud váš účet potřebuje přijímat převody od ostatních, je nutné použít limity platby (v tuto chvíli účty úrovně 0 nebudou moci přijímat platby) a je nutné upgradovat úroveň účtu na VIP, abyste mohli používat funkci platby; čím vyšší úroveň, tím se liší denní a měsíční limity, prosím upgradujte podle potřeby.
    VIP1: Limit platby 10 USDT denně a 30 USDT měsíčně
    VIP2: Limit platby 100 USDT denně a 3000 USDT měsíčně
    VIP3: Limit platby 30000 USDT denně a 900000 USDT měsíčně
    VIP4: Limit platby 100000 USDT denně a 3000000 USDT měsíčně
    VIP5: Limit platby 200000 USDT denně a 6000000 USDT měsíčně
    VIP6: Limit platby 2000000 USDT denně a 60000000 USDT měsíčně`,

'content3' : `Poznámka: VIP mohou používat funkci převodu na platformě. Převody mohou být prováděny pouze mezi VIP účty na platformě (úspěšné převody nelze vrátit)
    1. Klikněte na tlačítko převodu
    2. Správně vyplňte uživatelské jméno VIP, e-mailovou adresu a telefonní číslo
    3. Klikněte na potvrdit pro dokončení převodu (není nutné zadávat KEY)
    4. Pokud váš účet potřebuje přijímat převody od ostatních, je nutné použít limity platby
    VIP1: Limit platby 10 USDT denně a 30 USDT měsíčně
    VIP2: Limit platby 100 USDT denně a 3000 USDT měsíčně
    VIP3: Limit platby 30000 USDT denně a 900000 USDT měsíčně
    VIP4: Limit platby 100000 USDT denně a 3000000 USDT měsíčně
    VIP5: Limit platby 200000 USDT denně a 6000000 USDT měsíčně
    VIP6: Limit platby 2000000 USDT denně a 60000000 USDT měsíčně
    Příklad limitu převodu: Pokud je váš účet VIP5
    VIP5 převod na VIP1 (10USDT)
    VIP5 převod na VIP2 (100USDT)
    VIP5 převod na VIP3 (30000USDT)
    VIP5 převod na VIP4 (100000USDT)
    VIP5 převod na VIP5 (200000USDT)
    VIP6 převod na VIP6 (2000000USDT)`,

'content4' : `      1. Klikněte na tlačítko pro nabíjení
    2. Vyberte částku, kterou chcete uložit
    3. Klikněte na potvrdit, zadejte (KEY)
    4. Získáte exkluzivní adresu pro uložení hodnoty, na kterou můžete převést prostředky z jakékoli burzy nebo peněženky.
    5. Částka vašeho vkladu bude automaticky přidána do vašeho účtu`,

'content5' : `      1. Klikněte na tlačítko pro výběr
    2. Zadejte adresu pro výběr USDT (TRC20)
    3. Zadejte částku pro výběr
    4. Klikněte na tlačítko potvrdit a zadejte KEY
    5. Výběr je úspěšný a vaše výběry dorazí do 2 hodin.`,

'content6' : `      1. KEY je nastaveno vámi a nemůže být získáno jinými osobami. KEY je jedinečné a nelze ho resetovat.
    2. KEY je jediným důkazem vašeho účtu. Nelze jej upravit nebo obnovit.
    3. Pokud zapomenete KEY, stále budete moci vybírat prostředky z vašeho účtu. Můžete si zaregistrovat nový účet, upgradovat VIP a poté převést prostředky ze starého účtu na nový účet pro výběr. Pro převody v rámci platformy VIP není nutné mít KEY.
    KEY je jediným dokladem pro váš účet a nemůže být upraven nebo obnoven. Aby byla chráněna soukromí zákazníka, platforma nebude zaznamenávat číslo účtu, heslo, telefonní číslo, e-mail nebo KEY uživatele. Prosím, uchovávejte své přihlašovací údaje v bezpečí.`,

'content7' : `      1. Klikněte na moji domovskou stránku
    2. Klikněte na tlačítko Centrum zabezpečení
    3. Vyberte heslo nebo e-mail, které potřebujete změnit
    4. Zadejte KEY pro dokončení`,

'content8' : `      (Poznámka: Při registraci, prosím, neotevírejte překladový software. Pokud otevřete překladový software, tlačítko nebude potvrzeno. Pokud se nemůžete zaregistrovat, změňte prosím prohlížeč)
    1. Klikněte na tlačítko pro registraci na hlavní stránce
    2. Klikněte na registraci
    3. Vyplňte své registrační údaje
    4. Uložte KEY správně při jeho nastavení. Nelze jej upravit ani obnovit (ztráta může způsobit škodu na majetku)`,

'content9' : `      Uložte bezpečně a snadno vydělávejte
    1. Minimální částka pro uložení: 5000 USDT (účty s prostředky nižšími než 5000 nemohou využívat finanční služby)
    2: Vaše aktiva budou generovat příjmy podle různých úrovní a celkový příjem na každé úrovni se sečte.
    3: Očekávaný roční příjem bude upraven na základě skutečných příjmů těžebního fondu.
    4: Příjmy budou vypočítány den po otevření PoS.
    5: Není nutné zamrazovat zajištěná aktiva, můžete kdykoli obchodovat nebo vybírat mince.
    Představuje datum slibu. Výdělky začnou na T+1 (další den) a budou rozděleny na váš účet na T+2.
    Žádný příjem nebude generován v den, kdy uvolníte zajištěná aktiva. Výdělky budou každý den distribuovány na váš spotový účet. Aktuální finanční produkty lze kdykoli zrušit a příjmy budou vyrovnány do dne předchozího opuštění PoS.
    U jiných produktů, když je dosaženo minimálního úložného období, si můžete vybrat, zda je odkoupit nebo pokračovat v ukládání. Konečný příjem bude vypočítán na základě skutečného počtu úložných dnů a hlavní částka bude odblokována v den odblokování výběru.
    Mezi finančními produkty, které jsou poskytovány, si můžete vybrat z desítek digitálních aktiv (jako je Bitcoin, Ethereum) a stablecoiny.`,

'content10' : `      1. Děkujeme, že jste se stali naším členem, budeme vám poskytovat lepší služby a podporu!
    2. Získáte služby pro převod a sběr
    3. Užijete si exkluzivní VIP sazby a příznivější transakční náklady.
    4. Získáte exkluzivní VIP odznak a ukážete svou identitu a status.
    5. Připojíte se k naší exkluzivní VIP skupině a budete mít více exkluzivní zákaznickou podporu.
    6. Budete pravidelně dostávat zprávy o průmyslu, abyste pochopili dynamiku trhu a investiční příležitosti.
    7. Užijete si exkluzivní sváteční výhody a personalizované privilegium pro památky, které vám umožní cítit naši péči.
    8. Budete mít příležitost účastnit se exkluzivních VIP aktivit a poznat více úspěšných investorů.
    9. Budete mít exkluzivní 24/7 VIP zákaznickou službu a získáte rychlejší a prioritní řešení podpory.
    10. Získáte nezávislý zelený kanál a užijete si pohodlnější služby pro vklady a výběry.`,


},

"el":{
  "menu_1": "Αρχική",
  "menu_2": "Κατάστημα",
  "menu_3": "Ο λογαριασμός μου",
  "title1": "Νόμισμα",
  "title2": "Τιμή τελευταίας ενημέρωσης",
  "title3": "Συνεργάτες",
  "title4": "Γλώσσα",
  "title5": "Αρχική",
  "welcome": "Καλώς ήρθατε",
  "hello": "Γειά σας",
  "title6": "Διαθέσιμο υπόλοιπο",
  "title7": "Ημερήσιο όριο εισοδήματος",
  "title8": "Μηνιαίο όριο εισοδήματος",
  "title9": "Κατάθεση",
  "title10": "Ανάληψη",
  "title11": "Εσωτερική μεταφορά",
  "title12": "Αναβάθμιση μέλους",
  "title13": "Ετήσια απόδοση αναφορών",
  "title14": "Επενδυτική περίοδος",
  "title15": "Αρχικό ποσό",
  "title16": "Συμμετοχή τώρα",
  "title17": "Καταγραφή πλατφόρμας",
  "title18": "Ανάληψη",
  "title19": "Επαναφόρτιση",
  "title20": "Ο λογαριασμός μου",
  "title21": "Η περιουσία μου",
  "title22": "Κατάθεση",
  "title23": "Ανάληψη",
  "title24": "Μεταφορά",
  "title25": "VIP",
  "title26": "Ανακοινώσεις",
  "title27": "Γρήγορη αγορά",
  "title28": "Κέντρο βοήθειας",
  "title29": "Αποσύνδεση",
  "title30": "Ρυθμίσεις",
  "title31": "Κέντρο ασφαλείας",
  "title32": "Σχετικά με εμάς",
  "title33": "Επιβεβαίωση email",
  "title34": "Αλλαγή κωδικού πρόσβασης",
  "title35": "Επεξεργασία email",
  "context1": "Αυτή η ιστοσελίδα είναι μια αποκεντρωμένη πλατφόρμα χρηματοοικονομικών προϊόντων DeFi με υψηλό επίπεδο ανωνυμίας και ασφάλειας. Δεν χρειάζεται να ανησυχείτε για τη διαρροή προσωπικών σας δεδομένων ή διαδικασίες KYC. Οι χρήστες μπορούν να αποθηκεύουν και να αναλαμβάνουν ελεύθερα τα κεφάλαιά τους. Τα αποκεντρωμένα χρηματοοικονομικά προϊόντα στην πλατφόρμα προσφέρουν υψηλές αποδόσεις και σταθερότητα με απλές και γρήγορες υπηρεσίες.",
  "context2": "（Για εφαρμογές αποκεντρωμένων χρηματοοικονομικών υπηρεσιών DeFi）",
  "context3": "Το DeFi (Decentralized Finance), που σημαίνει Αποκεντρωμένα Χρηματοοικονομικά, είναι μια εφαρμογή της τεχνολογίας blockchain στον χρηματοοικονομικό τομέα (Fintech). Το DeFi δημιουργεί ένα ανοικτό και διαφανές χρηματοοικονομικό σύστημα μέσω αποκεντρωμένων ανοιχτών blockchain πλατφορμών.",
  "context4": "Το DeFi αναφέρεται στη χρήση ανοιχτού λογισμικού και αποκεντρωμένων δικτύων για τη μετατροπή των παραδοσιακών χρηματοοικονομικών προϊόντων σε αξιόπιστα και διαφανή πρωτόκολλα που λειτουργούν χωρίς μεσάζοντες. Το DeFi είναι η ίδια η έννοια και η αρχιτεκτονική που επικεντρώνεται στην 'αποκέντρωση', που σημαίνει ότι δεν υπάρχει διαχειριστής και κάθε χρήστης είναι ισότιμος με τα ίδια δικαιώματα. Εάν κάποιος θέλει να τροποποιήσει το περιεχόμενο, πρέπει να επιτευχθεί συμφωνία από όλους τους χρήστες. Επομένως, τα χαρακτηριστικά του DeFi περιλαμβάνουν τη διανεμημένη ιδιοκτησία που δεν ανήκει σε κανέναν και είναι δύσκολο να παραποιηθεί. Το DeFi έχει επίσης τα πλεονεκτήματα του blockchain, όπως η διαφάνεια, η αντοχή στην λογοκρισία και η αμετάβλητη καταγραφή.",
  "context5": "Το DeFi έχει πολλά πλεονεκτήματα, όπως η αξιόπιστη βάση, η απαίτηση άδειας από θεσμικούς φορείς και η δυνατότητα ελέγχου των περιουσιακών στοιχείων από το άτομο. Το μεγαλύτερο πλεονέκτημα του DeFi είναι ότι εξαλείφει τους 'μεσάζοντες' μέσω της αποκέντρωσης, διατηρώντας ταυτόχρονα τη διαφάνεια και την αμετάβλητη φύση των βιβλίων.",
  "context6": "Η κύρια διαφορά μεταξύ του DeFi και του Fintech είναι στον μηχανισμό της 'εμπιστοσύνης'. Το Fintech εφαρμόζει την τεχνολογία blockchain εντός του υπάρχοντος χρηματοοικονομικού συστήματος, εξακολουθεί να εξαρτάται από κεντρικές αρχές και όλες οι συναλλαγές ελέγχονται από ανθρώπους που κερδίζουν την εμπιστοσύνη των χρηστών μέσω εξωτερικών μηχανισμών. Αντίθετα, το κύριο χαρακτηριστικό του DeFi είναι η αποκέντρωση μέσω έξυπνων συμβολαίων. Ο κώδικας του είναι ανοιχτός και οι βασικές λειτουργίες εκτελούνται στο blockchain, που είναι αμετάβλητο και εκτελέσιμο, οπότε οι χρήστες μπορούν να εμπιστευτούν τη δομή του.",
  "context7": "Ορατές εφαρμογές του DeFi περιλαμβάνουν αποκεντρωμένα πρωτόκολλα ανταλλαγής, stablecoins, δάνεια, παράγωγα, ταμεία, αγορές προβλέψεων, πρωτόκολλα για την τοκενικοποίηση, KYC/AML και επαλήθευση ταυτότητας.",
  "title36": "Κατάθεση",
  "title37": "Δίκτυο",
  "title38": "Ποσό",
  "title39": "Κατάθεση",
  "title40": "Χρησιμοποιήστε τη διεύθυνση για να καταθέσετε από το πορτοφόλι ή το ανταλλακτήριο σας. Μετά την ολοκλήρωση της κατάθεσης, το ποσό θα προστεθεί αυτόματα στον λογαριασμό σας.",
  "title41": "Ανάληψη",
  "title42": "Διεύθυνση πορτοφολιού",
  "title43": "Δίκτυο",
  "title44": "Ποσό",
  "title45": "Διαθέσιμο υπόλοιπο",
  "title46": "Συμβουλή ασφαλείας: Ελέγξτε τη διεύθυνση πριν από την ανάληψη, τα κεφάλαια δεν επιστρέφονται μετά την ολοκλήρωση της μεταφοράς.",
  "title47": "Ανάληψη",
  "title48": "Εισάγετε διεύθυνση πορτοφολιού",
  "title49": "5-1000000",
  "title50": "Εσωτερική μεταφορά",
  "title51": "Λογαριασμός",
  "title52": "Τα μέλη VIP μπορούν να χρησιμοποιήσουν την μεταφορά με ένα κλικ χωρίς κωδικό",
  "title53": "Ο παραλήπτης πρέπει να είναι τουλάχιστον VIP1",
  "title54": "Τέλος υπηρεσίας",
  "title55": "Εσωτερική μεταφορά",
  "title56": "Αγορά",
  "title57": "Κατάθεση",
  "title58": "Κανόνες επιπέδων",
  "title59": "Κατάθεση",
  "title60": "Ημερήσιο όριο εισοδήματος",
  "title61": "Μηνιαίο όριο εισοδήματος",
  "title62": "Οι χρήστες πρέπει να καταθέσουν για να χρησιμοποιήσουν τις υπηρεσίες VIP. Το VIP1 είναι έγκυρο για 1 μήνα και μετά τη λήξη του, θα ακυρωθεί αυτόματα. Τα μέλη VIP2 και άνω, μετά τη χρήση της υπηρεσίας για 2 μήνες, μπορούν να επικοινωνήσουν με την εξυπηρέτηση πελατών για επιστροφή κατάθεσης. Για περισσότερες πληροφορίες, ανατρέξτε στο Κέντρο βοήθειας.",
  "title63": "Καταγραφή",
  "title64": "Αναβάθμιση μέλους",
  "title65": "VIP1 πρέπει να περιμένει 30 ημέρες για αυτόματη επιστροφή (δεν είναι δυνατή η αίτηση για επιστροφή).",
  "title66": "VIP2-VIP6 μπορούν να επικοινωνήσουν με την εξυπηρέτηση πελατών για αίτημα επιστροφής. Μετά την επιτυχή επιστροφή, το ποσό της κατάθεσης θα επιστραφεί στον λογαριασμό και το επίπεδο VIP θα ακυρωθεί.",
  "title67": "Ανακοινώσεις",
  "title68": "Γειά σας! Καλώς ήρθατε στην πλατφόρμα!",
  "title69": "Λογαριασμός",
  "title70": "Κωδικός πρόσβασης",
  "title71": "Εισάγετε το όνομα χρήστη ή τη διεύθυνση email",
  "title72": "Εισάγετε τον κωδικό πρόσβασης",
  "title73": "Εισάγετε τον κωδικό επαλήθευσης",
  "title74": "Σύνδεση",
  "title75": "Δεν έχετε λογαριασμό;",
  "title76": "Εγγραφείτε",
  "title77": "Ανάκτηση κωδικού πρόσβασης",
  "title78": "Κωδικός πρόσβασης",
  "title79": "Κωδικός πρόσβασης",
  "title80": "Επιβεβαιώστε τον κωδικό πρόσβασης",
  "title81": "Κωδικός",
  "title82": "Εισάγετε τον κωδικό",
  "title83": "Επιβεβαιώστε ξανά τον κωδικό πρόσβασης",
  "title84": "Εισάγετε τον κωδικό πρόσβασης",
  "title85": "Επιβεβαίωση",
  "title86": "Δημιουργία λογαριασμού",
  "title87": "Όνομα χρήστη",
  "title88": "Εισάγετε το όνομα χρήστη σας",
  "title89": "Email",
  "title90": "Εισάγετε τη διεύθυνση email σας",
  "title91": "Χώρα",
  "title92": "Κινητό τηλέφωνο",
  "title93": "Εισάγετε τον αριθμό τηλεφώνου σας",
  "title94": "Εγγραφή",
  "title95": "ΣΥΝΔΕΣΗ",
  "title96": "Έχετε ήδη λογαριασμό;",
  "title97": "Γρήγορη αγορά",
  "title98": "Αγοράστε Tether (USDT) με πιστωτική ή χρεωστική κάρτα μέσω Paxful",
  "title99": "Αγοράστε Tether (USDT) με πιστωτική ή χρεωστική κάρτα μέσω ChangeNow",
  "title100": "Αγοράστε Tether (USDT) με πιστωτική ή χρεωστική κάρτα μέσω Changelly",
  "title101": "Αγοράστε Tether (USDT) με πιστωτική ή χρεωστική κάρτα μέσω Binance Exchange",
  "title102": "Αγοράστε Tether (USDT) με πιστωτική ή χρεωστική κάρτα μέσω Bybit Exchange",
  "title103": "Αγοράστε Tether (USDT) με πιστωτική ή χρεωστική κάρτα μέσω OKX Exchange",
  "title104": "Κέντρο βοήθειας",
  "title105": "1. Οδηγίες χρήσης της πλατφόρμας",
  "title106": "2. Πώς να ενεργοποιήσετε VIP",
  "title107": "3. Εσωτερική μεταφορά VIP (χωρίς ανάγκη κωδικού)",
  "title108": "4. Πώς να κάνετε κατάθεση;",
  "title109": "5. Πώς να κάνετε ανάληψη;",
  "title110": "6. Τι να κάνω αν ξεχάσω τον κωδικό μου;",
  "title111": "7. Πώς να αλλάξω τον κωδικό πρόσβασης ή το email;",
  "title112": "8. Πώς να εγγραφείτε;",
  "title113": "9. Πώς να επενδύσετε;",
  "title114": "10. VIP επενδυτικές υπηρεσίες",
  "title115": "Αποστολή",
  "title116": "Εισάγετε τον κωδικό για τον κωδικό πρόσβασης",
  "title117": "Εισάγετε διεύθυνση πορτοφολιού",
  "title118": "Εισάγετε το ποσό για ανάληψη",
  "title119": "Χρησιμοποιήστε τη διεύθυνση για να καταθέσετε από το πορτοφόλι ή το ανταλλακτήριο σας. Μετά την ολοκλήρωση της κατάθεσης, το ποσό θα προστεθεί αυτόματα στον λογαριασμό σας.",
  "title120": "Αποστολή",
  "title121": "Η ελάχιστη κατάθεση είναι 50",
  "title122": "Αίτημα επιστροφής",
  "title123": "Εισάγετε το ποσό που θέλετε να μεταφέρετε",
  "title124": "Εισάγετε το όνομα χρήστη",
  "title125": "Χρησιμοποιήστε τη διεύθυνση για να καταθέσετε από το πορτοφόλι ή το ανταλλακτήριο σας. Μετά την ολοκλήρωση της κατάθεσης, το ποσό θα προστεθεί αυτόματα στον λογαριασμό σας.",
  "title126": "QR Κωδικός Πορτοφολιού",
  "title127": "Αναμονή",
  "title128": "Εισάγετε τον αριθμό τηλεφώνου σας",
  "title129": "Εισάγετε τη διεύθυνση email σας",
  "title130": "Εισάγετε τον κωδικό πρόσβασης",
  "title131": "Εισάγετε τον κωδικό πρόσβασης ξανά",
  "title132": "Οι κωδικοί πρόσβασης δεν ταιριάζουν",
  "title133": "Εισάγετε τον κωδικό για το κλειδί",
  "title134": "Εισάγετε τον κωδικό για το κλειδί ξανά",
  "title135": "Οι κωδικοί πρόσβασης για το κλειδί δεν ταιριάζουν",
  "title136": "Εισάγετε τον κωδικό επαλήθευσης εικόνας",
  "title137": "Η εγγραφή απέτυχε",
  "title138": "Εισάγετε τον κωδικό πρόσβασης σύνδεσης",
  "title139": "Σύνδεση",
  "title140": "Εισάγετε ξανά τον κωδικό πρόσβασης",
  "title141": "Εισάγετε τον κωδικό για το κλειδί",
  "title142": "Οι κωδικοί πρόσβασης δεν ταιριάζουν",
  "title143": "Αποστολή",
  'content1' : `Η ομάδα USDT έχει επίσημα εισέλθει στην πλατφόρμα συναλλαγών ψηφιακών περιουσιακών στοιχείων.
    Με αξιόπιστο και επεκτάσιμο τεχνικό σχεδιασμό, υπηρεσίες υψηλής ποιότητας και περισσότερη υποστήριξη, η πλατφόρμα συναλλαγών ψηφιακών περιουσιακών στοιχείων USDT αποσκοπεί στο να προσφέρει στους ειδικούς χρήστες της πιο βολικές, ασφαλέστερες και πιο άνετες υπηρεσίες συναλλαγών ανταλλαγής USDT και υψηλής ποιότητας blockchain περιουσιακά στοιχεία. Οι παγκόσμιοι πελάτες VIP παρέχουν καταθέσεις, μεταφορές, αναλήψεις, χρηματοοικονομική διαχείριση και υπηρεσίες επένδυσης. VIP αποκλειστική χρηματοοικονομική διαχείριση, επενδύσεις και εξυπηρέτηση πελατών 24 ώρες το 24ωρο.
    1. Η πλατφόρμα είναι ανοιχτή για εγγραφή κοινού. Ο καθένας μπορεί να εγγράψει τον δικό του λογαριασμό.
    2. Το επίπεδο VIP του νέου εγγεγραμμένου λογαριασμού είναι 0 (αποθήκευση αξίας, μεταφορά, ανάληψη κ.λπ.). Αυτές οι υπηρεσίες είναι δωρεάν και δεν χρειάζεται να αναβαθμίσετε τον λογαριασμό σας για να τις χρησιμοποιήσετε. Εάν ο λογαριασμός σας χρειάζεται να λάβει μεταφορές από άλλους, πρέπει να χρησιμοποιήσετε όρια πληρωμών (σε αυτή την περίπτωση, οι λογαριασμοί επιπέδου 0 δεν θα μπορούν να δέχονται πληρωμές) και θα πρέπει να αναβαθμίσετε το επίπεδο του λογαριασμού σας σε VIP για να χρησιμοποιήσετε τη λειτουργία πληρωμών. Όσο υψηλότερο το επίπεδο, τόσο διαφορετικά θα είναι τα ημερήσια και μηνιαία όρια, παρακαλούμε αναβαθμίστε όπως απαιτείται.
    3. Μπορούμε επίσης να προσφέρουμε διάφορες υπηρεσίες, όπως ανταλλαγή νομισμάτων και δάνεια με ενέχυρο USDT για τους πελάτες VIP.`,

'content2' : `     1. Κάντε κλικ στο κουμπί VIP
    2. Επιλέξτε το επίπεδο VIP που θέλετε να αγοράσετε
    3. Πληρώστε την εγγύηση VIP και ολοκληρώστε την αγορά
    4. Η απαιτούμενη κατάθεση για το επίπεδο VIP (μπορείτε να το ακυρώσετε οποιαδήποτε στιγμή και να πάρετε την κατάθεσή σας πίσω)
    Κατάθεση VIP1: 50 USDT (VIP1 είναι μια υπηρεσία για αρχάριους, ισχύει για 1 μήνα, η κατάθεση δεν επιστρέφεται και δεν μπορεί να ακυρωθεί)
    Κατάθεση VIP2: 1000 USDT
    Κατάθεση VIP3: 3000 USDT
    Κατάθεση VIP4: 30000 USDT
    Κατάθεση VIP5: 50000 USDT
    Κατάθεση VIP6: 100000 USDT
    Το επίπεδο VIP του νέου εγγεγραμμένου λογαριασμού είναι 0 (αποθήκευση αξίας, μεταφορά, ανάληψη κ.λπ.). Αυτές οι υπηρεσίες είναι δωρεάν και δεν χρειάζεται να αναβαθμίσετε τον λογαριασμό σας για να τις χρησιμοποιήσετε. Εάν ο λογαριασμός σας χρειάζεται να λάβει μεταφορές από άλλους, πρέπει να χρησιμοποιήσετε όρια πληρωμών (σε αυτή την περίπτωση, οι λογαριασμοί επιπέδου 0 δεν θα μπορούν να δέχονται πληρωμές) και θα πρέπει να αναβαθμίσετε το επίπεδο του λογαριασμού σας σε VIP για να χρησιμοποιήσετε τη λειτουργία πληρωμών. Όσο υψηλότερο το επίπεδο, τόσο διαφορετικά θα είναι τα ημερήσια και μηνιαία όρια, παρακαλούμε αναβαθμίστε όπως απαιτείται.
    VIP1: Όριο πληρωμής 10 USDT ανά ημέρα και 30 USDT ανά μήνα
    VIP2: Όριο πληρωμής 100 USDT ανά ημέρα και 3000 USDT ανά μήνα
    VIP3: Όριο πληρωμής 30000 USDT ανά ημέρα και 900000 USDT ανά μήνα
    VIP4: Όριο πληρωμής 100000 USDT ανά ημέρα και 3000000 USDT ανά μήνα
    VIP5: Όριο πληρωμής 200000 USDT ανά ημέρα και 6000000 USDT ανά μήνα
    VIP6: Όριο πληρωμής 2000000 USDT ανά ημέρα και 60000000 USDT ανά μήνα`,

'content3' : `Σημείωση: Οι VIP μπορούν να χρησιμοποιήσουν τη λειτουργία μεταφοράς της πλατφόρμας. Οι μεταφορές μπορούν να γίνονται μόνο μεταξύ VIP λογαριασμών στην πλατφόρμα (οι επιτυχημένες μεταφορές δεν μπορούν να επιστραφούν)
    1. Κάντε κλικ στο κουμπί μεταφοράς
    2. Συμπληρώστε σωστά το όνομα χρήστη VIP, τη διεύθυνση email και τον αριθμό τηλεφώνου
    3. Κάντε κλικ στην επιβεβαίωση για να ολοκληρώσετε τη μεταφορά (δεν χρειάζεται να εισάγετε το KEY)
    4. Εάν ο λογαριασμός σας χρειάζεται να λάβει μεταφορές από άλλους, πρέπει να χρησιμοποιήσετε όρια πληρωμών
    VIP1: Όριο πληρωμής 10 USDT ανά ημέρα και 30 USDT ανά μήνα
    VIP2: Όριο πληρωμής 100 USDT ανά ημέρα και 3000 USDT ανά μήνα
    VIP3: Όριο πληρωμής 30000 USDT ανά ημέρα και 900000 USDT ανά μήνα
    VIP4: Όριο πληρωμής 100000 USDT ανά ημέρα και 3000000 USDT ανά μήνα
    VIP5: Όριο πληρωμής 200000 USDT ανά ημέρα και 6000000 USDT ανά μήνα
    VIP6: Όριο πληρωμής 2000000 USDT ανά ημέρα και 60000000 USDT ανά μήνα
    Παράδειγμα ορίου μεταφοράς: Εάν ο λογαριασμός σας είναι VIP5
    VIP5 μεταφορά σε VIP1 (10USDT)
    VIP5 μεταφορά σε VIP2 (100USDT)
    VIP5 μεταφορά σε VIP3 (30000USDT)
    VIP5 μεταφορά σε VIP4 (100000USDT)
    VIP5 μεταφορά σε VIP5 (200000USDT)
    VIP6 μεταφορά σε VIP6 (2000000USDT)`,

'content4' : `      1. Κάντε κλικ στο κουμπί κατάθεσης
    2. Επιλέξτε το ποσό που θέλετε να καταθέσετε
    3. Κάντε κλικ στην επιβεβαίωση και εισάγετε (KEY)
    4. Θα λάβετε μια αποκλειστική διεύθυνση κατάθεσης για τον λογαριασμό σας, στην οποία μπορείτε να μεταφέρετε από οποιαδήποτε ανταλλακτήριο ή πορτοφόλι.
    5. Το ποσό της κατάθεσής σας θα προστεθεί αυτόματα στο υπόλοιπο του λογαριασμού σας`,

'content5' : `      1. Κάντε κλικ στο κουμπί ανάληψης
    2. Εισάγετε τη διεύθυνση ανάληψης USDT (TRC20)
    3. Εισάγετε το ποσό ανάληψης
    4. Κάντε κλικ στο κουμπί επιβεβαίωσης και εισάγετε KEY
    5. Η ανάληψη είναι επιτυχής και η ανάληψή σας θα φτάσει εντός 2 ωρών.`,

'content6' : `      1. Το KEY ορίζεται από εσάς και δεν μπορεί να το αποκτήσει άλλος. Το KEY είναι μοναδικό και δεν μπορεί να επαναφερθεί.
    2. Το KEY είναι η μόνη απόδειξη του λογαριασμού σας. Δεν μπορεί να τροποποιηθεί ή να ανακτηθεί.
    3. Εάν ξεχάσετε το KEY, μπορείτε ακόμα να κάνετε ανάληψη από τον λογαριασμό σας. Μπορείτε να εγγράψετε έναν νέο λογαριασμό, να αναβαθμίσετε το VIP και στη συνέχεια να μεταφέρετε τα χρήματα από τον παλιό λογαριασμό στον νέο λογαριασμό για ανάληψη. Δεν απαιτείται KEY για μεταφορές εντός της πλατφόρμας VIP.
    Το KEY είναι το μόνο πιστοποιητικό για τον λογαριασμό σας και δεν μπορεί να τροποποιηθεί ή να ανακτηθεί. Για την προστασία της ιδιωτικότητας του πελάτη, η πλατφόρμα δεν θα σας ζητήσει ποτέ το KEY σας.
    Εάν ξεχάσετε το KEY σας, η πλατφόρμα δεν είναι υπεύθυνη για την απώλεια χρημάτων.`,

'content7' : `      1. Όλα τα διαθέσιμα νομίσματα θα εμφανίζονται στην ενότητα του λογαριασμού
    2. Ο λογαριασμός μπορεί να επιλέξει μεταξύ των νομισμάτων που υποστηρίζονται από την πλατφόρμα.
    3. Το υπόλοιπο του λογαριασμού μπορεί να παρακολουθείται καθημερινά για να παρακολουθείτε την ισχύ των νομισμάτων.`,

'content8' : `      1. Ενδέχεται να υπάρξουν καθυστερήσεις στις συναλλαγές κατά την εξαιρετική μεταφορά. Παρακαλούμε επιβεβαιώστε και επανεκκινήστε τη συναλλαγή για να αποφύγετε οποιεσδήποτε επιπλέον καθυστερήσεις.
    2. Οι υπολοιπόμενοι πιστωτικοί πόροι και τα διαθέσιμα αποθέματα ενδέχεται να χρειάζονται λίγο χρόνο για να καταγραφούν και να ενημερωθούν στις υπηρεσίες.
    3. Για οποιεσδήποτε ερωτήσεις ή ανησυχίες, επικοινωνήστε με την ομάδα υποστήριξης πελατών.
    4. Η πλατφόρμα δεν ευθύνεται για τυχόν σφάλματα που προκαλούνται από τον χρήστη.`,

'content9' : `1. Ο υπολογισμός του εισοδήματος πραγματοποιείται με βάση το επιτόκιο του προϊόντος (το επιτόκιο μπορεί να αλλάξει ανά πάσα στιγμή).
    2. Το συνολικό εισόδημα σε κάθε επίπεδο θα υπολογιστεί.
    3. Το αναμενόμενο ετήσιο εισόδημα θα προσαρμοστεί με βάση τα πραγματικά έσοδα του ταμείου εξόρυξης.
    4. Τα έσοδα θα υπολογίζονται την ημέρα μετά το άνοιγμα του PoS.
    5. Δεν είναι απαραίτητο να παγώσετε τα εξασφαλισμένα κεφάλαια, μπορείτε να διαπραγματεύεστε ή να αποσύρετε νομίσματα ανά πάσα στιγμή.
    Οι κέρδη αρχίζουν από την ημερομηνία T+1 (την επόμενη ημέρα) και διανέμονται στον λογαριασμό σας την ημέρα T+2.
    Δεν θα παραχθεί εισόδημα την ημέρα που απελευθερώνετε τα εξασφαλισμένα κεφάλαια. Τα κέρδη θα καταχωρούνται καθημερινά στον λογαριασμό σας. Τα τρέχοντα χρηματοοικονομικά προϊόντα μπορούν να ακυρωθούν ανά πάσα στιγμή και τα κέρδη θα εξισωθούν έως την ημέρα πριν την έξοδο από το PoS.
    Για άλλα προϊόντα, όταν επιτευχθεί η ελάχιστη περίοδος αποθήκευσης, μπορείτε να επιλέξετε είτε να τα αγοράσετε είτε να συνεχίσετε την αποθήκευση. Το τελικό εισόδημα θα υπολογιστεί με βάση τον πραγματικό αριθμό ημερών αποθήκευσης και το κύριο ποσό θα ξεκλειδωθεί την ημέρα απόσυρσης της επιλεγμένης αποθήκευσης.
    Μπορείτε να επιλέξετε από δεκάδες ψηφιακά περιουσιακά στοιχεία (όπως Bitcoin, Ethereum) και stablecoins.`,

'content10' : `      1. Ευχαριστούμε που γίνατε μέλος μας, θα σας παρέχουμε καλύτερες υπηρεσίες και υποστήριξη!
    2. Θα έχετε υπηρεσίες για μεταφορές και συλλογή.
    3. Θα απολαύσετε αποκλειστικά ποσοστά VIP και ευνοϊκότερα κόστη συναλλαγών.
    4. Θα αποκτήσετε αποκλειστικό σήμα VIP για να δείξετε την ταυτότητα και την κατάσταση σας.
    5. Θα συμμετάσχετε στην αποκλειστική VIP ομάδα μας και θα απολαύσετε περισσότερη αποκλειστική υποστήριξη πελατών.
    6. Θα λαμβάνετε τακτικά αναφορές της βιομηχανίας για να κατανοήσετε τη δυναμική της αγοράς και τις επενδυτικές ευκαιρίες.
    7. Θα απολαύσετε αποκλειστικά οφέλη στις διακοπές και εξατομικευμένα προνόμια για τα σημεία ενδιαφέροντος που σας επιτρέπουν να νιώσετε τη φροντίδα μας.
    8. Θα έχετε την ευκαιρία να συμμετάσχετε σε αποκλειστικές VIP δραστηριότητες και να γνωρίσετε περισσότερους επιτυχημένους επενδυτές.
    9. Θα έχετε αποκλειστική υποστήριξη 24/7 και θα αποκτήσετε ταχύτερη και προτεραιότητα υποστήριξης.
    10. Θα αποκτήσετε ανεξάρτητο πράσινο κανάλι και θα απολαύσετε πιο άνετες υπηρεσίες για καταθέσεις και αναλήψεις.`,


},
"fa":{
  "menu_1": "خانه",
  "menu_2": "فروشگاه",
  "menu_3": "حساب من",
  "title1": "ارز",
  "title2": "آخرین قیمت به روزرسانی",
  "title3": "شرکا",
  "title4": "زبان",
  "title5": "خانه",
  "welcome": "خوش آمدید",
  "hello": "سلام",
  "title6": "مانده موجود",
  "title7": "حد روزانه درآمد",
  "title8": "حد ماهانه درآمد",
  "title9": "واریز",
  "title10": "برداشت",
  "title11": "انتقال داخلی",
  "title12": "ارتقا عضویت",
  "title13": "گزارش سالانه عملکرد",
  "title14": "دوره سرمایه گذاری",
  "title15": "مقدار اولیه",
  "title16": "هم اکنون شرکت کنید",
  "title17": "ثبت پلتفرم",
  "title18": "برداشت",
  "title19": "شارژ مجدد",
  "title20": "حساب من",
  "title21": "دارایی من",
  "title22": "واریز",
  "title23": "برداشت",
  "title24": "انتقال",
  "title25": "وی آی پی",
  "title26": "اعلانات",
  "title27": "خرید سریع",
  "title28": "مرکز پشتیبانی",
  "title29": "خروج",
  "title30": "تنظیمات",
  "title31": "مرکز امنیت",
  "title32": "درباره ما",
  "title33": "تایید ایمیل",
  "title34": "تغییر رمز عبور",
  "title35": "ویرایش ایمیل",
  "context1": "این وبسایت یک پلتفرم مالی غیر متمرکز DeFi با سطح بالایی از حریم خصوصی و امنیت است. نیازی به نگرانی در مورد افشای اطلاعات شخصی شما یا فرآیندهای KYC نیست. کاربران می‌توانند آزادانه موجودی خود را ذخیره و برداشت کنند. محصولات مالی غیرمتمرکز در این پلتفرم بازدهی بالا و ثبات را با خدمات سریع و ساده ارائه می‌دهند.",
  "context2": "（برای اپلیکیشن‌های خدمات مالی غیرمتمرکز DeFi）",
  "context3": "DeFi (مالی غیرمتمرکز) یک کاربرد از تکنولوژی بلاکچین در بخش مالی (فینتک) است. DeFi یک سیستم مالی باز و شفاف از طریق پلتفرم‌های غیرمتمرکز بلاکچین ایجاد می‌کند.",
  "context4": "DeFi به استفاده از نرم‌افزارهای متن‌باز و شبکه‌های غیرمتمرکز برای تبدیل محصولات مالی سنتی به پروتکل‌های قابل اعتماد و شفاف اشاره دارد که بدون واسطه عمل می‌کنند. DeFi به خودی خود مفهوم و معماری است که بر 'غیرمتمرکز بودن' تأکید دارد، به این معنا که هیچ مدیر مرکزی وجود ندارد و همه کاربران از حقوق یکسانی برخوردارند. اگر کسی بخواهد محتوای آن را تغییر دهد، باید توافق جمعی از همه کاربران حاصل شود. بنابراین ویژگی‌های DeFi شامل مالکیت توزیع‌شده است که متعلق به هیچ‌کس نیست و سخت است که آن را جعل کرد. DeFi همچنین از مزایای بلاکچین مانند شفافیت، مقاوم در برابر سانسور و ثبت غیرقابل تغییر برخوردار است.",
  "context5": "DeFi مزایای زیادی دارد، از جمله یک پایه قابل اعتماد، نیاز به مجوز از نهادهای رسمی و قابلیت کنترل دارایی‌ها توسط خود فرد. بزرگترین مزیت DeFi این است که از طریق غیرمتمرکز بودن، واسطه‌ها را از بین می‌برد در حالی که شفافیت و طبیعت غیرقابل تغییر دفترها را حفظ می‌کند.",
  "context6": "تفاوت اصلی بین DeFi و فینتک در مکانیزم 'اعتماد' است. فینتک فناوری بلاکچین را در داخل سیستم مالی موجود اعمال می‌کند، همچنان به مقامات مرکزی وابسته است و همه تراکنش‌ها توسط افرادی که از طریق مکانیزم‌های خارجی اعتماد کاربران را جلب می‌کنند، کنترل می‌شوند. در مقابل، ویژگی اصلی DeFi غیرمتمرکز بودن از طریق قراردادهای هوشمند است. کد آن باز است و عملکردهای اصلی در بلاکچین که تغییرناپذیر و اجرایی است، انجام می‌شود بنابراین کاربران می‌توانند به ساختار آن اعتماد کنند.",
  "context7": "برنامه‌های قابل مشاهده DeFi شامل پروتکل‌های غیرمتمرکز مبادله، استیبل‌کوین‌ها، وام‌ها، مشتقات، صندوق‌ها، بازارهای پیش‌بینی، پروتکل‌ها برای توکنیزه کردن، KYC/AML و تأیید هویت هستند.",
  "title36": "واریز",
  "title37": "شبکه",
  "title38": "مقدار",
  "title39": "واریز",
  "title40": "از آدرس برای واریز از کیف پول یا صرافی خود استفاده کنید. پس از تکمیل واریز، مقدار به طور خودکار به حساب شما اضافه خواهد شد.",
  "title41": "برداشت",
  "title42": "آدرس کیف پول",
  "title43": "شبکه",
  "title44": "مقدار",
  "title45": "مانده موجود",
  "title46": "نکته امنیتی: قبل از برداشت آدرس را بررسی کنید، وجوه پس از تکمیل انتقال قابل بازگشت نیستند.",
  "title47": "برداشت",
  "title48": "آدرس کیف پول را وارد کنید",
  "title49": "5-1000000",
  "title50": "انتقال داخلی",
  "title51": "حساب",
  "title52": "اعضای VIP می‌توانند از انتقال یک‌کلیک بدون نیاز به کد استفاده کنند",
  "title53": "دریافت‌کننده باید حداقل VIP1 باشد",
  "title54": "هزینه خدمات",
  "title55": "انتقال داخلی",
  "title56": "خرید",
  "title57": "واریز",
  "title58": "قوانین سطح",
  "title59": "واریز",
  "title60": "حد درآمد روزانه",
  "title61": "حد درآمد ماهانه",
  "title62": "کاربران باید برای استفاده از خدمات VIP واریز کنند. VIP1 برای 1 ماه معتبر است و پس از اتمام آن، به طور خودکار لغو می‌شود. اعضای VIP2 و بالاتر، پس از استفاده از خدمات به مدت 2 ماه، می‌توانند برای بازپرداخت واریز با پشتیبانی تماس بگیرند. برای اطلاعات بیشتر به مرکز پشتیبانی مراجعه کنید.",
  "title63": "گزارش",
  "title64": "ارتقا عضویت",
  "title65": "VIP1 باید 30 روز منتظر بازگشت خودکار باشد (درخواست بازگشت مجاز نیست).",
  "title66": "VIP2-VIP6 می‌توانند برای درخواست بازگشت با پشتیبانی تماس بگیرند. پس از بازگشت موفق، مبلغ واریز شده به حساب بازمی‌گردد و سطح VIP لغو خواهد شد.",
  "title67": "اعلانات",
  "title68": "سلام! خوش آمدید به پلتفرم!",
  "title69": "حساب",
  "title70": "رمز عبور",
  "title71": "نام کاربری یا ایمیل خود را وارد کنید",
  "title72": "رمز عبور را وارد کنید",
  "title73": "کد تأیید را وارد کنید",
  "title74": "ورود",
  "title75": "حساب ندارید؟",
  "title76": "ثبت‌نام",
  "title77": "بازیابی رمز عبور",
  "title78": "رمز عبور",
  "title79": "رمز عبور",
  "title80": "تأیید رمز عبور",
  "title81": "کد",
  "title82": "کد را وارد کنید",
  "title83": "رمز عبور را دوباره وارد کنید",
  "title84": "رمز عبور را وارد کنید",
  "title85": "تأیید",
  "title86": "ایجاد حساب",
  "title87": "نام کاربری",
  "title88": "نام کاربری خود را وارد کنید",
  "title89": "ایمیل",
  "title90": "ایمیل خود را وارد کنید",
  "title91": "کشور",
  "title92": "تلفن همراه",
  "title93": "شماره تلفن خود را وارد کنید",
  "title94": "ثبت‌نام",
  "title95": "ورود",
  "title96": "قبلاً حساب دارید؟",
  "title97": "خرید سریع",
  "title98": "خرید Tether (USDT) با کارت اعتباری یا دبیت از طریق Paxful",
  "title99": "خرید Tether (USDT) با کارت اعتباری یا دبیت از طریق ChangeNow",
  "title100": "خرید Tether (USDT) با کارت اعتباری یا دبیت از طریق Changelly",
  "title101": "خرید Tether (USDT) با کارت اعتباری یا دبیت از طریق Binance Exchange",
  "title102": "خرید Tether (USDT) با کارت اعتباری یا دبیت از طریق Bybit Exchange",
  "title103": "خرید Tether (USDT) با کارت اعتباری یا دبیت از طریق OKX Exchange",
  "title104": "مرکز پشتیبانی",
  "title105": "1. دستورالعمل‌های استفاده از پلتفرم",
  "title106": "2. چگونه VIP فعال کنیم",
  "title107": "3. انتقال داخلی VIP (بدون نیاز به کد)",
  "title108": "4. چگونه واریز کنیم؟",
  "title109": "5. چگونه برداشت کنیم؟",
  "title110": "6. اگر رمز عبور خود را فراموش کنم چه باید بکنم؟",
  "title111": "7. چگونه رمز عبور یا ایمیل خود را تغییر دهم؟",
  "title112": "8. چگونه ثبت‌نام کنیم؟",
  "title113": "9. چگونه سرمایه‌گذاری کنیم؟",
  "title114": "10. خدمات سرمایه‌گذاری VIP",
  "title115": "ارسال",
  "title116": "کد را برای رمز عبور وارد کنید",
  "title117": "آدرس کیف پول را وارد کنید",
  "title118": "مقدار برای برداشت را وارد کنید",
  "title119": "از آدرس برای واریز از کیف پول یا صرافی خود استفاده کنید. پس از تکمیل واریز، مقدار به طور خودکار به حساب شما اضافه خواهد شد.",
  "title120": "ارسال",
  "title121": "حداقل واریز 50 است",
  "title122": "درخواست بازگشت",
  "title123": "مقدار مورد نظر برای انتقال را وارد کنید",
  "title124": "نام کاربری را وارد کنید",
  "title125": "از آدرس برای واریز از کیف پول یا صرافی خود استفاده کنید. پس از تکمیل واریز، مقدار به طور خودکار به حساب شما اضافه خواهد شد.",
  "title126": "کد QR کیف پول",
  "title127": "در انتظار",
  "title128": "شماره تلفن خود را وارد کنید",
  "title129": "ایمیل خود را وارد کنید",
  "title130": "رمز عبور را وارد کنید",
  "title131": "رمز عبور را دوباره وارد کنید",
  "title132": "رمز عبور‌ها مطابقت ندارند",
  "title133": "کد را برای کلید وارد کنید",
  "title134": "کد را برای کلید دوباره وارد کنید",
  "title135": "کد‌ها برای کلید مطابقت ندارند",
  "title136": "کد تأیید تصویر را وارد کنید",
  "title137": "ثبت‌نام ناموفق بود",
  "title138": "کد ورود را وارد کنید",
  "title139": "ورود",
  "title140": "رمز عبور را دوباره وارد کنید",
  "title141": "کد را برای کلید وارد کنید",
  "title142": "کد‌های ورود مطابقت ندارند",
  "title143": "ارسال",
  'content1' : `تیم USDT به طور رسمی وارد پلتفرم تبادل دارایی‌های دیجیتال شده است.
    با طراحی فنی قابل اعتماد و مقیاس‌پذیر، خدمات با کیفیت بالا و پشتیبانی بیشتر، پلتفرم تبادل دارایی‌های دیجیتال USDT هدف دارد که خدمات مبادله USDT راحت‌تر، ایمن‌تر و راحت‌تر را به کاربران متخصص خود و دارایی‌های بلاکچین با کیفیت بالا ارائه دهد. مشتریان VIP در سطح جهانی خدماتی مانند واریز، انتقال، برداشت، مدیریت مالی و خدمات سرمایه‌گذاری را ارائه می‌دهند. خدمات اختصاصی مدیریت مالی VIP، سرمایه‌گذاری و پشتیبانی مشتری به صورت 24 ساعته.
    1. پلتفرم برای ثبت‌نام عموم آزاد است. هر کسی می‌تواند حساب خود را ثبت کند.
    2. سطح VIP حساب جدید ثبت‌شده 0 است (ذخیره ارزش، انتقال، برداشت و غیره). این خدمات رایگان هستند و نیازی به ارتقاء حساب برای استفاده از آن‌ها نیست. اگر حساب شما نیاز به دریافت انتقال از دیگران دارد، باید از محدودیت‌های پرداخت استفاده کنید (در این صورت حساب‌های سطح 0 قادر به دریافت پرداخت نخواهند بود) و باید سطح حساب خود را به VIP ارتقاء دهید تا از ویژگی پرداخت استفاده کنید. هرچه سطح بالاتر باشد، محدودیت‌های روزانه و ماهانه متفاوت خواهند بود، لطفاً طبق نیاز ارتقاء دهید.
    3. ما همچنین می‌توانیم خدمات مختلفی مانند تبادل ارز و وام با وثیقه USDT را برای مشتریان VIP ارائه دهیم.`,

'content2' : `     1. بر روی دکمه VIP کلیک کنید
    2. سطح VIP که می‌خواهید خریداری کنید را انتخاب کنید
    3. ضمانت VIP را پرداخت کرده و خرید را تکمیل کنید
    4. مبلغ مورد نیاز برای واریز برای سطح VIP (می‌توانید هر زمان که بخواهید لغو کنید و واریز خود را پس بگیرید)
    واریز VIP1: 50 USDT (VIP1 یک سرویس برای مبتدیان است، برای 1 ماه معتبر است، واریز غیر قابل بازگشت است و نمی‌توان آن را لغو کرد)
    واریز VIP2: 1000 USDT
    واریز VIP3: 3000 USDT
    واریز VIP4: 30000 USDT
    واریز VIP5: 50000 USDT
    واریز VIP6: 100000 USDT
    سطح VIP حساب جدید ثبت‌شده 0 است (ذخیره ارزش، انتقال، برداشت و غیره). این خدمات رایگان هستند و نیازی به ارتقاء حساب برای استفاده از آن‌ها نیست. اگر حساب شما نیاز به دریافت انتقال از دیگران دارد، باید از محدودیت‌های پرداخت استفاده کنید (در این صورت حساب‌های سطح 0 قادر به دریافت پرداخت نخواهند بود) و باید سطح حساب خود را به VIP ارتقاء دهید تا از ویژگی پرداخت استفاده کنید. هرچه سطح بالاتر باشد، محدودیت‌های روزانه و ماهانه متفاوت خواهند بود، لطفاً طبق نیاز ارتقاء دهید.
    VIP1: محدودیت پرداخت 10 USDT در روز و 30 USDT در ماه
    VIP2: محدودیت پرداخت 100 USDT در روز و 3000 USDT در ماه
    VIP3: محدودیت پرداخت 30000 USDT در روز و 900000 USDT در ماه
    VIP4: محدودیت پرداخت 100000 USDT در روز و 3000000 USDT در ماه
    VIP5: محدودیت پرداخت 200000 USDT در روز و 6000000 USDT در ماه
    VIP6: محدودیت پرداخت 2000000 USDT در روز و 60000000 USDT در ماه`,

'content3' : `توجه: VIP‌ها می‌توانند از ویژگی انتقال پلتفرم استفاده کنند. انتقال‌ها تنها می‌توانند بین حساب‌های VIP در پلتفرم انجام شوند (انتقالات موفق غیر قابل بازگشت هستند)
    1. بر روی دکمه انتقال کلیک کنید
    2. نام کاربری VIP، آدرس ایمیل و شماره تلفن را به درستی وارد کنید
    3. بر روی تأیید کلیک کرده و انتقال را تکمیل کنید (نیازی به وارد کردن KEY نیست)
    4. اگر حساب شما نیاز به دریافت انتقال از دیگران دارد، باید از محدودیت‌های پرداخت استفاده کنید
    VIP1: محدودیت پرداخت 10 USDT در روز و 30 USDT در ماه
    VIP2: محدودیت پرداخت 100 USDT در روز و 3000 USDT در ماه
    VIP3: محدودیت پرداخت 30000 USDT در روز و 900000 USDT در ماه
    VIP4: محدودیت پرداخت 100000 USDT در روز و 3000000 USDT در ماه
    VIP5: محدودیت پرداخت 200000 USDT در روز و 6000000 USDT در ماه
    VIP6: محدودیت پرداخت 2000000 USDT در روز و 60000000 USDT در ماه
    مثال محدودیت انتقال: اگر حساب شما VIP5 است
    انتقال VIP5 به VIP1 (10USDT)
    انتقال VIP5 به VIP2 (100USDT)
    انتقال VIP5 به VIP3 (30000USDT)
    انتقال VIP5 به VIP4 (100000USDT)
    انتقال VIP5 به VIP5 (200000USDT)
    انتقال VIP6 به VIP6 (2000000USDT)`,

'content4' : `      1. بر روی دکمه واریز کلیک کنید
    2. مبلغی را که می‌خواهید واریز کنید انتخاب کنید
    3. بر روی تأیید کلیک کنید و (KEY) را وارد کنید
    4. آدرس واریز اختصاصی برای حساب شما به شما داده می‌شود که می‌توانید از هر صرافی یا کیف پولی به آن انتقال دهید.
    5. مبلغ واریز شما به طور خودکار به موجودی حساب شما اضافه خواهد شد`,

'content5' : `      1. بر روی دکمه برداشت کلیک کنید
    2. آدرس برداشت USDT (TRC20) را وارد کنید
    3. مبلغ برداشت را وارد کنید
    4. بر روی دکمه تأیید کلیک کرده و KEY را وارد کنید
    5. برداشت موفقیت‌آمیز است و برداشت شما در عرض 2 ساعت به حساب شما واریز خواهد شد.`,

'content6' : `      1. KEY توسط شما تنظیم می‌شود و هیچ‌کس دیگر نمی‌تواند آن را دریافت کند. KEY منحصر به فرد است و قابل بازنشانی نیست.
    2. KEY تنها مدرک برای حساب شماست. قابل تغییر یا بازیابی نیست.
    3. اگر KEY خود را فراموش کنید، همچنان می‌توانید از حساب خود برداشت کنید. می‌توانید یک حساب جدید ثبت کنید، VIP را ارتقاء دهید و سپس وجوه را از حساب قدیمی به حساب جدید منتقل کنید و برداشت کنید. برای انتقال‌ها در پلتفرم VIP نیازی به KEY نیست.
    KEY تنها مدرک برای حساب شماست و قابل تغییر یا بازیابی نیست. به منظور حفاظت از حریم خصوصی مشتری، پلتفرم هیچ‌گاه از شما درخواست KEY نخواهد کرد.
    اگر KEY خود را فراموش کنید، پلتفرم مسئول از دست رفتن وجوه نخواهد بود.`,

'content7' : `      1. تمام ارزهای موجود در بخش حساب نمایش داده می‌شوند
    2. حساب می‌تواند از بین ارزهایی که توسط پلتفرم پشتیبانی می‌شوند انتخاب کند.
    3. موجودی حساب را می‌توانید به صورت روزانه پیگیری کنید تا قدرت ارزها را مشاهده کنید.`,

'content8' : `      1. ممکن است در صورت انتقال ویژه، تاخیرهایی در تراکنش‌ها رخ دهد. لطفاً تأیید کرده و تراکنش را دوباره راه‌اندازی کنید تا از هرگونه تاخیر اضافی جلوگیری کنید.
    2. منابع اعتبار باقی‌مانده و موجودی‌های در دسترس ممکن است کمی زمان ببرند تا در خدمات ثبت و به‌روزرسانی شوند.
    3. برای هرگونه سوال یا نگرانی، با تیم پشتیبانی مشتری تماس بگیرید.
    4. پلتفرم برای اشتباهات ناشی از کاربر مسئول نخواهد بود.`,

'content9' : `1. محاسبه درآمد بر اساس نرخ بهره محصول انجام می‌شود (نرخ بهره ممکن است هر زمان تغییر کند).
    2. درآمد کل در هر سطح محاسبه خواهد شد.
    3. درآمد سالانه پیش‌بینی شده بر اساس درآمد واقعی صندوق استخراج تنظیم خواهد شد.
    4. درآمد در روز بعد از باز شدن PoS محاسبه خواهد شد.
    5. نیازی به مسدود کردن وجوه تضمینی نیست، می‌توانید در هر زمانی ارزها را معامله یا برداشت کنید.
    درآمد از تاریخ T+1 (روز بعد) شروع شده و در روز T+2 به حساب شما واریز می‌شود.
    در روز آزادسازی وجوه تضمینی درآمدی تولید نمی‌شود. درآمد به صورت روزانه به حساب شما واریز خواهد شد. محصولات مالی جاری می‌توانند در هر زمانی لغو شوند و درآمدها تا روز قبل از خروج از PoS تسویه خواهند شد.
    برای سایر محصولات، هنگامی که حداقل دوره ذخیره‌سازی به دست آید، می‌توانید انتخاب کنید که آن‌ها را خریداری کنید یا به ذخیره‌سازی ادامه دهید. درآمد نهایی بر اساس تعداد واقعی روزهای ذخیره‌سازی محاسبه خواهد شد و مبلغ اصلی در روز برداشت ذخیره‌سازی انتخاب‌شده آزاد می‌شود.
    می‌توانید از میان ده‌ها دارایی دیجیتال مختلف (مانند بیت‌کوین، اتریوم) و استیبل‌کوین‌ها انتخاب کنید.`,

'content10' : `      1. از اینکه عضو ما شدید متشکریم، ما خدمات و پشتیبانی بهتری به شما ارائه خواهیم داد!
    2. شما از خدمات برای انتقال‌ها و جمع‌آوری‌ها بهره‌مند خواهید شد.
    3. از نرخ‌های اختصاصی VIP و هزینه‌های تبادل بهتر لذت خواهید برد.
    4. شما یک نشان اختصاصی VIP خواهید داشت تا هویت و وضعیت خود را نمایش دهید.
    5. به گروه اختصاصی VIP ما ملحق خواهید شد و از پشتیبانی اختصاصی بیشتر بهره‌مند خواهید شد.
    6. گزارش‌های صنعت به طور منظم دریافت خواهید کرد تا از دینامیک بازار و فرصت‌های سرمایه‌گذاری آگاه شوید.
    7. از مزایای اختصاصی در تعطیلات و امتیازات سفارشی برای نقاط مورد علاقه لذت خواهید برد که به شما احساس مراقبت ما را می‌دهد.
    8. شما فرصت شرکت در فعالیت‌های اختصاصی VIP را خواهید داشت و موفق‌ترین سرمایه‌گذاران را خواهید دید.
    9. از پشتیبانی اختصاصی 24/7 بهره‌مند خواهید شد و اولویت در پشتیبانی خواهید داشت.
    10. کانال سبز مستقل خواهید داشت و از خدمات راحت‌تری برای واریز و برداشت بهره‌مند خواهید شد.`,


},
"fi":{
  "menu_1": "Koti",
  "menu_2": "Kauppa",
  "menu_3": "Oma tilini",
  "title1": "Valuutta",
  "title2": "Viimeisin päivitys",
  "title3": "Kumppanit",
  "title4": "Kieli",
  "title5": "Koti",
  "welcome": "Tervetuloa",
  "hello": "Hei",
  "title6": "Jäljellä oleva saldo",
  "title7": "Päivittäinen ansioraja",
  "title8": "Kuukausittainen ansioraja",
  "title9": "Talletus",
  "title10": "Nosto",
  "title11": "Sisäinen siirto",
  "title12": "Jäsenyyden päivitys",
  "title13": "Vuotuinen suorituskykytarkastus",
  "title14": "Sijoitusjakso",
  "title15": "Alkupääoma",
  "title16": "Liity nyt",
  "title17": "Rekisteröidy alustalle",
  "title18": "Nosto",
  "title19": "Lataus",
  "title20": "Oma tilini",
  "title21": "Oma varallisuus",
  "title22": "Talletus",
  "title23": "Nosto",
  "title24": "Siirto",
  "title25": "VIP-jäsenyys",
  "title26": "Ilmoitukset",
  "title27": "Nopea osto",
  "title28": "Tukikeskus",
  "title29": "Kirjaudu ulos",
  "title30": "Asetukset",
  "title31": "Turvakeskus",
  "title32": "Tietoa meistä",
  "title33": "Sähköpostivahvistus",
  "title34": "Vaihda salasana",
  "title35": "Muokkaa sähköpostia",
  "context1": "Tämä verkkosivusto on hajautettu DeFi-rahapohjainen alusta, jolla on korkea yksityisyys ja turvallisuus. Ei tarvitse huolehtia henkilökohtaisen tiedon tai KYC-prosessin paljastamisesta. Käyttäjät voivat vapaasti tallettaa ja nostaa varojaan. Hajautetut rahoitustuotteet tarjoavat korkean tuoton ja vakauden nopeasti ja helposti.",
  "context2": "(Hajautetun talouden DeFi-palvelusovellukset)",
  "context3": "DeFi (Hajautettu Rahoitus) on lohkoketjuteknologian sovellus finanssialalla (FinTech). DeFi luo avoimen ja läpinäkyvän rahoitusjärjestelmän hajautettujen lohkoketjupohjaisten alustoiden kautta.",
  "context4": "DeFi viittaa avoimen lähdekoodin ohjelmistoihin ja hajautettuihin verkkoihin, jotka muuttavat perinteisiä rahoitustuotteita luomalla luotettavia ja läpinäkyviä protokollia, jotka toimivat ilman välikäsiä. DeFi:n ydinajatus ja -arkkitehtuuri keskittyy hajauttamiseen, eli se ei perustu mihinkään keskitettyyn valvontaan, ja kaikki käyttäjät nauttivat samoista oikeuksista. Jos joku haluaa muuttaa sitä, kaikkien käyttäjien on oltava yhtä mieltä. Siksi DeFi:n ominaisuudet sisältävät hajautetun omistajuuden, joka ei kuulu kenellekään, ja sitä on vaikea väärentää. DeFi:llä on myös lohkoketjun tuomia etuja, kuten läpinäkyvyys, sensuurin kestävyys ja muuttamattomat rekisterit.",
  "context5": "DeFi:n etuja ovat muun muassa luotettava infrastruktuuri, ei tarvetta viranomaisten hyväksynnälle ja varojen hallinnan siirtäminen käyttäjälle itselleen. DeFi:n suurin etu on se, että se poistaa välikädet säilyttäen samalla läpinäkyvyyden ja muuttumattomien kirjojen luonteen hajautetun verkon kautta.",
  "context6": "DeFi:n ja FinTech:n tärkein ero on 'luottamus'-mekanismin ero. FinTech käyttää lohkoketjuteknologiaa nykyisen finanssijärjestelmän sisällä, mutta se on edelleen riippuvainen keskusviranomaisista ja kaikki transaktiot hallitaan henkilöiden kautta, jotka saavat käyttäjien luottamuksen ulkoisten mekanismien kautta. DeFi:n keskeinen ominaisuus on sen hajautettu luonne älykkäiden sopimusten kautta. Sen koodi on avoin, ja päätoiminnot tapahtuvat lohkoketjussa, joka on muuttumaton ja täytäntöönpantu, joten käyttäjät voivat luottaa sen rakenteeseen.",
  "context7": "DeFi-sovelluksiin kuuluu hajautetut pörssit, vakaavaluutat, lainat, johdannaiset, rahastot, ennustemarkkinat, tokenoinnin protokollat, KYC/AML ja identiteetin vahvistus.",
  "title36": "Talletus",
  "title37": "Verkko",
  "title38": "Määrä",
  "title39": "Talletus",
  "title40": "Käytä lompakkosi tai pörssisi osoitetta talletukseen. Kun talletus on suoritettu, summa lisätään automaattisesti tilillesi.",
  "title41": "Nosto",
  "title42": "Lompakon osoite",
  "title43": "Verkko",
  "title44": "Määrä",
  "title45": "Jäljellä oleva saldo",
  "title46": "Turvavihje: Varmista osoite ennen nostoa, varat eivät ole palautettavissa siirron jälkeen.",
  "title47": "Nosto",
  "title48": "Syötä lompakon osoite",
  "title49": "5-1000000",
  "title50": "Sisäinen siirto",
  "title51": "Tili",
  "title52": "VIP-jäsenet voivat käyttää yhden napsautuksen siirtoa ilman koodia",
  "title53": "Vastaanottajan on oltava vähintään VIP1-tasolla",
  "title54": "Palvelumaksu",
  "title55": "Sisäinen siirto",
  "title56": "Osto",
  "title57": "Talletus",
  "title58": "Tason säännöt",
  "title59": "Talletus",
  "title60": "Päivittäinen tuloraja",
  "title61": "Kuukausittainen tuloraja",
  "title62": "Käyttäjien on talletettava VIP-jäsenyyksiä varten. VIP1 on voimassa 1 kuukauden ajan ja se peruutetaan automaattisesti sen päätyttyä. VIP2 ja korkeammat jäsenet voivat ottaa yhteyttä tukeen saadakseen palautusta talletuksen jälkeen kahden kuukauden käytön jälkeen. Lisätietoja saat tukikeskuksesta.",
  "title63": "Raportti",
  "title64": "Jäsenyyden päivitys",
  "title65": "VIP1:n on odotettava automaattista palautusta 30 päivän ajan (palautuspyyntö ei ole sallittu).",
  "title66": "VIP2-VIP6 voivat ottaa yhteyttä tukeen palautuspyynnön tekemiseksi. Onnistuneen palautuksen jälkeen talletettu summa palautetaan tilille, ja VIP-taso peruutetaan.",
  "title67": "Ilmoitukset",
  "title68": "Hei! Tervetuloa alustalle!",
  "title69": "Tili",
  "title70": "Salasana",
  "title71": "Syötä käyttäjätunnuksesi tai sähköpostiosoitteesi",
  "title72": "Syötä salasanasi",
  "title73": "Syötä vahvistuskoodi",
  "title74": "Kirjaudu sisään",
  "title75": "Ei tiliä?",
  "title76": "Rekisteröidy",
  "title77": "Palauta salasana",
  "title78": "Salasana",
  "title79": "Salasana",
  "title80": "Vahvista salasana",
  "title81": "Koodi",
  "title82": "Syötä koodi",
  "title83": "Syötä salasana uudelleen",
  "title84": "Syötä salasana",
  "title85": "Vahvista",
  "title86": "Luo tili",
  "title87": "Käyttäjätunnus",
  "title88": "Syötä käyttäjätunnus",
  "title89": "Sähköposti",
  "title90": "Syötä sähköpostiosoitteesi",
  "title91": "Maa",
  "title92": "Puhelinnumero",
  "title93": "Syötä puhelinnumerosi",
  "title94": "Rekisteröidy",
  "title95": "Kirjaudu sisään",
  "title96": "Onko sinulla jo tili?",
  "title97": "Nopea osto",
  "title98": "Osta Tether (USDT) luottokortilla tai pankkikortilla Paxfulin kautta",
  "title99": "Osta Tether (USDT) luottokortilla tai pankkikortilla ChangeNow:n kautta",
  "title100": "Osta Tether (USDT) luottokortilla tai pankkikortilla Changelly:n kautta",
  "title101": "Osta Tether (USDT) luottokortilla tai pankkikortilla Binance-pörssin kautta",
  "title102": "Osta Tether (USDT) luottokortilla tai pankkikortilla Bybit-pörssin kautta",
  "title103": "Osta Tether (USDT) luottokortilla tai pankkikortilla OKX-pörssin kautta",
  "title104": "Tukikeskus",
  "title105": "1. Alustan käyttöohjeet",
  "title106": "2. Kuinka aktivoida VIP",
  "title107": "3. Sisäinen siirto VIP:lle (ei koodia tarvitse)",
  "title108": "4. Kuinka tallettaa?",
  "title109": "5. Kuinka nostaa?",
  "title110": "6. Mitä tehdä, jos unohdan salasanani?",
  "title111": "7. Kuinka muuttaa salasanaa tai sähköpostia?",
  "title112": "8. Kuinka rekisteröidyn?",
  "title113": "9. Kuinka sijoittaa?",
  "title114": "10. VIP-sijoituspalvelut",
  "title115": "Lähetä",
  "title116": "Syötä vahvistuskoodi salasanalle",
  "title117": "Syötä lompakon osoite",
  "title118": "Syötä nosto-määrä",
  "title119": "Käytä lompakkosi tai pörssisi osoitetta talletukseen. Kun talletus on suoritettu, summa lisätään automaattisesti tilillesi.",
  "title120": "Lähetä",
  "title121": "Minimi talletus on 50",
  "title122": "Palautuspyyntö",
  "title123": "Syötä haluamasi siirto-määrä",
  "title124": "Syötä käyttäjätunnus",
  "title125": "Käytä lompakkosi tai pörssisi osoitetta talletukseen. Kun talletus on suoritettu, summa lisätään automaattisesti tilillesi.",
  "title126": "QR-koodi lompakolle",
  "title127": "Odottamassa",
  "title128": "Syötä puhelinnumerosi",
  "title129": "Syötä sähköpostiosoitteesi",
  "title130": "Syötä salasanasi",
  "title131": "Syötä salasana uudelleen",
  "title132": "Salasanojen ei tarvitse täsmätä",
  "title133": "Syötä koodi avaimelle",
  "title134": "Syötä koodi avaimelle uudelleen",
  "title135": "Avaimen koodit eivät täsmää",
  "title136": "Syötä kuvan vahvistuskoodi",
  "title137": "Rekisteröinti epäonnistui",
  "title138": "Syötä kirjautumiskoodi",
  "title139": "Kirjaudu sisään",
  "title140": "Syötä salasana uudelleen",
  "title141": "Syötä koodi avaimelle",
  "title142": "Kirjautumiskoodit eivät täsmää",
  "title143": "Lähetä",
  'content1' : `USDT-tiimi on virallisesti tullut digitaalisten omaisuuserien kaupankäyntipalvelualustalle.
    Luotettavalla ja laajennettavalla teknisellä suunnittelulla, korkealaatuisilla palveluilla ja lisääntyneellä tuella USDT:n digitaalisten omaisuuserien kaupankäyntialusta pyrkii tarjoamaan erityisille käyttäjilleen kätevämmät, turvallisemmat ja helpommat USDT-vaihtopalvelut sekä korkealaatuiset lohkoketjuomaisuudet. Globaaleille VIP-asiakkaille tarjotaan talletuksia, siirtoja, nostoja, taloushallintaa ja sijoituspalveluja. VIP-erityinen taloushallinta, sijoittaminen ja asiakaspalvelu 24 tuntia päivässä.
    1. Alusta on avoinna yleisölle rekisteröitymistä varten. Kuka tahansa voi rekisteröidä oman tilinsä.
    2. Uuden rekisteröidyn tilin VIP-taso on 0 (säilytys, siirrot, nostot jne.). Nämä ovat ilmaisia eivätkä vaadi tilin päivittämistä niiden käyttämiseksi. Jos tilisi tarvitsee vastaanottaa siirtoja muilta, sinun täytyy käyttää maksurajoituksia (tällöin tason 0 tilit eivät voi vastaanottaa maksuja) ja sinun täytyy päivittää tilisi VIP-tasolle maksutoiminnon käyttämiseksi; mitä korkeampi taso, sitä erilaisten päivittäisten ja kuukausittaisten kiintiöiden mukaan jokaisella tasolla. Päivitä tarpeen mukaan.
    3. Voimme myös tarjota VIP-asiakkaille erilaisia palveluja, kuten valuutanvaihtoa ja USDT-takaukseksi otettuja lainoja.`,

'content2' : `     1. Napsauta VIP-painiketta
    2. Valitse VIP-taso, jonka haluat ostaa
    3. Maksa VIP-talletus ja osta onnistuneesti
    4. VIP-tason vaatima talletus (voit peruuttaa milloin tahansa ja saada talletuksesi takaisin)
    VIP1-talletus: 50 USDT (VIP1 on aloittelijakokemuspalvelu, voimassa 1 kuukauden ajan, talletus ei ole palautettavissa eikä sitä voi peruuttaa)
    VIP2-talletus: 1000 USDT
    VIP3-talletus: 3000 USDT
    VIP4-talletus: 30000 USDT
    VIP5-talletus: 50000 USDT
    VIP6-talletus: 100000 USDT
    Uuden rekisteröidyn tilin VIP-taso on 0 (säilytys, siirrot, nostot jne.). Nämä ovat ilmaisia eivätkä vaadi tilin päivittämistä niiden käyttämiseksi. Jos tilisi tarvitsee vastaanottaa siirtoja muilta, sinun täytyy käyttää maksurajoituksia (tällöin tason 0 tilit eivät voi vastaanottaa maksuja) ja sinun täytyy päivittää tilisi VIP-tasolle maksutoiminnon käyttämiseksi; mitä korkeampi taso, sitä erilaisten päivittäisten ja kuukausittaisten kiintiöiden mukaan jokaisella tasolla. Päivitä tarpeen mukaan.
    VIP1: Maksurajoitus 10 USDT päivässä ja 30 USDT kuukaudessa
    VIP2: Maksurajoitus 100 USDT päivässä ja 3000 USDT kuukaudessa
    VIP3: Maksurajoitus 30000 USDT päivässä ja 900000 USDT kuukaudessa
    VIP4: Maksurajoitus 100000 USDT päivässä ja 3000000 USDT kuukaudessa
    VIP5: Maksurajoitus 200000 USDT päivässä ja 6000000 USDT kuukaudessa
    VIP6: Maksurajoitus 2000000 USDT päivässä ja 60000000 USDT kuukaudessa`,

'content3' : `Huomautus: VIP:t voivat käyttää alustan siirtotoimintoa. Siirrot voidaan tehdä vain VIP-tilien välillä alustalla (onnistuneet siirrot eivät ole palautettavissa)
    1. Napsauta Siirto-painiketta
    2. Täytä oikein VIP-käyttäjätunnus, sähköpostiosoite ja puhelinnumero
    3. Napsauta Vahvista ja suorita siirto (ei tarvitse syöttää KEY:tä)
    4. Jos tilisi tarvitsee vastaanottaa siirtoja muilta, sinun täytyy käyttää maksurajoituksia
    VIP1: Maksurajoitus 10 USDT päivässä ja 30 USDT kuukaudessa
    VIP2: Maksurajoitus 100 USDT päivässä ja 3000 USDT kuukaudessa
    VIP3: Maksurajoitus 30000 USDT päivässä ja 900000 USDT kuukaudessa
    VIP4: Maksurajoitus 100000 USDT päivässä ja 3000000 USDT kuukaudessa
    VIP5: Maksurajoitus 200000 USDT päivässä ja 6000000 USDT kuukaudessa
    VIP6: Maksurajoitus 2000000 USDT päivässä ja 60000000 USDT kuukaudessa
    Siirron rajoituksen esimerkki: Jos tilisi on VIP5
    VIP5 siirtää VIP1:lle (10 USDT)
    VIP5 siirtää VIP2:lle (100 USDT)
    VIP5 siirtää VIP3:lle (30000 USDT)
    VIP5 siirtää VIP4:lle (100000 USDT)
    VIP5 siirtää VIP5:lle (200000 USDT)
    VIP6 siirtää VIP6:lle (2000000 USDT)`,

'content4' : `      1. Napsauta Talleta-painiketta
    2. Valitse talletettava määrä
    3. Napsauta Vahvista. Syötä (KEY)
    4. Saat yksilöllisen talletusosoitteen tilillesi, johon voit siirtää mistä tahansa pörssistä tai lompakosta.
    5. Talletusmääräsi lisätään automaattisesti tilisi saldoon`,

'content5' : `      1. Napsauta Nosta-painiketta
    2. Syötä USDT:n noston osoite (TRC20)
    3. Syötä nostettavien määrien määrä
    4. Napsauta Vahvista-painiketta ja syötä KEY
    5. Nosto on onnistunut ja nostosi saapuu 2 tunnin kuluessa.`,

'content6' : `      1. KEY:n asettaa itse ja sitä ei voi saada muilta. KEY on ainutlaatuinen eikä sitä voida palauttaa.
    2. KEY on tilisi ainoa todiste, sitä ei voida muokata eikä palauttaa.
    3. Jos unohdat KEY:n, tililläsi on edelleen varoja nostettavaksi. Voit rekisteröidä uuden tilin ja päivittää VIP:n, ja sitten siirtää varoja vanhalta tililtä uudelle tilille nostamista varten. VIP-alustalla ei tarvita KEY:tä siirroille.
    KEY on tilisi ainoa todiste, eikä sitä voida muokata tai palauttaa. Asiakkaiden yksityisyyden suojaamiseksi alusta ei tallenna käyttäjätunnuksia, salasanoja, puhelinnumeroita, sähköposteja tai KEY:tä. Säilytä kirjautumistiedot turvassa.`,

'content7' : `      1. Napsauta kotisivun vasemmassa yläkulmassa olevaa kuvaketta.
    2. Siirry "Tiedot" kohtaan.
    3. Syötä henkilötiedot (nimi, puhelinnumero, sähköposti jne.) ja valitse "Tallenna" (muutokset tulee tehdä manuaalisesti).`,

'content8' : `      (Huom: Rekisteröityessä ei saa avata käännösohjelmaa. Jos avaat käännösohjelman, rekisteröitymispainike ei vahvistu. Jos et voi rekisteröityä, vaihda selain)
    1. Napsauta rekisteröintipainiketta kotisivulla
    2. Klikkaa rekisteröidy
    3. Täytä rekisteröintitiedot
    4. Säilytä KEY turvallisesti, koska sitä ei voi muuttaa eikä palauttaa (hukkaa saattaa johtaa varojen menetykseen)`,

'content9' : `      Säilytä turvallisesti ja ansaitse helposti
    1. Minimitalletusmäärä: 5000 USDT (alle 5000 USDT:n saldo ei pääse talouspalveluihin)
    2: Varasi ansaitsevat tuloja eri tasojen mukaan, ja kunkin tason kokonaistulo lasketaan yhteen.
    3: Odotettu vuotuinen tuotto voi muuttua jatkuvasti louhintapoolin todellisen tuoton perusteella.
    4: Tuotto lasketaan vasta seuraavana päivänä PoS:n avaamisen jälkeen.
    5: Takaisinmaksua ei vaadita, ja voit tallettaa ja nostaa rahaa koska tahansa. Tuotto tuloista maksetaan sinulle päivittäin.`,

'content10' : `      1. Kiitos liittymisestä! Tarjoamme sinulle entistä parempia palveluja ja tukea!
    2. Hyödynnät palveluja siirroissa ja keräyksissä.
    3. Saat nauttia erikoishintaisista VIP-maksuista ja paremmista vaihto-ehdoista.
    4. Saat henkilökohtaisen VIP-merkinnän, joka kertoo sinun statuksesi ja tunnistuksesi.
    5. Liity VIP-erityisryhmään ja nauti paremmasta asiakastuesta.
    6. Saat säännöllisesti alan raportteja markkinoiden dynaamisten tilanteiden ja investointimahdollisuuksien ymmärtämiseksi.
    7. Saat VIP-erityistason edut lomilla ja räätälöityjä etuja suosikki paikoista, jolloin tunnet arvostetuksi.
    8. Saat mahdollisuuden osallistua VIP-aktiiviteetteihin ja tavata menestyneitä sijoittajia.
    9. Saat 24/7 erikoistukea ja etusijan asiakastuen käsittelyssä.
    10. Saat yksityisen vihreän kanavan, jonka kautta voit nauttia helpommista talletus- ja nostopalveluista.`,


},

"ja":{
  "menu_1": "ホーム",
  "menu_2": "ショップ",
  "menu_3": "マイアカウント",
  "title1": "通貨",
  "title2": "最終更新",
  "title3": "パートナー",
  "title4": "言語",
  "title5": "ホーム",
  "welcome": "ようこそ",
  "hello": "こんにちは",
  "title6": "残高",
  "title7": "1日の収益上限",
  "title8": "月間収益上限",
  "title9": "入金",
  "title10": "出金",
  "title11": "内部送金",
  "title12": "メンバーシップ更新",
  "title13": "年次パフォーマンスチェック",
  "title14": "投資期間",
  "title15": "初期資本",
  "title16": "今すぐ登録",
  "title17": "プラットフォームに登録",
  "title18": "出金",
  "title19": "入金",
  "title20": "マイアカウント",
  "title21": "自分の資産",
  "title22": "入金",
  "title23": "出金",
  "title24": "送金",
  "title25": "VIPメンバーシップ",
  "title26": "通知",
  "title27": "クイック購入",
  "title28": "サポートセンター",
  "title29": "ログアウト",
  "title30": "設定",
  "title31": "セキュリティセンター",
  "title32": "私たちについて",
  "title33": "メール認証",
  "title34": "パスワードを変更",
  "title35": "メールを変更",
  "context1": "このWebサイトは、プライバシーとセキュリティが高い分散型DeFiプラットフォームで、個人情報やKYCのプロセスを気にせずに利用できます。ユーザーは資金を自由に入金・出金できます。分散型金融製品は、高いリターンと安定性を迅速かつ簡単に提供します。",
  "context2": "(分散型金融DeFiアプリケーション)",
  "context3": "DeFi（分散型金融）は、ブロックチェーン技術を活用した金融（FinTech）の応用で、分散型のプラットフォームを通じて透明性のあるオープンな金融システムを構築します。",
  "context4": "DeFiはオープンソースのソフトウェアと分散型ネットワークを使用して、伝統的な金融製品を変革し、信頼性と透明性のあるプロトコルを提供します。それらは、中央集権的な管理者なしで動作し、ユーザー全員が平等な権利を持っています。誰かが変更したい場合、すべてのユーザーが同意しなければなりません。したがって、DeFiの特徴は、信頼性のある分散所有権と偽造が困難であることです。DeFiは、透明性、検閲耐性、不変のレコードといった、ブロックチェーン技術の利点を享受しています。",
  "context5": "DeFiの利点には、信頼できるインフラ、政府の承認なしでの金融サービス、ユーザー自身による資金管理が含まれます。DeFiの最大のメリットは、中央集権的な中介者を排除し、分散型ネットワークを介して透明性と不変性を保ちながら、金融サービスを提供できる点です。",
  "context6": "DeFiとFinTechの主な違いは、信頼メカニズムにあります。FinTechは、現在の金融システムの中でブロックチェーン技術を活用していますが、依然として中央集権的な当局に依存しており、すべての取引は中介者を通じて管理され、外部のメカニズムによって信頼が提供されます。一方、DeFiの中心的な特徴は、スマートコントラクトを通じて分散型であることです。そのコードはオープンで、主要な操作は不変で自動実行されるブロックチェーン上で行われ、ユーザーはその構造を信頼することができます。",
  "context7": "DeFiアプリケーションには、分散型取引所、ステーブルコイン、貸出、デリバティブ、ファンド、予測市場、トークン化プロトコル、KYC/AML、認証システムなどがあります。",
  "title36": "入金",
  "title37": "ネットワーク",
  "title38": "金額",
  "title39": "入金",
  "title40": "ウォレットまたは取引所のアドレスを使用して入金します。入金が完了すると、金額が自動的にアカウントに追加されます。",
  "title41": "出金",
  "title42": "ウォレットアドレス",
  "title43": "ネットワーク",
  "title44": "金額",
  "title45": "残高",
  "title46": "セキュリティヒント: 出金前にアドレスを確認してください。取引後は資金の返金はできません。",
  "title47": "出金",
  "title48": "ウォレットアドレスを入力",
  "title49": "5-1000000",
  "title50": "内部送金",
  "title51": "アカウント",
  "title52": "VIPメンバーは、コードなしでワンクリックで送金できます",
  "title53": "受取人は少なくともVIP1レベルでなければなりません",
  "title54": "サービス料",
  "title55": "内部送金",
  "title56": "購入",
  "title57": "入金",
  "title58": "レベルルール",
  "title59": "入金",
  "title60": "1日の収益制限",
  "title61": "月間収益制限",
  "title62": "ユーザーはVIPメンバーシップを更新するために入金する必要があります。VIP1は1ヶ月間有効で、期間終了後に自動的に解約されます。VIP2以上のメンバーは、サポートに連絡して2ヶ月使用後に入金後の返金を受け取ることができます。詳細についてはサポートセンターまでお問い合わせください。",
  "title63": "報告",
  "title64": "メンバーシップ更新",
  "title65": "VIP1は30日後に自動的に返金されるまで待機する必要があります（返金申請は許可されません）。",
  "title66": "VIP2-VIP6はサポートに連絡して返金申請を行うことができます。返金が成功した場合、入金額がアカウントに返金され、VIPレベルは取り消されます。",
  "title67": "通知",
  "title68": "こんにちは！プラットフォームへようこそ！",
  "title69": "アカウント",
  "title70": "パスワード",
  "title71": "ユーザー名またはメールアドレスを入力",
  "title72": "パスワードを入力",
  "title73": "確認コードを入力",
  "title74": "ログイン",
  "title75": "アカウントがありませんか？",
  "title76": "登録",
  "title77": "パスワードをリセット",
  "title78": "パスワード",
  "title79": "パスワード",
  "title80": "パスワードを確認",
  "title81": "コード",
  "title82": "コードを入力",
  "title83": "パスワードを再入力",
  "title84": "パスワードを入力",
  "title85": "確認",
  "title86": "アカウントを作成",
  "title87": "ユーザー名",
  "title88": "ユーザー名を入力",
  "title89": "メール",
  "title90": "メールアドレスを入力",
  "title91": "国",
  "title92": "電話番号",
  "title93": "電話番号を入力",
  "title94": "登録",
  "title95": "ログイン",
  "title96": "すでにアカウントをお持ちですか？",
  "title97": "クイック購入",
  "title98": "Paxfulを使用してクレジットカードまたはデビットカードでTether（USDT）を購入",
  "title99": "ChangeNowを使用してクレジットカードまたはデビットカードでTether（USDT）を購入",
  "title100": "Changellyを使用してクレジットカードまたはデビットカードでTether（USDT）を購入",
  "title101": "Binance取引所を使用してクレジットカードまたはデビットカードでTether（USDT）を購入",
  "title102": "Bybit取引所を使用してクレジットカードまたはデビットカードでTether（USDT）を購入",
  "title103": "OKX取引所を使用してクレジットカードまたはデビットカードでTether（USDT）を購入",
  "title104": "サポートセンター",
  "title105": "1. プラットフォームの使用ガイド",
  "title106": "2. VIPのアクティベーション方法",
  "title107": "3. VIPへの内部送金（コード不要）",
  "title108": "4. 入金方法",
  "title109": "5. 出金方法",
  "title110": "6. パスワードを忘れた場合はどうするか",
  "title111": "7. パスワードまたはメールを変更する方法",
  "title112": "8. 登録方法",
  "title113": "9. 投資方法",
  "title114": "10. VIP投資サービス",
  "title115": "送信",
  "title116": "パスワード確認コードを入力",
  "title117": "ウォレットアドレスを入力",
  "title118": "出金額を入力",
  "title119": "ウォレットまたは取引所のアドレスを使用して入金します。入金が完了すると、金額が自動的にアカウントに追加されます。",
  "title120": "送信",
  "title121": "最小入金額は50です",
  "title122": "返金申請",
  "title123": "送金金額を入力",
  "title124": "ユーザー名を入力",
  "title125": "ウォレットまたは取引所のアドレスを使用して入金します。入金が完了すると、金額が自動的にアカウントに追加されます。",
  "title126": "QRコードをウォレットに",
  "title127": "保留中",
  "title128": "電話番号を入力",
  "title129": "メールアドレスを入力",
  "title130": "パスワードを入力",
  "title131": "パスワードを再入力",
  "title132": "パスワードが一致しません",
  "title133": "確認コードを入力",
  "title134": "確認コードを再入力",
  "title135": "確認コードが一致しません",
  "title136": "画像確認コードを入力",
  "title137": "登録失敗",
  "title138": "ログインコードを入力",
  "title139": "ログイン",
  "title140": "パスワードを再入力",
  "title141": "コードを入力",
  "title142": "ログインコードが一致しません",
  "title143": "送信",
  'content1' : `USDTチームは公式にデジタル資産取引サービスプラットフォームに参入しました。
    信頼性が高くスケーラブルな技術設計、高品質なサービス、さらなるサポートを提供するUSDTデジタル資産取引プラットフォームは、特別なユーザーにより便利で安全なUSDT交換取引サービスと高品質なブロックチェーン資産を提供することを目的としています。グローバルVIP顧客には、預金、送金、引き出し、財務管理、投資サービスを提供します。VIP専用の財務管理、投資、24時間カスタマーサービスも提供しています。
    1. プラットフォームは一般公開されており、誰でもアカウントを登録できます。
    2. 新規登録アカウントのVIPレベルは0（預金、送金、引き出しなど）。これらは無料で、アカウントをアップグレードせずに操作できます。アカウントが他者から送金を受ける必要がある場合、支払い制限を使用する必要があります（この時点でレベル0アカウントは送金を受け取ることができません）。VIPレベルにアップグレードして支払い機能を使用する必要があります。レベルが高くなるほど、日次および月次の制限が異なりますので、必要に応じてアップグレードしてください。
    3. VIP顧客には、通貨交換やUSDT担保ローンなどのさまざまなサービスも提供できます。`,

'content2' : `     1. VIPボタンをクリック
    2. 購入したいVIPレベルを選択
    3. VIPデポジットを支払い、購入完了
    4. VIPレベルに必要なデポジット（いつでもキャンセルして返金を受け取ることができます）
    VIP1デポジット：50 USDT（VIP1は初心者体験サービスで、1ヶ月間有効、デポジットは返金不可、キャンセル不可）
    VIP2デポジット：1000 USDT
    VIP3デポジット：3000 USDT
    VIP4デポジット：30000 USDT
    VIP5デポジット：50000 USDT
    VIP6デポジット：100000 USDT
    新規登録アカウントのVIPレベルは0（預金、送金、引き出しなど）。これらは無料で、アカウントをアップグレードせずに操作できます。アカウントが他者から送金を受ける必要がある場合、支払い制限を使用する必要があります（この時点でレベル0アカウントは送金を受け取ることができません）。VIPレベルにアップグレードして支払い機能を使用する必要があります。レベルが高くなるほど、日次および月次の制限が異なりますので、必要に応じてアップグレードしてください。
    VIP1：支払い制限 10 USDT/日、30 USDT/月
    VIP2：支払い制限 100 USDT/日、3000 USDT/月
    VIP3：支払い制限 30000 USDT/日、900000 USDT/月
    VIP4：支払い制限 100000 USDT/日、3000000 USDT/月
    VIP5：支払い制限 200000 USDT/日、6000000 USDT/月
    VIP6：支払い制限 2000000 USDT/日、60000000 USDT/月`,

'content3' : `注意：VIPはプラットフォームの送金機能を使用できます。送金はプラットフォーム内のVIP間でのみ行うことができます（送金は返金不可）
    1. 送金ボタンをクリック
    2. 正しいVIPユーザー名、メールアドレス、電話番号を入力
    3. 確認をクリックして送金完了（KEYは必要ありません）
    4. 他のユーザーから送金を受け取る必要がある場合は、支払い制限を使用する必要があります
    VIP1：支払い制限 10 USDT/日、30 USDT/月
    VIP2：支払い制限 100 USDT/日、3000 USDT/月
    VIP3：支払い制限 30000 USDT/日、900000 USDT/月
    VIP4：支払い制限 100000 USDT/日、3000000 USDT/月
    VIP5：支払い制限 200000 USDT/日、6000000 USDT/月
    VIP6：支払い制限 2000000 USDT/日、60000000 USDT/月
    送金制限の例：あなたのアカウントがVIP5の場合
    VIP5からVIP1への送金（10 USDT）
    VIP5からVIP2への送金（100 USDT）
    VIP5からVIP3への送金（30000 USDT）
    VIP5からVIP4への送金（100000 USDT）
    VIP5からVIP5への送金（200000 USDT）
    VIP6からVIP6への送金（2000000 USDT）`,

'content4' : `      1. 入金ボタンをクリック
    2. 入金額を選択
    3. 確認をクリック（KEYを入力）
    4. あなたのアカウント専用の入金アドレスが提供され、どの取引所やウォレットからでも送金できます。
    5. 入金額は自動的にあなたのアカウント残高に追加されます`,

'content5' : `      1. 引き出しボタンをクリック
    2. USDTの引き出しアドレス（TRC20）を入力
    3. 引き出す金額を入力
    4. 確認ボタンをクリックし、KEYを入力
    5. 引き出しが成功し、2時間以内に引き出しが届きます。`,

'content6' : `      1. KEYは自分で設定し、他人に取得されることはありません。KEYは一意であり、リセットできません。
    2. KEYはアカウントの唯一の証明であり、変更または回復できません。
    3. KEYを忘れた場合でも、アカウントに残っている資金は引き出せます。新しいアカウントを再登録してVIPをアップグレードし、古いアカウントから新しいアカウントに資金を移して引き出すことができます。VIPプラットフォーム内での送金にはKEYは必要ありません。
    KEYはアカウントの唯一の証明であり、変更または回復できません。お客様のプライバシーを保護するため、プラットフォームはユーザーのアカウント番号、パスワード、電話番号、メールアドレス、またはKEYを記録しません。ログイン情報は安全に保管してください。`,

'content7' : `      1. ホームページにアクセス
    2. セキュリティセンターボタンをクリック
    3. 変更したいパスワードまたはメールアドレスを選択
    4. KEYを入力して完了`,

'content8' : `      （注意：登録時に翻訳ソフトを開かないでください。翻訳ソフトを開くと、ボタンが確認されません。登録できない場合は、ブラウザを変更してください）
    1. ホームページの登録ボタンをクリック
    2. 登録をクリック
    3. 登録情報を入力
    4. KEYを適切に保管してください。変更や回復はできません（紛失すると財産に損失が生じる可能性があります）`,
'content9' : `      資産を凍結する必要はなく、いつでも取引や引き出しが可能です。
    保証期間を示す日数です。収益はT+1（翌日）から開始され、T+2にあなたのアカウントに分配されます。
    拠出された資産を解除した日には収益は発生しません。収益は毎日スポットアカウントに分配されます。現在の金融管理はいつでもキャンセル可能で、PoSから退出する前日まで収益が精算されます。
    他の製品については、最小預金期間が経過した後、償還または継続して保管することができます。最終的な収益は、実際の保管日数に基づいて計算され、元本は償還解除日に解除されます。
    提供される金融商品には、ビットコインやイーサリアムなどのデジタル資産や安定したコインが数十種類選べます。`,

'content10' : `      1. 再度、会員になっていただきありがとうございます。今後ともより良いサービスとサポートを提供いたします！
    2. 送金および受取サービスを提供します
    3. 独占的なVIPレートとより有利な取引コストを享受できます。
    4. あなたのIDとステータスを示すVIP専用バッジを受け取ります。
    5. 独占的なVIPサービスグループに参加し、より専用のカスタマーサポートを受けます。
    6. 定期的な業界レポートを受け取り、市場の動向と投資機会を理解します。
    7. 独占的な祝祭の特典とカスタマイズされた記念品特典を受け取り、私たちの気配りを感じてください。
    8. 独占的なVIPイベントに参加する機会があり、他の成功した投資家と出会うことができます。
    9. 独占的な24時間VIPカスタマーサービスチャンネルを通じて、より迅速で優先的な解決支援を受けられます。
    10. 独立したグリーンチャネルを受け取ることで、より便利な入金および引き出しサービスを享受できます。`


  
},
"pl":{
  "menu_1": "Strona główna",
  "menu_2": "Sklep",
  "menu_3": "Moje konto",
  "title1": "Waluta",
  "title2": "Ostatnia aktualizacja",
  "title3": "Partnerzy",
  "title4": "Język",
  "title5": "Strona główna",
  "welcome": "Witamy",
  "hello": "Cześć",
  "title6": "Saldo",
  "title7": "Limit dzienny",
  "title8": "Limit miesięczny",
  "title9": "Wpłata",
  "title10": "Wypłata",
  "title11": "Przelew wewnętrzny",
  "title12": "Odnowienie członkostwa",
  "title13": "Weryfikacja roczna",
  "title14": "Okres inwestycyjny",
  "title15": "Wkład początkowy",
  "title16": "Zarejestruj się teraz",
  "title17": "Zarejestruj się na platformie",
  "title18": "Wypłata",
  "title19": "Wpłata",
  "title20": "Moje konto",
  "title21": "Moje aktywa",
  "title22": "Wpłata",
  "title23": "Wypłata",
  "title24": "Przelew",
  "title25": "Członkostwo VIP",
  "title26": "Powiadomienia",
  "title27": "Szybki zakup",
  "title28": "Centrum wsparcia",
  "title29": "Wyloguj się",
  "title30": "Ustawienia",
  "title31": "Centrum bezpieczeństwa",
  "title32": "O nas",
  "title33": "Weryfikacja e-mail",
  "title34": "Zmień hasło",
  "title35": "Zmień e-mail",
  "context1": "Ta strona internetowa jest bezpieczną i wysokiej jakości platformą DeFi, umożliwiającą użytkownikom swobodne wpłacanie i wypłacanie funduszy bez obaw o prywatność i procesy KYC. Produkty finansowe DeFi zapewniają wysokie zyski i stabilność, zapewniając łatwy i szybki dostęp do finansów.",
  "context2": "(Zdecentralizowane aplikacje finansowe)",
  "context3": "DeFi (Zdecentralizowane Finanse) to aplikacje finansowe oparte na technologii blockchain, które tworzą zdecentralizowany, przejrzysty i otwarty system finansowy.",
  "context4": "DeFi wykorzystuje oprogramowanie open-source i zdecentralizowaną sieć, aby zrewolucjonizować tradycyjne produkty finansowe, oferując przejrzyste i niezawodne protokoły bez pośredników. Systemy te działają bez centralnych administratorów, co pozwala każdemu użytkownikowi mieć równy dostęp do platformy. Jeśli zmiana jest konieczna, wszyscy użytkownicy muszą się na nią zgodzić. Charakterystyczną cechą DeFi jest zdecentralizowana własność i trudność w fałszowaniu danych. DeFi korzysta z zalet technologii blockchain, takich jak przejrzystość, odporność na cenzurę i niezmienność.",
  "context5": "Zalety DeFi obejmują niezawodną infrastrukturę, usługi finansowe bez konieczności zgody rządu oraz zarządzanie funduszami przez użytkowników. Największą zaletą DeFi jest eliminacja centralnych pośredników, dzięki czemu usługi finansowe są świadczone w sposób przejrzysty i niezawodny.",
  "context6": "Różnica między DeFi a FinTech polega na mechanizmie zaufania. FinTech wykorzystuje technologię blockchain w ramach istniejącego systemu finansowego, ale nadal polega na centralnych organach, a wszystkie transakcje są kontrolowane przez pośredników. Z kolei DeFi opiera się na zdecentralizowanej strukturze, w której operacje są wykonywane automatycznie i niezmiennie na blockchainie za pomocą inteligentnych kontraktów, które zapewniają pełną przejrzystość i zaufanie do systemu.",
  "context7": "Aplikacje DeFi obejmują zdecentralizowane giełdy, stablecoiny, pożyczki, instrumenty pochodne, fundusze, rynki prognoz, tokenizację, systemy KYC/AML i inne.",
  "title36": "Wpłata",
  "title37": "Sieć",
  "title38": "Kwota",
  "title39": "Wpłata",
  "title40": "Aby dokonać wpłaty, użyj adresu portfela lub giełdy. Po zakończeniu wpłaty kwota zostanie automatycznie dodana do Twojego konta.",
  "title41": "Wypłata",
  "title42": "Adres portfela",
  "title43": "Sieć",
  "title44": "Kwota",
  "title45": "Saldo",
  "title46": "Wskazówka bezpieczeństwa: Przed wypłatą upewnij się, że adres jest poprawny. Po zakończeniu transakcji nie ma możliwości zwrotu funduszy.",
  "title47": "Wypłata",
  "title48": "Wpisz adres portfela",
  "title49": "5-1000000",
  "title50": "Przelew wewnętrzny",
  "title51": "Konto",
  "title52": "Członkowie VIP mogą dokonać przelewu jednym kliknięciem bez potrzeby podawania kodu.",
  "title53": "Odbiorca musi być przynajmniej na poziomie VIP1.",
  "title54": "Opłata za usługę",
  "title55": "Przelew wewnętrzny",
  "title56": "Zakup",
  "title57": "Wpłata",
  "title58": "Zasady poziomu",
  "title59": "Wpłata",
  "title60": "Limit dzienny zysku",
  "title61": "Limit miesięczny zysku",
  "title62": "Użytkownicy muszą wpłacić, aby zaktualizować członkostwo VIP. VIP1 jest ważne przez 1 miesiąc, po którym zostanie automatycznie anulowane. Członkowie VIP2 i wyżsi mogą skontaktować się z pomocą techniczną i po 2 miesiącach używania otrzymać zwrot po dokonaniu wpłaty. Aby uzyskać więcej informacji, skontaktuj się z pomocą techniczną.",
  "title63": "Raport",
  "title64": "Odnowienie członkostwa",
  "title65": "VIP1 wymaga odczekania 30 dni po aktywacji, zanim zostanie zwrócone (wnioski o zwrot nie są dozwolone).",
  "title66": "VIP2-VIP6 mogą skontaktować się z pomocą techniczną, aby ubiegać się o zwrot. Po pomyślnym zwrocie, wpłacona kwota zostanie zwrócona na konto, a poziom VIP zostanie anulowany.",
  "title67": "Powiadomienia",
  "title68": "Witaj! Witaj na platformie!",
  "title69": "Konto",
  "title70": "Hasło",
  "title71": "Wpisz nazwę użytkownika lub adres e-mail",
  "title72": "Wpisz hasło",
  "title73": "Wpisz kod weryfikacyjny",
  "title74": "Zaloguj się",
  "title75": "Nie masz konta?",
  "title76": "Zarejestruj się",
  "title77": "Zresetuj hasło",
  "title78": "Hasło",
  "title79": "Hasło",
  "title80": "Potwierdź hasło",
  "title81": "Kod",
  "title82": "Wpisz kod",
  "title83": "Wpisz hasło ponownie",
  "title84": "Wpisz hasło",
  "title85": "Potwierdzenie",
  "title86": "Utwórz konto",
  "title87": "Nazwa użytkownika",
  "title88": "Wpisz nazwę użytkownika",
  "title89": "E-mail",
  "title90": "Wpisz adres e-mail",
  "title91": "Kraj",
  "title92": "Numer telefonu",
  "title93": "Wpisz numer telefonu",
  "title94": "Zarejestruj się",
  "title95": "Zaloguj się",
  "title96": "Masz już konto?",
  "title97": "Szybki zakup",
  "title98": "Kup Tether (USDT) kartą kredytową lub debetową przez Paxful",
  "title99": "Kup Tether (USDT) kartą kredytową lub debetową przez ChangeNow",
  "title100": "Kup Tether (USDT) kartą kredytową lub debetową przez Changelly",
  "title101": "Kup Tether (USDT) kartą kredytową lub debetową przez Binance",
  "title102": "Kup Tether (USDT) kartą kredytową lub debetową przez Bybit",
  "title103": "Kup Tether (USDT) kartą kredytową lub debetową przez OKX",
  "title104": "Centrum wsparcia",
  "title105": "1. Przewodnik po platformie",
  "title106": "2. Jak aktywować VIP",
  "title107": "3. Przelew wewnętrzny VIP (bez kodu)",
  "title108": "4. Jak dokonać wpłaty",
  "title109": "5. Jak dokonać wypłaty",
  "title110": "6. Co zrobić, gdy zapomnę hasła",
  "title111": "7. Jak zmienić hasło lub e-mail",
  "title112": "8. Jak się zarejestrować",
  "title113": "9. Jak inwestować",
  "title114": "10. Usługi inwestycyjne VIP",
  "title115": "Wyślij",
  "title116": "Wpisz kod weryfikacyjny",
  "title117": "Wpisz adres portfela",
  "title118": "Wpisz kwotę wypłaty",
  "title119": "Aby dokonać wpłaty, użyj adresu portfela lub giełdy. Po zakończeniu wpłaty kwota zostanie automatycznie dodana do Twojego konta.",
  "title120": "Wyślij",
  "title121": "Minimalna kwota wpłaty to 50",
  "title122": "Wniosek o zwrot",
  "title123": "Wpisz kwotę przelewu",
  "title124": "Wpisz nazwę użytkownika",
  "title125": "Aby dokonać wpłaty, użyj adresu portfela lub giełdy. Po zakończeniu wpłaty kwota zostanie automatycznie dodana do Twojego konta.",
  "title126": "QR kod do portfela",
  "title127": "Wstrzymane",
  "title128": "Wpisz numer telefonu",
  "title129": "Wpisz adres e-mail",
  "title130": "Wpisz hasło",
  "title131": "Wpisz hasło ponownie",
  "title132": "Hasła nie pasują",
  "title133": "Wpisz kod weryfikacyjny",
  "title134": "Wpisz kod weryfikacyjny ponownie",
  "title135": "Kody nie pasują",
  "title136": "Wpisz kod z obrazka",
  "title137": "Rejestracja nie powiodła się",
  "title138": "Wpisz kod logowania",
  "title139": "Zaloguj się",
  "title140": "Wpisz hasło ponownie",
  "title141": "Wpisz kod",
  "title142": "Kody logowania nie pasują",
  "title143": "Wyślij",
  'content1' : `Zespół USDT oficjalnie wszedł na platformę handlu aktywami cyfrowymi.
    Dzięki niezawodnemu i skalowalnemu projektowi technicznemu, wysokiej jakości usługom i większemu wsparciu, platforma handlu aktywami cyfrowymi USDT ma na celu zapewnienie swoim wyjątkowym użytkownikom wygodniejszych, bezpieczniejszych i bardziej wygodnych usług wymiany USDT oraz wysokiej jakości aktywów blockchain. Globalni klienci VIP otrzymują usługi depozytów, transferów, wypłat, zarządzania finansami i inwestycjami. VIP wyłącznie zarządzanie finansami, inwestycje i 24-godzinny serwis klienta.
    1. Platforma jest otwarta na rejestrację użytkowników. Każdy może zarejestrować swoje konto \n
    2. Nowo zarejestrowane konto ma poziom VIP 0 (przechowywanie wartości, transfer, wypłata itp.). Te usługi są bezpłatne i nie musisz aktualizować konta, aby je obsługiwać. Jeśli Twoje konto musi otrzymywać transfery od innych, musisz używać limitów płatności (w tym przypadku konta na poziomie 0 nie będą mogły otrzymywać płatności), a Twoje konto musi zostać zaktualizowane do poziomu VIP, aby korzystać z funkcji płatności; im wyższy poziom, tym inne limity dzienne i miesięczne będą się różnić, w zależności od potrzeb, dokonaj aktualizacji.
    3. Możemy również oferować różne usługi takie jak wymiana walut oraz pożyczki hipoteczne USDT dla klientów VIP.`,

'content2' : `     1. Kliknij przycisk VIP
    2. Wybierz poziom VIP, który chcesz kupić
    3. Zapłać depozyt VIP i dokonaj zakupu
    4. Wymagany depozyt na poziom VIP (możesz anulować w dowolnym momencie i otrzymać zwrot depozytu)
    Depozyt VIP1: 50 USDT (VIP1 to usługa doświadczenia dla nowicjuszy, ważna przez 1 miesiąc, depozyt nie może zostać zwrócony ani anulowany)
    Depozyt VIP2: 1000 USDT
    Depozyt VIP3: 3000 USDT
    Depozyt VIP4: 30000 USDT
    Depozyt VIP5: 50000 USDT
    Depozyt VIP6: 100000 USDT
    Nowo zarejestrowane konto ma poziom VIP 0 (przechowywanie wartości, transfer, wypłata). Te usługi są bezpłatne i nie musisz aktualizować konta, aby je obsługiwać. Jeśli Twoje konto musi otrzymywać transfery od innych, musisz używać limitów płatności (w tym przypadku konta na poziomie 0 nie będą mogły otrzymywać płatności), a Twoje konto musi zostać zaktualizowane do poziomu VIP, aby korzystać z funkcji płatności; im wyższy poziom, tym inne limity dzienne i miesięczne będą się różnić, w zależności od potrzeb, dokonaj aktualizacji.
    VIP1: Limit płatności 10 USDT dziennie i 30 USDT miesięcznie
    VIP2: Limit płatności 100 USDT dziennie i 3000 USDT miesięcznie
    VIP3: Limit płatności 30 000 USDT dziennie i 900 000 USDT miesięcznie
    VIP4: Limit płatności 100 000 USDT dziennie i 3 000 000 USDT miesięcznie
    VIP5: Limit płatności 200 000 USDT dziennie i 6 000 000 USDT miesięcznie
    VIP6: Limit płatności 2 000 000 USDT dziennie i 60 000 000 USDT miesięcznie`,

'content3' : `Uwaga: Klienci VIP mogą korzystać z funkcji transferu platformy. Transfery mogą być dokonywane tylko między VIP-ami platformy (udane transfery nie mogą być zwrócone).
    1. Kliknij przycisk transferu
    2. Poprawnie wypełnij nazwę użytkownika VIP, adres e-mail i numer telefonu komórkowego
    3. Kliknij Potwierdź, aby zakończyć transfer (nie trzeba wprowadzać klucza)
    4. Jeśli Twoje konto musi otrzymywać transfery od innych, musisz używać limitów płatności
    VIP1: Limit płatności 10 USDT dziennie i 30 USDT miesięcznie
    VIP2: Limit płatności 100 USDT dziennie i 3000 USDT miesięcznie
    VIP3: Limit płatności 30 000 USDT dziennie i 900 000 USDT miesięcznie
    VIP4: Limit płatności 100 000 USDT dziennie i 3 000 000 USDT miesięcznie
    VIP5: Limit płatności 200 000 USDT dziennie i 6 000 000 USDT miesięcznie
    VIP6: Limit płatności 2 000 000 USDT dziennie i 60 000 000 USDT miesięcznie
    Przykład limitu transferu: Jeśli Twoje konto to VIP5
    VIP5 transfer do VIP1 (10 USDT)
    VIP5 transfer do VIP2 (100 USDT)
    VIP5 transfer do VIP3 (30 000 USDT)
    VIP5 transfer do VIP4 (100 000 USDT)
    VIP5 transfer do VIP5 (200 000 USDT)
    VIP6 transfer do VIP6 (2 000 000 USDT)`,

'content4' : `      1. Kliknij przycisk doładowania
    2. Wybierz kwotę do przechowania
    3. Kliknij Potwierdź. Wprowadź (KLUCZ)
    4. Otrzymasz unikalny adres przechowywania dla swojego konta, z którego możesz dokonać transferu z dowolnej giełdy lub portfela.
    5. Kwota przechowywana zostanie automatycznie dodana do salda konta`,

'content5' : `      1. Kliknij przycisk Wypłać
    2. Wprowadź adres wypłaty USDT (TRC20)
    3. Wprowadź kwotę wypłaty
    4. Kliknij przycisk Potwierdź i wprowadź KLUCZ
    5. Wypłata została zakończona i dotrze na Twoje konto w ciągu 2 godzin.`,

'content6' : `      1. KLUCZ jest ustawiany przez Ciebie i nie może być zdobyty przez innych. KLUCZ jest unikalny i nie może być zresetowany.
    2. KLUCZ jest jedynym dowodem Twojego konta. Nie można go modyfikować ani odzyskać.
    3. Jeśli zapomnisz KLUCZ, a Twoje konto wciąż ma środki do wypłaty, możesz ponownie zarejestrować nowe konto i zaktualizować VIP, a następnie przenieść środki ze starego konta na nowe konto w celu wypłaty. Transfery na platformie VIP nie wymagają KLUCZA.
    KLUCZ jest jedynym dowodem Twojego konta i nie może być modyfikowany ani odzyskany. Aby chronić prywatność klientów, platforma nie będzie rejestrować numeru konta, hasła, numeru telefonu komórkowego, adresu e-mail ani KLUCZA. Proszę przechowywać swoje dane logowania w bezpieczny sposób`,

'content7' : `      1. Kliknij na moją stronę główną
    2. Kliknij przycisk Centrum bezpieczeństwa
    3. Wybierz hasło lub e-mail, który chcesz zmienić
    4. Wprowadź KLUCZ, aby zakończyć`,

'content8' : `(Uwaga: podczas rejestracji proszę nie otwierać oprogramowania do tłumaczenia. Jeśli otworzysz oprogramowanie do tłumaczenia, przycisk nie zostanie potwierdzony. Jeśli nie możesz się zarejestrować, proszę zmienić przeglądarkę)
    1. Kliknij przycisk rejestracji na stronie głównej
    2. Kliknij rejestrację
    3. Wypełnij swoje dane rejestracyjne
    4. Proszę odpowiednio przechowywać KLUCZ. Nie może być zmieniony ani odzyskany (zgubienie może spowodować straty majątkowe)`,

'content9' : `      Przechowuj bezpiecznie i zarabiaj łatwo
    1. Minimalna kwota przechowywana: 5000 USDT (środki na koncie poniżej 5000 nie mogą korzystać z usług finansowych)
    2: Twoje aktywa otrzymają dochód zgodnie z różnymi poziomami, a całkowity dochód na każdym poziomie jest sumowany.
    3: Oczekiwany roczny dochód będzie dostosowywany w zależności od rzeczywistego dochodu puli wydobywczej.
    4: Dochód będzie obliczany po jednym dniu, kiedy PoS zostanie uruchomiony.
    5: Nie trzeba zamrażać aktywów hipotecznych, możesz handlować lub wypłacać monety w dowolnym momencie.
    Przedstawia datę obietnicy. Dochody rozpoczną się od T+1 (następnego dnia) i zostaną rozdzielone na Twoje konto w T+2.
    Brak dochodu w dniu, w którym zwolnisz swoje aktywa zabezpieczające. Dochody będą rozdzielane codziennie na Twoje konto spot. Obecne zarządzanie finansami można anulować w dowolnym momencie, a dochody zostaną rozliczone do dnia przed wyjściem z PoS.
    W przypadku innych produktów, gdy minimalny okres przechowywania zostanie osiągnięty, możesz wybrać wykupienie lub kontynuowanie przechowywania. Ostateczny dochód będzie obliczany na podstawie rzeczywistej liczby dni przechowywania, a główny kapitał zostanie odblokowany w dniu wykupu.
    Wśród oferowanych produktów finansowych możesz wybierać spośród dziesiątek cyfrowych aktywów (takich jak Bitcoin, Ethereum) oraz stablecoinów.`,

'content10' : `      1. Dziękujemy ponownie za zostanie naszym członkiem, zapewniamy pełne wsparcie i lepszą obsługę!
    2. Otrzymasz usługi przelewów i odbiorów
    3. Korzystaj z ekskluzywnych stawek VIP i korzystnych kosztów transakcji.
    4. Otrzymasz VIP-owy medal, który pokaże Twoją tożsamość i status.
    5. Dołączysz do naszej ekskluzywnej grupy VIP i będziesz mieć dostęp do lepszej obsługi klienta.
    6. Otrzymasz regularne raporty branżowe, aby śledzić trendy rynkowe i szanse inwestycyjne.
    7. Korzystaj z ekskluzywnych benefitów świątecznych i specjalnych upominków, aby poczuć nasze troski.
    8. Będziesz mieć możliwość uczestniczenia w ekskluzywnych wydarzeniach VIP i poznawania innych sukcesów inwestycyjnych.
    9. Skorzystaj z 24-godzinnego kanału obsługi klienta VIP i uzyskaj szybsze rozwiązania i wsparcie.
    10. Otrzymasz niezależny zielony kanał i będziesz mógł korzystać z wygodnych usług depozytów i wypłat.`,


},
"pt":{
  "menu_1": "Página Inicial",
  "menu_2": "Loja",
  "menu_3": "Minha Conta",
  "title1": "Moeda",
  "title2": "Última atualização",
  "title3": "Parceiros",
  "title4": "Idioma",
  "title5": "Página Inicial",
  "welcome": "Bem-vindo",
  "hello": "Olá",
  "title6": "Saldo",
  "title7": "Limite diário",
  "title8": "Limite mensal",
  "title9": "Depósito",
  "title10": "Retirada",
  "title11": "Transferência interna",
  "title12": "Renovação de adesão",
  "title13": "Verificação anual",
  "title14": "Período de investimento",
  "title15": "Investimento inicial",
  "title16": "Cadastre-se agora",
  "title17": "Registre-se na plataforma",
  "title18": "Retirar",
  "title19": "Depositar",
  "title20": "Minha Conta",
  "title21": "Meus Ativos",
  "title22": "Depósito",
  "title23": "Retirada",
  "title24": "Transferência",
  "title25": "Membro VIP",
  "title26": "Notificações",
  "title27": "Compra rápida",
  "title28": "Central de Suporte",
  "title29": "Sair",
  "title30": "Configurações",
  "title31": "Central de Segurança",
  "title32": "Sobre nós",
  "title33": "Verificação de e-mail",
  "title34": "Alterar senha",
  "title35": "Alterar e-mail",
  "context1": "Este site é uma plataforma DeFi segura e de alta qualidade, permitindo que os usuários depositem e retirem fundos livremente, sem preocupações com privacidade e processos KYC. Os produtos financeiros DeFi oferecem altos lucros e estabilidade, proporcionando acesso fácil e rápido às finanças.",
  "context2": "(Aplicativos financeiros descentralizados)",
  "context3": "DeFi (Finanças Descentralizadas) são aplicativos financeiros baseados na tecnologia blockchain que criam um sistema financeiro descentralizado, transparente e aberto.",
  "context4": "DeFi usa software de código aberto e uma rede descentralizada para revolucionar os produtos financeiros tradicionais, oferecendo protocolos transparentes e confiáveis sem intermediários. Esses sistemas funcionam sem administradores centrais, permitindo que qualquer usuário tenha acesso igual à plataforma. Se uma mudança for necessária, todos os usuários devem concordar com ela. A característica principal do DeFi é a propriedade descentralizada e a dificuldade em falsificar dados. DeFi aproveita as vantagens da tecnologia blockchain, como transparência, resistência à censura e imutabilidade.",
  "context5": "As vantagens do DeFi incluem uma infraestrutura confiável, serviços financeiros sem a necessidade de aprovação governamental e gestão de fundos pelos próprios usuários. A maior vantagem do DeFi é a eliminação de intermediários centrais, permitindo que os serviços financeiros sejam fornecidos de forma transparente e confiável.",
  "context6": "A diferença entre DeFi e FinTech está no mecanismo de confiança. FinTech utiliza a tecnologia blockchain dentro do sistema financeiro existente, mas ainda depende de órgãos centrais, e todas as transações são controladas por intermediários. Já o DeFi baseia-se em uma estrutura descentralizada, onde as operações são realizadas automaticamente e de forma imutável na blockchain por meio de contratos inteligentes, garantindo total transparência e confiança no sistema.",
  "context7": "Os aplicativos DeFi incluem exchanges descentralizadas, stablecoins, empréstimos, derivados, fundos, mercados de previsão, tokenização, sistemas KYC/AML e outros.",
  "title36": "Depósito",
  "title37": "Rede",
  "title38": "Quantia",
  "title39": "Depósito",
  "title40": "Para fazer um depósito, use o endereço da carteira ou exchange. Após a conclusão do depósito, a quantia será automaticamente adicionada à sua conta.",
  "title41": "Retirada",
  "title42": "Endereço da carteira",
  "title43": "Rede",
  "title44": "Quantia",
  "title45": "Saldo",
  "title46": "Dica de segurança: Antes de retirar, verifique se o endereço está correto. Após a transação ser concluída, não há possibilidade de reembolso.",
  "title47": "Retirada",
  "title48": "Digite o endereço da carteira",
  "title49": "5-1000000",
  "title50": "Transferência interna",
  "title51": "Conta",
  "title52": "Membros VIP podem transferir com um clique, sem necessidade de código.",
  "title53": "O destinatário deve ser pelo menos no nível VIP1.",
  "title54": "Taxa de serviço",
  "title55": "Transferência interna",
  "title56": "Compra",
  "title57": "Depósito",
  "title58": "Regras do nível",
  "title59": "Depósito",
  "title60": "Limite de lucro diário",
  "title61": "Limite de lucro mensal",
  "title62": "Os usuários precisam depositar para atualizar a adesão VIP. VIP1 é válido por 1 mês, após o qual será automaticamente cancelado. Membros VIP2 e superiores podem entrar em contato com o suporte técnico e após 2 meses de uso, podem solicitar reembolso após o depósito. Para mais informações, entre em contato com o suporte técnico.",
  "title63": "Relatório",
  "title64": "Renovação de adesão",
  "title65": "VIP1 exige esperar 30 dias após a ativação antes de ser reembolsado (pedidos de reembolso não são permitidos).",
  "title66": "VIP2-VIP6 podem entrar em contato com o suporte técnico para solicitar reembolso. Após o reembolso bem-sucedido, a quantia depositada será retornada à conta e o nível VIP será cancelado.",
  "title67": "Notificações",
  "title68": "Olá! Bem-vindo à plataforma!",
  "title69": "Conta",
  "title70": "Senha",
  "title71": "Digite o nome de usuário ou e-mail",
  "title72": "Digite a senha",
  "title73": "Digite o código de verificação",
  "title74": "Entrar",
  "title75": "Não tem uma conta?",
  "title76": "Cadastre-se",
  "title77": "Redefinir senha",
  "title78": "Senha",
  "title79": "Senha",
  "title80": "Confirmar senha",
  "title81": "Código",
  "title82": "Digite o código",
  "title83": "Digite a senha novamente",
  "title84": "Digite a senha",
  "title85": "Confirmar",
  "title86": "Criar conta",
  "title87": "Nome de usuário",
  "title88": "Digite o nome de usuário",
  "title89": "E-mail",
  "title90": "Digite o e-mail",
  "title91": "País",
  "title92": "Número de telefone",
  "title93": "Digite o número de telefone",
  "title94": "Cadastrar",
  "title95": "Entrar",
  "title96": "Já tem uma conta?",
  "title97": "Compra rápida",
  "title98": "Compre Tether (USDT) com cartão de crédito ou débito através da Paxful",
  "title99": "Compre Tether (USDT) com cartão de crédito ou débito através da ChangeNow",
  "title100": "Compre Tether (USDT) com cartão de crédito ou débito através da Changelly",
  "title101": "Compre Tether (USDT) com cartão de crédito ou débito através da Binance",
  "title102": "Compre Tether (USDT) com cartão de crédito ou débito através da Bybit",
  "title103": "Compre Tether (USDT) com cartão de crédito ou débito através da OKX",
  "title104": "Central de Suporte",
  "title105": "1. Guia da plataforma",
  "title106": "2. Como ativar VIP",
  "title107": "3. Transferência interna VIP (sem código)",
  "title108": "4. Como fazer um depósito",
  "title109": "5. Como fazer uma retirada",
  "title110": "6. O que fazer se esquecer a senha",
  "title111": "7. Como mudar a senha ou e-mail",
  "title112": "8. Como se registrar",
  "title113": "9. Como investir",
  "title114": "10. Serviços de investimento VIP",
  "title115": "Enviar",
  "title116": "Digite o código de verificação",
  "title117": "Digite o endereço da carteira",
  "title118": "Digite o valor da retirada",
  "title119": "Para fazer um depósito, use o endereço da carteira ou exchange. Após a conclusão do depósito, a quantia será automaticamente adicionada à sua conta.",
  "title120": "Enviar",
  "title121": "O valor mínimo de depósito é 50",
  "title122": "Pedido de reembolso",
  "title123": "Digite o valor da transferência",
  "title124": "Digite o nome de usuário",
  "title125": "Para fazer um depósito, use o endereço da carteira ou exchange. Após a conclusão do depósito, a quantia será automaticamente adicionada à sua conta.",
  "title126": "Código QR da carteira",
  "title127": "Suspenso",
  "title128": "Digite o número de telefone",
  "title129": "Digite o e-mail",
  "title130": "Digite a senha",
  "title131": "Digite a senha novamente",
  "title132": "As senhas não correspondem",
  "title133": "Digite o código de verificação",
  "title134": "Digite o código de verificação novamente",
  "title135": "Os códigos não correspondem",
  "title136": "Digite o código da imagem",
  "title137": "Registro falhou",
  "title138": "Digite o código de login",
  "title139": "Entrar",
  "title140": "Digite a senha novamente",
  "title141": "Digite o código",
  "title142": "Os códigos de login não correspondem",
  "title143": "Enviar",
  'content1' : `A equipe USDT entrou oficialmente na plataforma de negociação de ativos digitais.
    Com um projeto técnico confiável e escalável, serviços de alta qualidade e maior suporte, a plataforma de negociação de ativos digitais USDT visa fornecer aos seus usuários excepcionais serviços de troca de USDT mais convenientes, seguros e confortáveis, bem como ativos blockchain de alta qualidade. Clientes VIP globais recebem serviços de depósito, transferência, retirada, gerenciamento financeiro e investimentos. Gerenciamento financeiro exclusivo VIP, investimentos e serviço de atendimento ao cliente 24 horas por dia.
    1. A plataforma está aberta para registro de usuários. Qualquer pessoa pode registrar sua conta \n
    2. A conta recém-registrada tem o nível VIP 0 (armazenamento de valor, transferência, retirada etc.). Esses serviços são gratuitos e você não precisa atualizar sua conta para operá-los. Se sua conta precisar receber transferências de outros, você precisará usar limites de pagamento (nesse caso, contas no nível 0 não poderão receber pagamentos), e sua conta precisará ser atualizada para VIP para usar a função de pagamento; quanto maior o nível, maiores os limites diários e mensais, dependendo das necessidades, faça a atualização.
    3. Também podemos oferecer vários serviços, como câmbio de moedas e empréstimos hipotecários USDT para clientes VIP.`,

'content2' : `     1. Clique no botão VIP
    2. Escolha o nível VIP que você deseja comprar
    3. Pague o depósito VIP e faça a compra
    4. Depósito necessário para nível VIP (você pode cancelar a qualquer momento e receber o reembolso do depósito)
    Depósito VIP1: 50 USDT (VIP1 é um serviço de experiência para novatos, válido por 1 mês, o depósito não pode ser reembolsado ou cancelado)
    Depósito VIP2: 1000 USDT
    Depósito VIP3: 3000 USDT
    Depósito VIP4: 30000 USDT
    Depósito VIP5: 50000 USDT
    Depósito VIP6: 100000 USDT
    A conta recém-registrada tem o nível VIP 0 (armazenamento de valor, transferência, retirada). Esses serviços são gratuitos e você não precisa atualizar sua conta para operá-los. Se sua conta precisar receber transferências de outros, você precisará usar limites de pagamento (nesse caso, contas no nível 0 não poderão receber pagamentos), e sua conta precisará ser atualizada para VIP para usar a função de pagamento; quanto maior o nível, maiores os limites diários e mensais, dependendo das necessidades, faça a atualização.
    VIP1: Limite de pagamento de 10 USDT por dia e 30 USDT por mês
    VIP2: Limite de pagamento de 100 USDT por dia e 3000 USDT por mês
    VIP3: Limite de pagamento de 30.000 USDT por dia e 900.000 USDT por mês
    VIP4: Limite de pagamento de 100.000 USDT por dia e 3.000.000 USDT por mês
    VIP5: Limite de pagamento de 200.000 USDT por dia e 6.000.000 USDT por mês
    VIP6: Limite de pagamento de 2.000.000 USDT por dia e 60.000.000 USDT por mês`,

'content3' : `Atenção: Clientes VIP podem usar a função de transferência da plataforma. As transferências só podem ser feitas entre VIPs da plataforma (as transferências bem-sucedidas não podem ser revertidas).
    1. Clique no botão de transferência
    2. Preencha corretamente o nome de usuário VIP, endereço de e-mail e número de telefone celular
    3. Clique em Confirmar para finalizar a transferência (não é necessário inserir chave)
    4. Se sua conta precisar receber transferências de outros, você precisará usar limites de pagamento
    VIP1: Limite de pagamento de 10 USDT por dia e 30 USDT por mês
    VIP2: Limite de pagamento de 100 USDT por dia e 3000 USDT por mês
    VIP3: Limite de pagamento de 30.000 USDT por dia e 900.000 USDT por mês
    VIP4: Limite de pagamento de 100.000 USDT por dia e 3.000.000 USDT por mês
    VIP5: Limite de pagamento de 200.000 USDT por dia e 6.000.000 USDT por mês
    VIP6: Limite de pagamento de 2.000.000 USDT por dia e 60.000.000 USDT por mês
    Exemplo de limite de transferência: Se sua conta for VIP5
    Transferência VIP5 para VIP1 (10 USDT)
    Transferência VIP5 para VIP2 (100 USDT)
    Transferência VIP5 para VIP3 (30.000 USDT)
    Transferência VIP5 para VIP4 (100.000 USDT)
    Transferência VIP5 para VIP5 (200.000 USDT)
    Transferência VIP6 para VIP6 (2.000.000 USDT)`,

'content4' : `      1. Clique no botão de recarga
    2. Escolha o valor para depósito
    3. Clique em Confirmar. Insira (CHAVE)
    4. Você receberá um endereço exclusivo de depósito para sua conta, a partir do qual poderá transferir de qualquer bolsa ou carteira.
    5. O valor depositado será automaticamente adicionado ao saldo da conta`,

'content5' : `      1. Clique no botão de Retirar
    2. Insira o endereço de retirada USDT (TRC20)
    3. Insira o valor da retirada
    4. Clique no botão Confirmar e insira a CHAVE
    5. A retirada será concluída e chegará à sua conta dentro de 2 horas.`,

'content6' : `      1. A CHAVE é configurada por você e não pode ser obtida por outros. A CHAVE é única e não pode ser redefinida.
    2. A CHAVE é a única prova da sua conta. Não pode ser modificada ou recuperada.
    3. Se você esquecer a CHAVE e sua conta ainda tiver fundos para retirada, você pode registrar uma nova conta e atualizar o VIP, depois transferir os fundos da conta antiga para a nova conta para retirada. As transferências na plataforma VIP não exigem CHAVE.
    A CHAVE é a única prova da sua conta e não pode ser modificada ou recuperada. Para proteger a privacidade dos clientes, a plataforma não registrará o número da conta, senha, número de telefone celular, endereço de e-mail ou CHAVE. Por favor, mantenha suas credenciais de login de forma segura`,

'content7' : `      1. Clique na minha página inicial
    2. Clique no botão Centro de segurança
    3. Selecione a senha ou e-mail que deseja alterar
    4. Insira a CHAVE para finalizar`,

'content8' : `(Atenção: durante o registro, por favor, não abra o software de tradução. Se você abrir o software de tradução, o botão não será confirmado. Se você não conseguir se registrar, tente mudar de navegador)
    1. Clique no botão de registro na página inicial
    2. Clique em Registrar
    3. Preencha seus dados de registro
    4. Por favor, armazene sua CHAVE de forma segura. Não pode ser alterada ou recuperada (perdê-la pode resultar em perda de fundos)`,

'content9' : `      Armazene com segurança e ganhe facilmente
    1. O valor mínimo depositado: 5000 USDT (fundos abaixo de 5000 não podem utilizar serviços financeiros)
    2: Seus ativos receberão rendimentos conforme diferentes níveis, e o rendimento total de cada nível será somado.
    3: O rendimento anual esperado será ajustado com base no rendimento real do pool de mineração.
    4: O rendimento será calculado após um dia, quando o PoS for iniciado.
    5: Não é necessário bloquear ativos hipotecados, você pode negociar ou retirar moedas a qualquer momento.
    A data da promessa é apresentada. Os rendimentos começarão a partir de T+1 (no dia seguinte) e serão distribuídos para sua conta em T+2.
    Não haverá rendimento no dia em que você liberar seus ativos de garantia. Os rendimentos serão distribuídos diariamente para sua conta spot. O gerenciamento financeiro atual pode ser cancelado a qualquer momento, e os rendimentos serão liquidados até o dia antes de sair do PoS.
    Para outros produtos, quando o período mínimo de armazenamento for alcançado, você poderá escolher resgatar ou continuar armazenando. O rendimento final será calculado com base no número real de dias de armazenamento, e o principal será desbloqueado no momento do resgate.
    Entre os produtos financeiros oferecidos, você pode escolher entre dezenas de ativos digitais (como Bitcoin, Ethereum) e stablecoins.`,

'content10' : `      1. Agradecemos novamente por se tornar nosso membro, garantimos total suporte e um serviço melhor!
    2. Você receberá serviços de transferências e recebimentos
    3. Aproveite as taxas exclusivas VIP e custos de transação vantajosos.
    4. Você receberá uma medalha VIP que mostrará sua identidade e status.
    5. Você se juntará ao nosso grupo exclusivo VIP e terá acesso a um melhor serviço de atendimento ao cliente.
    6. Você receberá relatórios regulares do setor para acompanhar as tendências de mercado e oportunidades de investimento.
    7. Aproveite os benefícios exclusivos de férias e presentes especiais para sentir nosso cuidado.
    8. Você terá a oportunidade de participar de eventos exclusivos VIP e conhecer outros investidores de sucesso.
    9. Aproveite o canal de atendimento ao cliente VIP 24 horas por dia e receba soluções e suporte mais rápidos.
    10. Você receberá um canal verde independente e poderá usufruir de serviços convenientes de depósitos e retiradas.`,


},
"ro":{
  "menu_1": "Acasă",
  "menu_2": "Tranzacționare",
  "menu_3": "Contul Meu",
  "title1": "Monedă",
  "title2": "Ultimul preț",
  "title3": "Parteneri",
  "title4": "Limbă",
  "title5": "Acasă",
  "welcome": "Bine ai venit",
  "hello": "Salut",
  "title6": "Sold disponibil",
  "title7": "Limită zilnică de primire",
  "title8": "Limită lunară de primire",
  "title9": "Depunere",
  "title10": "Retragere",
  "title11": "Transfer intern",
  "title12": "Upgrade membru",
  "title13": "Venit anualizat de referință",
  "title14": "Perioada investiției",
  "title15": "Cantitate minimă",
  "title16": "Participă acum",
  "title17": "Înregistrări platformă",
  "title18": "Retragere",
  "title19": "Reîncărcare",
  "title20": "Contul Meu",
  "title21": "Activele mele",
  "title22": "Depunere",
  "title23": "Retragere",
  "title24": "Transfer",
  "title25": "Vip",
  "title26": "Notificare Mesaje",
  "title27": "Cumpărare rapidă",
  "title28": "Centru de ajutor",
  "title29": "Ieșire",
  "title30": "Setări",
  "title31": "Centru de securitate",
  "title32": "Despre noi",
  "title33": "Autentificare prin e-mail",
  "title34": "Schimbă parola",
  "title35": "Modifică e-mailul",
  "context1": "Acest site este o platformă financiară DeFi descentralizată, cu un nivel ridicat de anonimat și securitate. Nu trebuie să îți faci griji cu privire la divulgarea informațiilor personale sau verificarea KYC. Utilizatorii pot stoca și retrage activele liber. Produsele financiare descentralizate disponibile pe acest site oferă randamente ridicate și stabilitate, cu servicii rapide și ușoare.",
  "context2": "(Despre aplicațiile financiare descentralizate DeFi)",
  "context3": "Finanțele descentralizate (DeFi) reprezintă una dintre aplicațiile tehnologiei blockchain în domeniul Fintech. DeFi creează un sistem financiar deschis și transparent prin platforme blockchain open-source și descentralizate.",
  "context4": "DeFi utilizează rețele descentralizate și software open-source pentru a transforma produsele financiare tradiționale în protocoale transparente și fără intermediere. Conceptul central al DeFi este descentralizarea, eliminând administratorii și acordând fiecărui utilizator aceleași permisiuni. Orice modificare necesită acordul tuturor utilizatorilor. Acest lucru face ca DeFi să fie deținute colectiv, greu de modificat și să ofere avantajele blockchain-ului, precum transparența, rezistența la cenzură și imuabilitatea.",
  "context5": "DeFi oferă numeroase beneficii, inclusiv o fundație fără încredere, lipsa nevoii de aprobare instituțională și controlul activelor de către utilizator. Principalul său avantaj este eliminarea 'terților de încredere' prin descentralizare, asigurând transparență și imuabilitate.",
  "context6": "Diferența principală dintre DeFi și Fintech este mecanismul de 'încredere'. Fintech folosește tehnologia blockchain în cadrul sistemului financiar centralizat, încă bazându-se pe instituții centralizate, în timp ce DeFi utilizează contracte inteligente pentru a asigura execuția automată și sigură a tranzacțiilor.",
  "context7": "Aplicațiile vizibile ale DeFi includ protocoale de tranzacționare descentralizate, stablecoins, împrumuturi, derivate, fonduri, piețe de predicții, protocoale de tokenizare și verificarea identității KYC/AML.",
  "title36": "Depunere",
  "title37": "Rețea",
  "title38": "Sumă",
  "title39": "Depunere",
  "title40": "Utilizați portofelul sau adresa de reîncărcare a schimbului. După finalizarea reîncărcării, suma va fi adăugată automat în contul dumneavoastră.",
  "title41": "Retragere",
  "title42": "Adresă portofel",
  "title43": "Rețea",
  "title44": "Sumă",
  "title45": "Sold disponibil",
  "title46": "Atenție: Vă rugăm să introduceți corect contul de retragere. Activele nu pot fi returnate după transfer.",
  "title47": "Retragere",
  "title48": "Introduceți adresa portofelului",
  "title49": "5-1000000",
  "title50": "Transfer intern",
  "title51": "Cont",
  "title52": "Membrii VIP pot folosi transferul rapid fără cheie",
  "title53": "Destinatarul trebuie să fie cel puțin VIP1",
  "title54": "Comision de serviciu",
  "title55": "Transfer intern",
  "title56": "Cumpărare",
  "title57": "Depunere",
  "title58": "Reguli de nivel",
  "title59": "Depunere",
  "title60": "Limită zilnică de colectare",
  "title61": "Limită lunară de colectare",
  "title62": "Utilizatorii trebuie să plătească un depozit pentru a folosi serviciile VIP. VIP1 este valabil timp de 1 lună. Se va anula automat la expirare.",
  "title63": "Înregistrare",
  "title64": "Upgrade membru",
  "title65": "VIP1 trebuie să aștepte 30 de zile pentru rambursarea automată (nu poate solicita rambursare).",
  "title66": "VIP2-VIP6 pot contacta serviciul clienți pentru rambursare.",
  "title67": "Notificare Mesaje",
  "title68": "Salut! Bine ai venit!",
  "title69": "Cont",
  "title70": "Parolă",
  "title71": "Introduceți numele de utilizator sau adresa de e-mail",
  "title72": "Introduceți parola",
  "title73": "Introduceți captcha",
  "title74": "Autentificare",
  "title75": "Nu ai cont?",
  "title76": "ÎNREGISTRARE",
  "title77": "RECUPERARE PAROLĂ",
  "title78": "Parolă",
  "title79": "Parolă",
  "title80": "Confirmă parola",
  "title81": "CHEIE",
  "title82": "Introduceți CHEIA",
  "title83": "Confirmați parola din nou",
  "title84": "Introduceți parola",
  "title85": "Confirmă",
  "title86": "Creează un cont",
  "title87": "Nume utilizator",
  "title88": "Introduceți numele de utilizator",
  "title89": "E-mail",
  "title90": "Introduceți adresa de e-mail",
  "title91": "Țară",
  "title92": "Mobil",
  "title93": "Introduceți numărul de telefon",
  "title94": "Înregistrare",
  "title95": "AUTENTIFICARE",
  "title96": "Ai deja cont?",
  "title97": "Cumpărare rapidă",
  "title98": "Cumpărați Tether (USDT) cu cardul de credit sau debit pe Paxful",
  "title99": "Cumpărați Tether (USDT) cu cardul de credit sau debit pe ChangeNow",
  "title100": "Cumpărați Tether (USDT) cu cardul de credit sau debit pe Changelly",
  "title101": "Cumpărați Tether (USDT) cu cardul de credit sau debit pe Binance Exchange",
  "title102": "Cumpărați Tether (USDT) cu cardul de credit sau debit pe Bybit Exchange",
  "title103": "Cumpărați Tether (USDT) cu cardul de credit sau debit pe OKX Exchange",
  "title104": "Centru de ajutor",
  "title105": "1. Instrucțiuni de utilizare a platformei",
  "title106": "2. Cum să activați VIP",
  "title107": "3. Transfer VIP (fără cheie necesară)",
  "title108": "4. Cum să reîncarci?",
  "title109": "5. Cum să retragi bani?",
  "title110": "6. Ce să faci dacă uiți CHEIA?",
  "title111": "7. Cum să schimbi parola, e-mail?",
  "title112": "8. Cum să înregistrezi un cont?",
  "title113": "9. Cum să investești?",
  "title114": "10. Servicii de investiții VIP",
  "title115": "Trimite",
  "title116": "Vă rugăm să introduceți parola cheii",
  "title117": "Vă rugăm să introduceți adresa portofelului",
  "title118": "Vă rugăm să introduceți suma de retragere",
  "title119": "Utilizați portofelul sau adresa de reîncărcare a schimbului. După finalizarea reîncărcării, suma va fi adăugată automat în contul dumneavoastră.",
  "title120": "Se trimite",
  "title121": "Suma minimă de reîncărcare nu poate fi mai mică de 50",
  "title122": "Solicită rambursare",
  "title123": "Vă rugăm să introduceți suma pe care doriți să o transferați",
  "title124": "Vă rugăm să introduceți numele de utilizator",
  "title125": "Utilizați portofelul sau adresa de reîncărcare a schimbului. După finalizarea reîncărcării, suma va fi adăugată automat în contul dumneavoastră.",
  "title126": "Cod QR portofel",
  "title127": "Așteptare",
  "title128": "Vă rugăm să introduceți numărul de telefon mobil",
  "title129": "Vă rugăm să introduceți adresa de e-mail",
  "title130": "Vă rugăm să introduceți o parolă",
  "title131": "Vă rugăm să introduceți parola din nou",
  "title132": "Parolele pe care le-ați introdus nu se potrivesc",
  "title133": "Vă rugăm să introduceți parola cheii",
  "title134": "Vă rugăm să introduceți din nou parola cheii",
  "title135": "Parola cheii pe care ați introdus-o nu se potrivește",
  "title136": "Vă rugăm să introduceți codul de verificare a imaginii",
  "title137": "Înregistrare eșuată",
  "title138": "Vă rugăm să introduceți parola de autentificare",
  "title139": "Autentificare în curs",
  "title140": "Vă rugăm să introduceți parola din nou",
  "title141": "Vă rugăm să introduceți parola cheii",
  "title142": "Parolele pe care le-ați introdus nu se potrivesc",
  "title143": "Trimitere",
  content1: `Echipa USDT a intrat oficial pe platforma de servicii de tranzacționare a activelor digitale.
Cu un design tehnic fiabil și scalabil, servicii de înaltă calitate și mai mult suport, platforma de tranzacționare a activelor digitale USDT își propune să ofere utilizatorilor săi speciali servicii de tranzacționare USDT mai convenabile, mai sigure și mai rapide și active blockchain de înaltă calitate. Clienții VIP globali vor beneficia de servicii de depozite, transferuri, retrageri, management financiar și investiții. Management financiar exclusiv VIP, investiții și suport pentru clienți 24 de ore din 24.
1. Platforma este deschisă pentru înregistrare. Oricine poate înregistra propriul cont.
2. Nivelul VIP al unui cont nou înregistrat este 0 (pentru depozite, transferuri, retrageri etc.). Acestea sunt gratuite și nu trebuie să îți actualizezi contul pentru a le utiliza. Dacă contul tău trebuie să primească transferuri de la alții, va trebui să utilizezi limitele de plată (în acest moment, conturile de nivel 0 nu vor putea primi plăți), iar contul trebuie să fie actualizat la nivel VIP pentru a utiliza funcția de plată; cu cât nivelul este mai mare, cu atât alocațiile zilnice și lunare vor fi diferite. Te rugăm să actualizezi contul în funcție de nevoi.
3. De asemenea, putem oferi diverse servicii, cum ar fi schimbul de monede și împrumuturi cu ipotecă USDT pentru clienții VIP.`,

content2: `1. Apasă pe butonul VIP
2. Selectează nivelul VIP pe care dorești să-l achiziționezi
3. Plătește depozitul VIP și achiziționează-l cu succes
4. Depozitul necesar pentru nivelul VIP (poți anula în orice moment și vei primi înapoi depozitul)
Depozit VIP1: 50 USDT (VIP1 este un serviciu de experiență pentru începători, valabil timp de 1 lună, depozitul nu poate fi rambursat și nu poate fi anulat)
Depozit VIP2: 1000 USDT
Depozit VIP3: 3000 USDT
Depozit VIP4: 30000 USDT
Depozit VIP5: 50000 USDT
Depozit VIP6: 100000 USDT
Nivelul VIP al unui cont nou înregistrat este 0 (pentru depozite, transferuri, retrageri etc.). Acestea sunt gratuite și nu trebuie să îți actualizezi contul pentru a le utiliza. Dacă contul tău trebuie să primească transferuri de la alții, va trebui să utilizezi limitele de plată (în acest moment, conturile de nivel 0 nu vor putea primi plăți), iar contul trebuie să fie actualizat la nivel VIP pentru a utiliza funcția de plată; cu cât nivelul este mai mare, cu atât alocațiile zilnice și lunare vor fi diferite. Te rugăm să actualizezi contul în funcție de nevoi.
VIP1: Limita de plată 10 USDT pe zi și 30 USDT pe lună
VIP2: Limita de plată 100 USDT pe zi și 3000 USDT pe lună
VIP3: Limita de plată 30000 USDT pe zi și 900000 USDT pe lună
VIP4: Limita de plată 100000 USDT pe zi și 3000000 USDT pe lună
VIP5: Limita de plată 200000 USDT pe zi și 6000000 USDT pe lună
VIP6: Limita de plată 2000000 USDT pe zi și 60000000 USDT pe lună`,

content3: `Notă: VIP-urile pot utiliza funcția de transfer a platformei. Transferurile se pot face doar între VIP-uri ale platformei (transferurile reușite nu pot fi returnate)
1. Apasă pe butonul de transfer
2. Completează corect numele de utilizator VIP, adresa de e-mail și numărul de telefon mobil
3. Apasă pe Confirmă pentru a finaliza transferul (nu este necesar să introduci KEY)
4. Dacă contul tău trebuie să primească transferuri de la alții, va trebui să utilizezi limitele de plată
VIP1: Limita de plată 10 USDT pe zi și 30 USDT pe lună
VIP2: Limita de plată 100 USDT pe zi și 3000 USDT pe lună
VIP3: Limita de plată 30000 USDT pe zi și 900000 USDT pe lună
VIP4: Limita de plată 100000 USDT pe zi și 3000000 USDT pe lună
VIP5: Limita de plată 200000 USDT pe zi și 6000000 USDT pe lună
VIP6: Limita de plată 2000000 USDT pe zi și 60000000 USDT pe lună
Exemplu de limită de transfer: Dacă contul tău este VIP5
VIP5 transfer către VIP1 (10USDT)
VIP5 transfer către VIP2 (100USDT)
VIP5 transfer către VIP3 (30000USDT)
VIP5 transfer către VIP4 (100000USDT)
VIP5 transfer către VIP5 (200000USDT)
VIP6 transfer către VIP6 (2000000USDT)`,

content4: `1. Apasă pe butonul de încărcare
2. Selectează suma pe care dorești să o depui
3. Apasă pe Confirmă și introduce (KEY)
4. Vei primi o adresă exclusivă de depozit pentru contul tău, din care poți transfera din orice schimb sau portofel.
5. Suma ta depusă va fi adăugată automat la soldul contului tău`,

content5: `1. Apasă pe butonul de retragere
2. Introdu adresa de retragere USDT (TRC20)
3. Introdu suma de retragere
4. Apasă pe butonul Confirmă și introduce KEY
5. Retragerea a fost realizată cu succes, iar retragerea va ajunge în contul tău în termen de 2 ore.`,

content6: `1. KEY-ul este setat de tine și nu poate fi obținut de altcineva. KEY-ul este unic și nu poate fi resetat.
2. KEY-ul este singura dovadă a contului tău și nu poate fi modificat. Nu poate fi recuperat
3. Dacă uiți KEY-ul și contul tău are fonduri, poți înregistra un cont nou și să îți upgradezi VIP-ul, apoi să transferi fondurile din vechiul cont în contul nou pentru a le retrage. Transferurile interne între VIP-uri nu necesită KEY
KEY-ul este singurul tău acreditiv pentru contul tău și nu poate fi modificat sau recuperat. Pentru a proteja confidențialitatea clientului, platforma nu va înregistra numărul de cont al utilizatorului, parola, numărul de telefon mobil, adresa de e-mail sau KEY-ul. Te rugăm să îți păstrezi informațiile de autentificare în siguranță`,

content7: `1. Apasă pe pagina principală
2. Apasă pe butonul Centrul de Securitate
3. Selectează parola sau adresa de e-mail care trebuie schimbată
4. Introdu KEY-ul pentru a finaliza`,

content8: `(Notă: Când te înregistrezi, te rugăm să nu deschizi software-ul de traducere. Dacă deschizi software-ul de traducere, butonul nu va fi confirmat și nu te vei putea înregistra. Te rugăm să schimbi browser-ul)
1. Apasă pe butonul de înregistrare de pe pagina principală
2. Apasă pe înregistrare
3. Completează informațiile de înregistrare
4. Te rugăm să păstrezi cu grijă KEY-ul atunci când îl setezi, deoarece nu poate fi modificat sau recuperat (pierderile pot duce la daune financiare)`,

content9: `Depozitează în siguranță și câștigă ușor
1. Suma minimă de depozit: 5000 USDT (conturile cu fonduri sub 5000 nu pot accesa serviciile financiare)
2. Activele tale vor genera venituri în funcție de diferitele nivele, iar venitul total pentru fiecare nivel va fi adunat.
3. Venitul anualizat estimat va fi ajustat oricând în funcție de venitul real din piscina de minerit.
4. Venitul va fi calculat în prima zi după deschiderea PoS.
5. Nu este necesar să îngheți activele ipotecate, poți tranzacționa sau retrage monedele în orice moment.
Reprezintă data de garanție. Veniturile vor începe în T+1 (a doua zi) și vor fi distribuite în contul tău în T+2.
Nu vor exista venituri în ziua în care vei elibera activele ipotecate. Veniturile vor fi distribuite zilnic în contul tău spot. Serviciul financiar actual poate fi anulat în orice moment și veniturile vor fi stabilite până în ziua anterioară ieșirii din PoS.
Pentru alte produse, când perioada minimă de depozit este atinsă, poți alege să răscumperi sau să continui depozitul. Venitul final va fi calculat pe baza numărului real de zile de depozit, iar principalul va fi deblocat în ziua de răscumpărare.
Printre produsele financiare oferite, poți alege dintre zeci de active digitale (precum Bitcoin, Ethereum) și monede stabile.`,

content10: `1. Îți mulțumim din nou pentru că ai devenit membru al nostru, vom oferi întotdeauna servicii și suport mai bune!
2. Vei beneficia de servicii de transfer și colectare
3. Vei avea rate exclusive VIP și costuri de tranzacție mai avantajoase.
4. Vei primi un insigne VIP exclusiv, care îți va arăta identitatea și statutul.
5. Vei fi adăugat la grupul nostru exclusiv de servicii VIP și vei primi mai mult suport din partea clienților noștri.
6. Vei primi rapoarte periodice despre industrie pentru a înțelege dinamica pieței și oportunitățile de investiții.
7. Vei beneficia de avantaje exclusive de sărbători și privilegii personalizate cu suveniruri, pentru a simți grijă din partea noastră.
8. Vei avea ocazia să participi la activități exclusive VIP și să cunoști alți investitori de succes.
9. Vei avea un canal exclusiv de servicii VIP 24 de ore și vei primi soluții rapide și prioritate.
10. Vei beneficia de un canal verde independent și vei putea efectua depozite și retrageri mai ușor.` 

},
ru:{
  "menu_1": "Главная",
  "menu_2": "Торговля",
  "menu_3": "Мой аккаунт",
  "title1": "Валюта",
  "title2": "Последняя цена",
  "title3": "Партнеры",
  "title4": "Язык",
  "title5": "Главная",
  "welcome": "Добро пожаловать",
  "hello": "Привет",
  "title6": "Доступный баланс",
  "title7": "Ежедневный лимит получения",
  "title8": "Ежемесячный лимит получения",
  "title9": "Депозит",
  "title10": "Вывод",
  "title11": "Внутренний перевод",
  "title12": "Обновление членства",
  "title13": "Годовой доход по реферальной программе",
  "title14": "Период инвестирования",
  "title15": "Начальная сумма",
  "title16": "Присоединяйтесь сейчас",
  "title17": "Записи платформы",
  "title18": "Вывод",
  "title19": "Пополнение",
  "title20": "Мой аккаунт",
  "title21": "Мои активы",
  "title22": "Депозит",
  "title23": "Вывод",
  "title24": "Перевод",
  "title25": "VIP",
  "title26": "Уведомления",
  "title27": "Быстрая покупка",
  "title28": "Центр помощи",
  "title29": "Выход",
  "title30": "Настройки",
  "title31": "Центр безопасности",
  "title32": "О нас",
  "title33": "Аутентификация по email",
  "title34": "Изменить пароль",
  "title35": "Изменить email",
  "context1": "Этот сайт является децентрализованной финансовой платформой DeFi с высокой анонимностью и безопасностью. Вам не нужно беспокоиться о утечке личных данных или прохождении KYC. Пользователи могут свободно хранить и выводить свои активы. Децентрализованные финансовые продукты на этом сайте предлагают высокие доходности и стабильность, с быстрыми и простыми сервисами.",
  "context2": "(О децентрализованных финансовых приложениях DeFi)",
  "context3": "Децентрализованные финансы (DeFi) — это одно из применений технологии блокчейн в финтехе. DeFi создает открытую и прозрачную финансовую систему через открытые и децентрализованные блокчейн-платформы.",
  "context4": "DeFi использует децентрализованные сети и открытое программное обеспечение для преобразования традиционных финансовых продуктов в прозрачные и безопасные протоколы без лишних посредников. Основная идея DeFi заключается в «децентрализации», что означает отсутствие администратора, а все пользователи имеют одинаковые права. Любые изменения должны быть одобрены всеми участниками, что делает DeFi коллективной собственностью, трудной для изменений и дает преимущества блокчейна, такие как прозрачность, сопротивление цензуре и неизменность.",
  "context5": "DeFi предлагает множество преимуществ, включая основу без доверия, отсутствие необходимости в институциональном разрешении и контроль активов на стороне пользователя. Главным его преимуществом является исключение «доверенных третьих сторон» через децентрализацию, сохраняя прозрачность и неизменность реестра.",
  "context6": "Основное отличие между DeFi и Fintech заключается в механизме «доверия». Fintech использует блокчейн-технологии в рамках существующей финансовой системы, все еще опираясь на централизованные учреждения, в то время как DeFi использует умные контракты, их код является открытым, а основная логика выполняется на блокчейне, что делает систему неизменной и обязательной для исполнения.",
  "context7": "К видимым приложениям DeFi относятся децентрализованные торговые протоколы, стейблкоины, кредиты, деривативы, фонды, рынки предсказаний, протоколы токенизации, а также проверка идентификации KYC/AML.",
  "title36": "Депозит",
  "title37": "Сеть",
  "title38": "Сумма",
  "title39": "Депозит",
  "title40": "Используйте адрес для пополнения через кошелек или обмен. После завершения пополнения сумма будет автоматически добавлена на ваш счет.",
  "title41": "Вывод",
  "title42": "Адрес кошелька",
  "title43": "Сеть",
  "title44": "Сумма",
  "title45": "Доступный баланс",
  "title46": "Совет по безопасности: Пожалуйста, правильно укажите адрес для вывода, активы не могут быть возвращены после перевода.",
  "title47": "Вывод",
  "title48": "Введите адрес кошелька",
  "title49": "5-1000000",
  "title50": "Внутренний перевод",
  "title51": "Аккаунт",
  "title52": "Пользователи VIP могут использовать одноразовый перевод без ключа",
  "title53": "Получатель должен быть хотя бы VIP1",
  "title54": "Комиссия за обслуживание",
  "title55": "Внутренний перевод",
  "title56": "Покупка",
  "title57": "Депозит",
  "title58": "Правила уровня",
  "title59": "Депозит",
  "title60": "Ежедневный лимит получения",
  "title61": "Ежемесячный лимит получения",
  "title62": "Пользователи должны внести депозит для использования услуг VIP. VIP1 действителен 1 месяц, после чего автоматически отменяется.",
  "title63": "Запись",
  "title64": "Обновление членства",
  "title65": "VIP1 требует ожидания 30 дней для автоматического возврата (нельзя подать заявку на возврат).",
  "title66": "VIP2-VIP6 могут обратиться в службу поддержки для подачи заявки на возврат. При успешном возврате депозит будет немедленно возвращен на баланс, и уровень VIP будет отменен.",
  "title67": "Уведомления",
  "title68": "Привет! Добро пожаловать!",
  "title69": "Аккаунт",
  "title70": "Пароль",
  "title71": "Введите имя пользователя или адрес электронной почты",
  "title72": "Введите пароль",
  "title73": "Введите капчу",
  "title74": "Войти",
  "title75": "Нет аккаунта?",
  "title76": "ЗАРЕГИСТРИРОВАТЬСЯ",
  "title77": "Восстановить пароль",
  "title78": "Пароль",
  "title79": "Пароль",
  "title80": "Подтвердите пароль",
  "title81": "КЛЮЧ",
  "title82": "Введите КЛЮЧ",
  "title83": "Пожалуйста, подтвердите пароль снова",
  "title84": "Пожалуйста, введите пароль",
  "title85": "Подтвердить",
  "title86": "Создать аккаунт",
  "title87": "Имя пользователя",
  "title88": "Введите имя пользователя",
  "title89": "Email",
  "title90": "Введите адрес электронной почты",
  "title91": "Страна",
  "title92": "Мобильный",
  "title93": "Введите номер телефона",
  "title94": "Зарегистрироваться",
  "title95": "ВОЙТИ",
  "title96": "Уже есть аккаунт?",
  "title97": "Быстрая покупка",
  "title98": "Купите Tether (USDT) с кредитной или дебетовой картой на Paxful",
  "title99": "Купите Tether (USDT) с кредитной или дебетовой картой на ChangeNow",
  "title100": "Купите Tether (USDT) с кредитной или дебетовой картой на Changelly",
  "title101": "Купите Tether (USDT) с кредитной или дебетовой картой на Binance Exchange",
  "title102": "Купите Tether (USDT) с кредитной или дебетовой картой на Bybit Exchange",
  "title103": "Купите Tether (USDT) с кредитной или дебетовой картой на OKX Exchange",
  "title104": "Центр помощи",
  "title105": "1. Инструкции по использованию платформы",
  "title106": "2. Как активировать VIP",
  "title107": "3. Перевод VIP (без ключа)",
  "title108": "4. Как пополнить?",
  "title109": "5. Как вывести деньги?",
  "title110": "6. Что делать, если я забыл свой КЛЮЧ?",
  "title111": "7. Как изменить пароль, email?",
  "title112": "8. Как зарегистрировать аккаунт?",
  "title113": "9. Как инвестировать?",
  "title114": "10. VIP инвестиционные услуги",
  "title115": "Отправить",
  "title116": "Пожалуйста, введите пароль ключа",
  "title117": "Пожалуйста, введите адрес кошелька",
  "title118": "Пожалуйста, введите сумму для вывода",
  "title119": "Используйте кошелек или адрес пополнения для перевода средств.",
  "title120": "Пожалуйста, заполните форму",
  "title121": "Пополните свой баланс, используя свой криптовалютный кошелек.",
  "title122": "Успешная регистрация",
  "title123": "Пользователь не существует, пожалуйста, создайте новый аккаунт",
  "title124": "Для дальнейших операций необходимо подтвердить email",
  "title125": "Неверный пароль, попробуйте снова",
  "title126": "Ошибка при регистрации, пожалуйста, попробуйте еще раз",
  "title127": "Подтверждение пароля",
  "title128": "Необходимо ввести новый пароль",
  "title129": "Успешно изменено",
  "title130": "Необходимо авторизоваться для доступа",
  "title131": "Успешно зарегистрирован",
  "title132": "Ошибка при авторизации",
  "title133": "Запрос не может быть обработан",
  "title134": "Пожалуйста, подождите немного",
  "title135": "Пожалуйста, попробуйте позже",
  "title136": "Проблемы с сервером",
  "title137": "Сеть перегружена",
  "title138": "Неправильный формат данных",
  "title139": "Невозможно подключиться к сети",
  "title140": "Привет, {name}, как дела?",
  "title141": "Ваш запрос успешно отправлен, скоро мы свяжемся с вами.",
  "title142": "Наши специалисты готовы помочь вам. В чем ваш вопрос?",
  "title143": "Списки переводов",
  content1: `Echipa USDT a intrat oficial pe platforma de servicii de tranzacționare a activelor digitale.
Cu un design tehnic fiabil și scalabil, servicii de înaltă calitate și mai mult suport, platforma de tranzacționare a activelor digitale USDT își propune să ofere utilizatorilor săi speciali servicii de tranzacționare USDT mai convenabile, mai sigure și mai rapide și active blockchain de înaltă calitate. Clienții VIP globali vor beneficia de servicii de depozite, transferuri, retrageri, management financiar și investiții. Management financiar exclusiv VIP, investiții și suport pentru clienți 24 de ore din 24.
1. Platforma este deschisă pentru înregistrare. Oricine poate înregistra propriul cont.
2. Nivelul VIP al unui cont nou înregistrat este 0 (pentru depozite, transferuri, retrageri etc.). Acestea sunt gratuite și nu trebuie să îți actualizezi contul pentru a le utiliza. Dacă contul tău trebuie să primească transferuri de la alții, va trebui să utilizezi limitele de plată (în acest moment, conturile de nivel 0 nu vor putea primi plăți), iar contul trebuie să fie actualizat la nivel VIP pentru a utiliza funcția de plată; cu cât nivelul este mai mare, cu atât alocațiile zilnice și lunare vor fi diferite. Te rugăm să actualizezi contul în funcție de nevoi.
3. De asemenea, putem oferi diverse servicii, cum ar fi schimbul de monede și împrumuturi cu ipotecă USDT pentru clienții VIP.`,

content2: `1. Apasă pe butonul VIP
2. Selectează nivelul VIP pe care dorești să-l achiziționezi
3. Plătește depozitul VIP și achiziționează-l cu succes
4. Depozitul necesar pentru nivelul VIP (poți anula în orice moment și vei primi înapoi depozitul)
Depozit VIP1: 50 USDT (VIP1 este un serviciu de experiență pentru începători, valabil timp de 1 lună, depozitul nu poate fi rambursat și nu poate fi anulat)
Depozit VIP2: 1000 USDT
Depozit VIP3: 3000 USDT
Depozit VIP4: 30000 USDT
Depozit VIP5: 50000 USDT
Depozit VIP6: 100000 USDT
Nivelul VIP al unui cont nou înregistrat este 0 (pentru depozite, transferuri, retrageri etc.). Acestea sunt gratuite și nu trebuie să îți actualizezi contul pentru a le utiliza. Dacă contul tău trebuie să primească transferuri de la alții, va trebui să utilizezi limitele de plată (în acest moment, conturile de nivel 0 nu vor putea primi plăți), iar contul trebuie să fie actualizat la nivel VIP pentru a utiliza funcția de plată; cu cât nivelul este mai mare, cu atât alocațiile zilnice și lunare vor fi diferite. Te rugăm să actualizezi contul în funcție de nevoi.
VIP1: Limita de plată 10 USDT pe zi și 30 USDT pe lună
VIP2: Limita de plată 100 USDT pe zi și 3000 USDT pe lună
VIP3: Limita de plată 30000 USDT pe zi și 900000 USDT pe lună
VIP4: Limita de plată 100000 USDT pe zi și 3000000 USDT pe lună
VIP5: Limita de plată 200000 USDT pe zi și 6000000 USDT pe lună
VIP6: Limita de plată 2000000 USDT pe zi și 60000000 USDT pe lună`,

content3: `Notă: VIP-urile pot utiliza funcția de transfer a platformei. Transferurile se pot face doar între VIP-uri ale platformei (transferurile reușite nu pot fi returnate)
1. Apasă pe butonul de transfer
2. Completează corect numele de utilizator VIP, adresa de e-mail și numărul de telefon mobil
3. Apasă pe Confirmă pentru a finaliza transferul (nu este necesar să introduci KEY)
4. Dacă contul tău trebuie să primească transferuri de la alții, va trebui să utilizezi limitele de plată
VIP1: Limita de plată 10 USDT pe zi și 30 USDT pe lună
VIP2: Limita de plată 100 USDT pe zi și 3000 USDT pe lună
VIP3: Limita de plată 30000 USDT pe zi și 900000 USDT pe lună
VIP4: Limita de plată 100000 USDT pe zi și 3000000 USDT pe lună
VIP5: Limita de plată 200000 USDT pe zi și 6000000 USDT pe lună
VIP6: Limita de plată 2000000 USDT pe zi și 60000000 USDT pe lună
Exemplu de limită de transfer: Dacă contul tău este VIP5
VIP5 transfer către VIP1 (10USDT)
VIP5 transfer către VIP2 (100USDT)
VIP5 transfer către VIP3 (30000USDT)
VIP5 transfer către VIP4 (100000USDT)
VIP5 transfer către VIP5 (200000USDT)
VIP6 transfer către VIP6 (2000000USDT)`,

content4: `1. Apasă pe butonul de încărcare
2. Selectează suma pe care dorești să o depui
3. Apasă pe Confirmă și introduce (KEY)
4. Vei primi o adresă exclusivă de depozit pentru contul tău, din care poți transfera din orice schimb sau portofel.
5. Suma ta depusă va fi adăugată automat la soldul contului tău`,

content5: `1. Apasă pe butonul de retragere
2. Introdu adresa de retragere USDT (TRC20)
3. Introdu suma de retragere
4. Apasă pe butonul Confirmă și introduce KEY
5. Retragerea a fost realizată cu succes, iar retragerea va ajunge în contul tău în termen de 2 ore.`,

content6: `1. KEY-ul este setat de tine și nu poate fi obținut de altcineva. KEY-ul este unic și nu poate fi resetat.
2. KEY-ul este singura dovadă a contului tău și nu poate fi modificat. Nu poate fi recuperat
3. Dacă uiți KEY-ul și contul tău are fonduri, poți înregistra un cont nou și să îți upgradezi VIP-ul, apoi să transferi fondurile din vechiul cont în contul nou pentru a le retrage. Transferurile interne între VIP-uri nu necesită KEY
KEY-ul este singurul tău acreditiv pentru contul tău și nu poate fi modificat sau recuperat. Pentru a proteja confidențialitatea clientului, platforma nu va înregistra numărul de cont al utilizatorului, parola, numărul de telefon mobil, adresa de e-mail sau KEY-ul. Te rugăm să îți păstrezi informațiile de autentificare în siguranță`,

content7: `1. Apasă pe pagina principală
2. Apasă pe butonul Centrul de Securitate
3. Selectează parola sau adresa de e-mail care trebuie schimbată
4. Introdu KEY-ul pentru a finaliza`,

content8: `(Notă: Când te înregistrezi, te rugăm să nu deschizi software-ul de traducere. Dacă deschizi software-ul de traducere, butonul nu va fi confirmat și nu te vei putea înregistra. Te rugăm să schimbi browser-ul)
1. Apasă pe butonul de înregistrare de pe pagina principală
2. Apasă pe înregistrare
3. Completează informațiile de înregistrare
4. Te rugăm să păstrezi cu grijă KEY-ul atunci când îl setezi, deoarece nu poate fi modificat sau recuperat (pierderile pot duce la daune financiare)`,

content9: `Depozitează în siguranță și câștigă ușor
1. Suma minimă de depozit: 5000 USDT (conturile cu fonduri sub 5000 nu pot accesa serviciile financiare)
2. Activele tale vor genera venituri în funcție de diferitele nivele, iar venitul total pentru fiecare nivel va fi adunat.
3. Venitul anualizat estimat va fi ajustat oricând în funcție de venitul real din piscina de minerit.
4. Venitul va fi calculat în prima zi după deschiderea PoS.
5. Nu este necesar să îngheți activele ipotecate, poți tranzacționa sau retrage monedele în orice moment.
Reprezintă data de garanție. Veniturile vor începe în T+1 (a doua zi) și vor fi distribuite în contul tău în T+2.
Nu vor exista venituri în ziua în care vei elibera activele ipotecate. Veniturile vor fi distribuite zilnic în contul tău spot. Serviciul financiar actual poate fi anulat în orice moment și veniturile vor fi stabilite până în ziua anterioară ieșirii din PoS.
Pentru alte produse, când perioada minimă de depozit este atinsă, poți alege să răscumperi sau să continui depozitul. Venitul final va fi calculat pe baza numărului real de zile de depozit, iar principalul va fi deblocat în ziua de răscumpărare.
Printre produsele financiare oferite, poți alege dintre zeci de active digitale (precum Bitcoin, Ethereum) și monede stabile.`,

content10: `1. Îți mulțumim din nou pentru că ai devenit membru al nostru, vom oferi întotdeauna servicii și suport mai bune!
2. Vei beneficia de servicii de transfer și colectare
3. Vei avea rate exclusive VIP și costuri de tranzacție mai avantajoase.
4. Vei primi un insigne VIP exclusiv, care îți va arăta identitatea și statutul.
5. Vei fi adăugat la grupul nostru exclusiv de servicii VIP și vei primi mai mult suport din partea clienților noștri.
6. Vei primi rapoarte periodice despre industrie pentru a înțelege dinamica pieței și oportunitățile de investiții.
7. Vei beneficia de avantaje exclusive de sărbători și privilegii personalizate cu suveniruri, pentru a simți grijă din partea noastră.
8. Vei avea ocazia să participi la activități exclusive VIP și să cunoști alți investitori de succes.
9. Vei avea un canal exclusiv de servicii VIP 24 de ore și vei primi soluții rapide și prioritate.
10. Vei beneficia de un canal verde independent și vei putea efectua depozite și retrageri mai ușor.` 

},
sv:{
  "menu_1": "Hem",
  "menu_2": "Handel",
  "menu_3": "Min",
  "title1": "Valuta",
  "title2": "Senaste pris",
  "title3": "Partners",
  "title4": "Språk",
  "title5": "Hem",
  "welcome": "Välkommen",
  "hello": "Hej",
  "title6": "Tillgängligt saldo",
  "title7": "Dagligt mottagningsgräns",
  "title8": "Månatligt mottagningsgräns",
  "title9": "Insättning",
  "title10": "Uttag",
  "title11": "Intern överföring",
  "title12": "Medlemskap Uppgradering",
  "title13": "Referens årlig inkomst",
  "title14": "Investeringsperiod",
  "title15": "Startmängd",
  "title16": "Delta nu",
  "title17": "Plattformens register",
  "title18": "Uttag",
  "title19": "Uppladdning",
  "title20": "Min",
  "title21": "Mina tillgångar",
  "title22": "Insättning",
  "title23": "Uttag",
  "title24": "Överföring",
  "title25": "VIP",
  "title26": "Meddelanden",
  "title27": "Snabb Köp",
  "title28": "Hjälpcenter",
  "title29": "Logga ut",
  "title30": "Inställningar",
  "title31": "Säkerhetscenter",
  "title32": "Om oss",
  "title33": "E-postverifiering",
  "title34": "Ändra lösenord",
  "title35": "Ändra e-post",
  "context1": "Denna webbplats är en decentraliserad DeFi-finansplattform med hög anonymitet och säkerhet. Du behöver inte oroa dig för att din personliga information läcks eller för KYC-verifiering. Användare kan fritt lagra och ta ut sina tillgångar. De decentraliserade finansiella produkterna som köps på denna webbplats erbjuder hög avkastning och stabilitet med enkla och snabba tjänster.",
  "context2": "(Om DeFi decentraliserade finansiella applikationer)",
  "context3": "Decentralized Finance (DeFi), förkortning för Decentralized Finance, är en tillämpning av blockchain-teknologi inom Fintech. DeFi skapar ett öppet och transparent finansiellt system genom öppna och decentraliserade blockchain-plattformar.",
  "context4": "DeFi innebär att använda öppen källkod och decentraliserade nätverk för att omvandla traditionella finansiella produkter till pålitliga och transparenta protokoll som fungerar utan onödiga mellanhänder. DeFi är ett koncept och en arkitektur centrerad kring 'decentralisering', vilket innebär att det inte finns någon administratör, alla användare är lika och har samma rättigheter. Om någon vill ändra innehållet måste det godkännas av alla. Därför har DeFi egenskaper som fördelad äganderätt, tillhör ingen och är svår att manipulera. DeFi har också fördelar med blockchain, såsom transparens, motståndskraft mot censur och oföränderlighet.",
  "context5": "DeFi har många fördelar, inklusive en tillitslös grund, ingen behov av institutionellt tillstånd och kontroll över tillgångarna i individens händer. Dess största fördel är att eliminera 'tredje part' genom decentralisering och bibehålla registertransparens och oföränderlighet.",
  "context6": "Den största skillnaden mellan DeFi och Fintech är deras 'tillit'-mekanism. Fintech tillämpar blockchain-teknologi inom det befintliga finansiella systemet, men förlitar sig fortfarande på centraliserade institutioner, och alla operationer kontrolleras av människor och får användarnas förtroende genom tredjeparts mekanismer. I kontrast är DeFi främst decentraliserad och använder smarta kontrakt. Dess kod är öppen och den centrala logiken körs på blockchain, vilket gör den oföränderlig och genomdriven, så användarna kan direkt lita på strukturen.",
  "context7": "Synliga DeFi-applikationer inkluderar decentraliserade handelsprotokoll, stabilcoins, lån, derivat, fonder, förutsägelsemarknader, tokeniseringprotokoll, KYC/AML och identifiering.",
  "title36": "Insättning",
  "title37": "Nätverk",
  "title38": "Belopp",
  "title39": "Insättning",
  "title40": "Använd plånbok eller växlingstransferinsättningsadress. Efter att uppladdningen är slutförd kommer beloppet automatiskt att läggas till ditt konto.",
  "title41": "Uttag",
  "title42": "Plånboksadress",
  "title43": "Nätverk",
  "title44": "Belopp",
  "title45": "Tillgängligt saldo",
  "title46": "Säkerhetstips: Vänligen fyll i korrekt uttagsadress, tillgångarna kan inte återvändas efter att de har överförts.",
  "title47": "Uttag",
  "title48": "Ange plånboksadress",
  "title49": "5-1000000",
  "title50": "Intern Överföring",
  "title51": "Konto",
  "title52": "VIP Medlemmar kan använda en knapp för överföring utan nyckel",
  "title53": "Mottagaren måste vara minst VIP1",
  "title54": "Serviceavgift",
  "title55": "Intern Överföring",
  "title56": "Köp",
  "title57": "Insättning",
  "title58": "Nivåregler",
  "title59": "Insättning",
  "title60": "Dagligt Insamlingsgräns",
  "title61": "Månatligt Insamlingsgräns",
  "title62": "Användare måste betala en insättning för att använda VIP-tjänster. VIP1 är giltigt i 1 månad, det kommer automatiskt att upphävas efter utgången.",
  "title63": "Post",
  "title64": "Medlemskap Uppgradering",
  "title65": "VIP1 kräver 30 dagars väntetid för automatisk återbetalning (kan inte begära återbetalning).",
  "title66": "VIP2-VIP6 kan kontakta kundtjänst för att ansöka om återbetalning. Vid framgångsrik återbetalning kommer insättningen omedelbart att återgå till saldot och VIP-nivån kommer att annulleras.",
  "title67": "Meddelanden",
  "title68": "Hej! Välkommen till inloggning!",
  "title69": "Konto",
  "title70": "Lösenord",
  "title71": "Vänligen ange ditt användarnamn eller e-postadress",
  "title72": "Vänligen ange ditt lösenord",
  "title73": "Ange captcha",
  "title74": "Logga in",
  "title75": "Inget konto",
  "title76": "REGISTRERA",
  "title77": "ÅTERSTÄLL LÖSENORD",
  "title78": "Lösenord",
  "title79": "Lösenord",
  "title80": "Bekräfta lösenord",
  "title81": "NYCKEL",
  "title82": "Vänligen ange NYCKEL",
  "title83": "Vänligen bekräfta lösenordet igen",
  "title84": "Vänligen ange ditt lösenord",
  "title85": "Bekräfta",
  "title86": "Skapa ett konto",
  "title87": "Användarnamn",
  "title88": "Vänligen ange ditt användarnamn",
  "title89": "E-post",
  "title90": "Vänligen ange din e-postadress",
  "title91": "Land",
  "title92": "Mobil",
  "title93": "Vänligen ange telefonnummer",
  "title94": "Registrera",
  "title95": "LOGGA IN",
  "title96": "Har du redan ett konto?",
  "title97": "Snabb Köp",
  "title98": "Köp Tether (USDT) med kredit- eller betalkort på Paxful",
  "title99": "Köp Tether (USDT) med kredit- eller betalkort på ChangeNow",
  "title100": "Köp Tether (USDT) med kredit- eller betalkort på Changelly",
  "title101": "Köp Tether (USDT) med kredit- eller betalkort på Binance Exchange",
  "title102": "Köp Tether (USDT) med kredit- eller betalkort på Bybit Exchange",
  "title103": "Köp Tether (USDT) med kredit- eller betalkort på OKX Exchange",
  "title104": "Hjälpcenter",
  "title105": "1. Plattformens användningsinstruktioner",
  "title106": "2. Hur man aktiverar VIP",
  "title107": "3. VIP överföring (ingen nyckel krävs)",
  "title108": "4. Hur man sätter in?",
  "title109": "5. Hur man tar ut pengar?",
  "title110": "6. Vad ska jag göra om jag har glömt min NYCKEL?",
  "title111": "7. Hur man ändrar lösenord, e-post?",
  "title112": "8. Hur man registrerar ett konto?",
  "title113": "9. Hur man investerar?",
  "title114": "10. VIP investeringstjänster",
  "title115": "Skicka",
  "title116": "Vänligen ange nyckellösenord",
  "title117": "Vänligen ange din plånboksadress",
  "title118": "Vänligen ange uttagsbelopp",
  "title119": "Använd plånbok eller växlingstransferinsättningsadress. Efter att uppladdningen är slutförd kommer beloppet automatiskt att läggas till ditt konto.",
  "title120": "Skickar",
  "title121": "Minsta insättningsbelopp kan inte vara mindre än 50",
  "title122": "Ansök om återbetalning",
  "title123": "Vänligen ange beloppet du vill överföra",
  "title124": "Vänligen ange användarnamn",
  "title125": "Använd plånbok eller växlingstransferinsättningsadress. Efter att uppladdningen är slutförd kommer beloppet automatiskt att läggas till ditt konto.",
  "title126": "Plånboks QR kod",
  "title127": "Väntar",
  "title128": "Vänligen ange ditt mobilnummer",
  "title129": "Vänligen ange din e-postadress",
  "title130": "Vänligen ange ett lösenord",
  "title131": "Vänligen ange lösenordet igen",
  "title132": "De lösenord du angav två gånger stämmer inte överens",
  "title133": "Vänligen ange nyckellösenord",
  "title134": "Vänligen ange nyckellösenordet igen",
  "title135": "De nyckellösenord du angav två gånger stämmer inte överens",
  "title136": "Vänligen ange bildverifieringskoden",
  "title137": "Registrering misslyckades",
  "title138": "Vänligen ange inloggningslösenord",
  "title139": "Loggar in",
  "title140": "Vänligen ange ditt lösenord igen",
  "title141": "Vänligen ange ditt nyckellösenord",
  "title142": "De lösenord du angav två gånger stämmer inte överens",
  "title143": "Överföringslistor",
  content1: `USDT-teamet har officiellt gått in i plattformen för digitala tillgångstradingtjänster.
Med pålitlig och skalbar teknisk design, högkvalitativa tjänster och mer stöd, syftar USDT digitala tillgångs tradingplattform till att erbjuda sina speciella användare mer bekväma, säkra och snabbare USDT-bytartjänster och högkvalitativa blockchain-tillgångar. Globala VIP-kunder får insättningar, överföringar, uttag, finansiell förvaltning och investeringsservice. VIP-exklusiv finansiell förvaltning, investeringar och 24/7 kundsupport.
1. Plattformen är öppen för registrering. Alla kan registrera sitt eget konto.
2. VIP-nivån för ett nyligen registrerat konto är 0 (för insättning, överföring, uttag etc.). Dessa är gratis och du behöver inte uppgradera ditt konto för att använda dem. Om ditt konto behöver ta emot överföringar från andra, måste du använda betalningsgränser (just nu kan konton på nivå 0 inte ta emot betalningar), och du måste uppgradera ditt konto till VIP för att använda betalningsfunktionen; ju högre nivå desto olika dagliga och månatliga tilldelningar. Uppgradera ditt konto vid behov.
3. Vi kan även erbjuda olika tjänster som valutaväxling och USDT-pantlån för VIP-kunder.`,

content2: `1. Klicka på VIP-knappen
2. Välj den VIP-nivå du vill köpa
3. Betala VIP-depositionen och köp framgångsrikt
4. Depositionen som krävs för VIP-nivån (du kan när som helst avbryta och få tillbaka din deposition)
VIP1-deposition: 50 USDT (VIP1 är en nybörjarupplevelse, giltig i 1 månad, depositionen kan inte återbetalas och kan inte avbrytas)
VIP2-deposition: 1000 USDT
VIP3-deposition: 3000 USDT
VIP4-deposition: 30000 USDT
VIP5-deposition: 50000 USDT
VIP6-deposition: 100000 USDT
VIP-nivån för ett nyligen registrerat konto är 0 (för insättning, överföring, uttag etc.). Dessa är gratis och du behöver inte uppgradera ditt konto för att använda dem. Om ditt konto behöver ta emot överföringar från andra, måste du använda betalningsgränser (just nu kan konton på nivå 0 inte ta emot betalningar), och du måste uppgradera ditt konto till VIP för att använda betalningsfunktionen; ju högre nivå desto olika dagliga och månatliga tilldelningar. Uppgradera ditt konto vid behov.
VIP1: Betalningsgräns 10 USDT per dag och 30 USDT per månad
VIP2: Betalningsgräns 100 USDT per dag och 3000 USDT per månad
VIP3: Betalningsgräns 30000 USDT per dag och 900000 USDT per månad
VIP4: Betalningsgräns 100000 USDT per dag och 3000000 USDT per månad
VIP5: Betalningsgräns 200000 USDT per dag och 6000000 USDT per månad
VIP6: Betalningsgräns 2000000 USDT per dag och 60000000 USDT per månad`,

content3: `Not: VIP-användare kan använda plattformens överföringsfunktion. Överföringar kan endast göras mellan VIP-användare på plattformen (framgångsrika överföringar kan inte återställas)
1. Klicka på överföringsknappen
2. Fyll korrekt i VIP-användarnamn, e-postadress och mobilnummer
3. Klicka på Bekräfta för att slutföra överföringen (det behövs inget KEY)
4. Om ditt konto behöver ta emot överföringar från andra, måste du använda betalningsgränser
VIP1: Betalningsgräns 10 USDT per dag och 30 USDT per månad
VIP2: Betalningsgräns 100 USDT per dag och 3000 USDT per månad
VIP3: Betalningsgräns 30000 USDT per dag och 900000 USDT per månad
VIP4: Betalningsgräns 100000 USDT per dag och 3000000 USDT per månad
VIP5: Betalningsgräns 200000 USDT per dag och 6000000 USDT per månad
VIP6: Betalningsgräns 2000000 USDT per dag och 60000000 USDT per månad
Exempel på överföringsgräns: Om ditt konto är VIP5
VIP5 överför till VIP1 (10USDT)
VIP5 överför till VIP2 (100USDT)
VIP5 överför till VIP3 (30000USDT)
VIP5 överför till VIP4 (100000USDT)
VIP5 överför till VIP5 (200000USDT)
VIP6 överför till VIP6 (2000000USDT)`,

content4: `1. Klicka på uppladdningsknappen
2. Välj det belopp du vill sätta in
3. Klicka på Bekräfta och ange (KEY)
4. Du kommer att få en exklusiv insättningsadress för ditt konto, från vilken du kan överföra från vilken börs eller plånbok som helst.
5. Det insatta beloppet kommer att läggas till ditt kontosaldo automatiskt`,

content5: `1. Klicka på Uttagsknappen
2. Ange USDT-uttagadress (TRC20)
3. Ange uttagsbeloppet
4. Klicka på Bekräfta och ange KEY
5. Uttaget är framgångsrikt och din uttag kommer att anlända inom 2 timmar.`,

content6: `1. KEY ställs in av dig och kan inte erhållas av andra. KEY är unik och kan inte återställas.
2. KEY är det enda beviset på ditt konto och kan inte ändras. Det kan inte återställas
3. Om du glömmer KEY och ditt konto har medel, kan du registrera ett nytt konto och uppgradera till VIP, och sedan överföra medel från ditt gamla konto till ditt nya konto för uttag. För överföringar inom VIP-plattformen behövs inget KEY
KEY är din enda auktorisation för ditt konto och kan inte ändras eller återställas. För att skydda kundens sekretess kommer plattformen inte att registrera användarens kontonummer, lösenord, mobilnummer, e-postadress eller KEY. Vänligen håll din inloggningsinformation säker`,

content7: `1. Klicka på min startsida
2. Klicka på Säkerhetscenter-knappen
3. Välj lösenord eller e-post som behöver ändras
4. Ange KEY för att slutföra`,

content8: `(Not: Vid registrering, vänligen öppna inte översättningsprogram. Om du öppnar översättningsprogrammet kommer knappen inte att bekräftas och du kan inte registrera dig. Vänligen byt webbläsare)
1. Klicka på registreringsknappen på hemsidan
2. Klicka på Registrera
3. Fyll i dina registreringsuppgifter
4. Vänligen spara KEY noggrant när du ställer in den, eftersom den inte kan ändras eller återställas (förlust kan orsaka förlust av medel)`,

content9: `Lagra säkert och tjäna lätt
1. Minsta insättningsbelopp: 5000 USDT (kontofonder under 5000 kan inte komma åt finansiella tjänster)
2. Dina tillgångar kommer att generera inkomst beroende på olika nivåer, och den totala inkomsten för varje nivå kommer att summeras.
3. Den beräknade årliga avkastningen kommer att justeras när som helst baserat på den faktiska inkomsten från gruvpoolen.
4. Avkastningen beräknas en dag efter att PoS har öppnats.
5. Det är inte nödvändigt att frysa panttillgångar, du kan handla eller ta ut mynt när som helst.
Det representerar pantdatumet. Intäkterna börjar på T+1 (nästa dag) och kommer att distribueras till ditt konto på T+2.
Inga intäkter kommer att genereras den dag du släpper dina pantsatta tillgångar. Intäkterna kommer att distribueras till ditt spotkonto dagligen. Aktuell finansiell förvaltning kan avbrytas när som helst och intäkterna kommer att beräknas fram till dagen innan utgången från PoS.
För andra produkter, när den minsta lagringstiden har nåtts, kan du välja att lösa in eller fortsätta lagra. Slutlig inkomst beräknas baserat på det faktiska antalet lagringsdagar, och kapitalet kommer att låsas upp vid inlösendagen.
Bland de finansiella produkter som erbjuds kan du välja mellan dussintals digitala tillgångar (som Bitcoin, Ethereum) och stabila mynt.`,

content10: `1. Tack igen för att du blivit vår medlem, vi kommer att ge dig bättre service och stöd!
2. Du får överförings- och insamlingsservice
3. Du kommer att få exklusiva VIP-priser och mer fördelaktiga transaktionskostnader.
4. Du kommer att få ett exklusivt VIP-märke för att visa din identitet och status.
5. Du kommer att gå med i vår exklusiva VIP-servicegrupp och få mer kundsupport.
6. Du kommer att få regelbundna branschrapporter för att förstå marknadsdynamik och investeringsmöjligheter.
7. Du kommer att njuta av exklusiva förmåner och anpassade souvenirprivilegier, så att du kan känna vår omtanke.
8. Du kommer att ha möjlighet att delta i exklusiva VIP-aktiviteter och lära känna fler framgångsrika investerare.
9. Du kommer att ha en exklusiv 24-timmars VIP-kundservicekanal och få snabbare och prioriterad lösningssupport.
10. Du kommer att få en oberoende grön kanal och njuta av mer bekväma insättningar och uttag.` 

},
tr:{
  "menu_1": "Ana Sayfa",
  "menu_2": "Ticaret",
  "menu_3": "Benim",
  "title1": "Para Birimi",
  "title2": "Son Fiyat",
  "title3": "Ortaklar",
  "title4": "Dil",
  "title5": "Ana Sayfa",
  "welcome": "Hoş Geldiniz",
  "hello": "Merhaba",
  "title6": "Mevcut Bakiye",
  "title7": "Günlük Alım Limiti",
  "title8": "Aylık Alım Limiti",
  "title9": "Yatırma",
  "title10": "Çekme",
  "title11": "İç Transfer",
  "title12": "Üyelik Yükseltme",
  "title13": "Referans Yıllık Gelir",
  "title14": "Yatırım Süresi",
  "title15": "Başlangıç Miktarı",
  "title16": "Şimdi Katıl",
  "title17": "Platform Kayıtları",
  "title18": "Çekme",
  "title19": "Yatırma",
  "title20": "Benim",
  "title21": "Varlıklarım",
  "title22": "Yatırma",
  "title23": "Çekme",
  "title24": "Transfer",
  "title25": "VIP",
  "title26": "Mesaj Bildirimi",
  "title27": "Hızlı Alım",
  "title28": "Yardım Merkezi",
  "title29": "Çıkış",
  "title30": "Ayarlar",
  "title31": "Güvenlik Merkezi",
  "title32": "Hakkımızda",
  "title33": "E-posta Doğrulaması",
  "title34": "Şifreyi Değiştir",
  "title35": "E-postayı Değiştir",
  "context1": "Bu site, yüksek anonimlik ve güvenlik ile merkeziyetsiz bir DeFi finansal platformudur. Kişisel bilgilerinizin sızması veya KYC doğrulaması konusunda endişelenmenize gerek yoktur. Kullanıcılar, varlıklarını özgürce saklayabilir ve çekebilir. Bu siteden satın alınan merkeziyetsiz finansal ürünler, yüksek getiri ve stabilite sunar, kolay ve hızlı hizmetler sağlar.",
  "context2": "(DeFi merkeziyetsiz finansal uygulamalar hakkında)",
  "context3": "Merkeziyetsiz Finans (DeFi), Fintech alanında blockchain teknolojisinin bir uygulamasıdır. DeFi, açık kaynaklı ve merkeziyetsiz blockchain platformları aracılığıyla açık ve şeffaf bir finansal sistem oluşturur.",
  "context4": "DeFi, açık kaynak yazılımı ve merkeziyetsiz ağlar kullanarak geleneksel finansal ürünleri, gereksiz aracıları olmadan çalışan güvenilmez ve şeffaf protokollere dönüştürmeyi amaçlar. DeFi, 'merkeziyetsizlik' etrafında şekillenmiş bir kavram ve yapıdır; bu, yöneticinin olmadığı, her kullanıcının eşit olduğu ve aynı haklara sahip olduğu anlamına gelir. İçeriği değiştirmek isteyen birinin, herkesin onayını alması gerekir. Bu nedenle DeFi'nin özellikleri, dağıtılmış mülkiyet, kimseye ait olmama ve manipülasyona karşı zorluk içermektedir. DeFi ayrıca şeffaflık, sansüre karşı direnç ve değiştirilemezlik gibi blockchain'in avantajlarına sahiptir.",
  "context5": "DeFi, güvenilmez bir temel, kurumsal izin gereksizliği ve bireylerin varlıkları üzerinde kontrol sahibi olma gibi birçok avantaja sahiptir. En büyük avantajı, merkeziyetsizlik yoluyla 'güvenilen üçüncü tarafları' ortadan kaldırmak ve defter şeffaflığını ve değiştirilemezliğini korumaktır.",
  "context6": "DeFi ile Fintech arasındaki en büyük fark, onların 'güven' mekanizmasıdır. Fintech, blockchain teknolojisini mevcut finansal sisteme entegre ederken, hala merkezi kurumlara güvenmektedir ve tüm işlemler insan kontrolü altındadır, kullanıcı güvenini üçüncü taraf mekanizmalarıyla sağlar. Buna karşılık, DeFi'nin ana özelliği merkeziyetsizliktir ve akıllı sözleşmeler kullanır. Kodları açık kaynaklıdır ve temel mantığı blockchain üzerinde çalışır, bu nedenle değiştirilemez ve zorunlu hale gelir ve kullanıcılar yapısına doğrudan güvenebilirler.",
  "context7": "Görünür DeFi uygulamaları, merkeziyetsiz ticaret protokolleri, stabilcoin'ler, kredi, türevler, fonlar, tahmin piyasaları, tokenizasyon protokolleri, KYC/AML ve kimlik doğrulama içerir.",
  "title36": "Yatırma",
  "title37": "Ağ",
  "title38": "Tutar",
  "title39": "Yatırma",
  "title40": "Cüzdan veya borsa transferi ile yatırma adresini kullanın. Yatırım tamamlandıktan sonra tutar otomatik olarak hesabınıza eklenecektir.",
  "title41": "Çekme",
  "title42": "Cüzdan Adresi",
  "title43": "Ağ",
  "title44": "Tutar",
  "title45": "Mevcut Bakiye",
  "title46": "Güvenlik İpucu: Lütfen doğru çekim hesabınızı girin, varlıklar transfer edildikten sonra geri alınamaz.",
  "title47": "Çekme",
  "title48": "Cüzdan adresini girin",
  "title49": "5-1000000",
  "title50": "İç Transfer",
  "title51": "Hesap",
  "title52": "VIP Üyeler, Anahtar Kullanımına Gerek Kalmadan Tek Tıkla Transfer Yapabilir.",
  "title53": "Alıcının en az VIP1 olması gerekir.",
  "title54": "Hizmet Ücreti",
  "title55": "İç Transfer",
  "title56": "Satın Al",
  "title57": "Yatırma",
  "title58": "Seviye Kuralları",
  "title59": "Yatırma",
  "title60": "Günlük Toplama Limiti",
  "title61": "Aylık Toplama Limiti",
  "title62": "Kullanıcılar, VIP hizmetlerini kullanabilmek için bir depozito ödemelidir. VIP1 1 ay geçerlidir, süresi dolduğunda otomatik olarak iptal edilir.",
  "title63": "Kayıt",
  "title64": "Üyelik Yükseltme",
  "title65": "VIP1 için 30 gün beklenmesi gerekir, otomatik geri ödeme yapılır (geri ödeme başvurusu yapılamaz).",
  "title66": "VIP2-VIP6, müşteri hizmetleri ile iletişime geçerek geri ödeme başvurusunda bulunabilir. Başarılı geri ödeme durumunda depozito anında bakiyenize iade edilir ve VIP seviyesi iptal edilir.",
  "title67": "Mesaj Bildirimi",
  "title68": "Merhaba! Giriş yaptığınız için hoş geldiniz!",
  "title69": "Hesap",
  "title70": "Şifre",
  "title71": "Kullanıcı adınızı veya e-posta adresinizi girin",
  "title72": "Şifrenizi girin",
  "title73": "Doğrulama kodunu girin",
  "title74": "Giriş Yap",
  "title75": "Hesabınız yok mu?",
  "title76": "KAYIT OL",
  "title77": "ŞİFREYİ UNUTTUM",
  "title78": "Şifre",
  "title79": "Şifre",
  "title80": "Şifreyi Onayla",
  "title81": "ANAHTAR",
  "title82": "Lütfen ANAHTAR'ı girin",
  "title83": "Lütfen şifreyi tekrar onaylayın",
  "title84": "Lütfen şifrenizi girin",
  "title85": "Onayla",
  "title86": "Hesap oluştur",
  "title87": "Kullanıcı Adı",
  "title88": "Kullanıcı adınızı girin",
  "title89": "E-posta",
  "title90": "E-posta adresinizi girin",
  "title91": "Ülke",
  "title92": "Mobil",
  "title93": "Telefon numaranızı girin",
  "title94": "Kayıt Ol",
  "title95": "GİRİŞ YAP",
  "title96": "Hesabınız var mı?",
  "title97": "Hızlı Alım",
  "title98": "Kredi veya banka kartı ile Tether (USDT) satın alın Paxful üzerinden",
  "title99": "Kredi veya banka kartı ile Tether (USDT) satın alın ChangeNow üzerinden",
  "title100": "Kredi veya banka kartı ile Tether (USDT) satın alın Changelly üzerinden",
  "title101": "Kredi veya banka kartı ile Tether (USDT) satın alın Binance Borsası üzerinden",
  "title102": "Kredi veya banka kartı ile Tether (USDT) satın alın Bybit Borsası üzerinden",
  "title103": "Kredi veya banka kartı ile Tether (USDT) satın alın OKX Borsası üzerinden",
  "title104": "Yardım Merkezi",
  "title105": "1. Platform kullanım talimatları",
  "title106": "2. VIP nasıl aktive edilir",
  "title107": "3. VIP transferi (anahtar gerektirmez)",
  "title108": "4. Nasıl yatırım yaparım?",
  "title109": "5. Nasıl para çekilir?",
  "title110": "6. Anahtarımı unuttum, ne yapmalıyım?",
  "title111": "7. Şifre, e-posta nasıl değiştirilir?",
  "title112": "8. Hesap nasıl oluşturulur?",
  "title113": "9. Nasıl yatırım yaparım?",
  "title114": "10. VIP yatırım hizmetleri",
  "title115": "Gönder",
  "title116": "Lütfen Anahtar Şifrenizi Girin",
  "title117": "Lütfen cüzdan adresinizi girin",
  "title118": "Lütfen çekim tutarını girin",
  "title119": "Cüzdan veya borsa transferi ile yatırma adresini kullanın. Yatırım tamamlandıktan sonra tutar otomatik olarak hesabınıza eklenecektir.",
  "title120": "Gönderiliyor",
  "title121": "Minimum yatırma tutarı 50'den az olamaz",
  "title122": "Geri Ödeme Talep Et",
  "title123": "Lütfen transfer etmek istediğiniz tutarı girin",
  "title124": "Lütfen kullanıcı adınızı girin",
  "title125": "Cüzdan veya borsa transferi ile yatırma adresini kullanın. Yatırım tamamlandıktan sonra tutar otomatik olarak hesabınıza eklenecektir.",
  "title126": "Cüzdan QR Kodu",
  "title127": "Bekleniyor",
  "title128": "Lütfen telefon numaranızı girin",
  "title129": "Lütfen e-posta adresinizi girin",
  "title130": "Lütfen şifreyi girin",
  "title131": "Lütfen şifreyi tekrar girin",
  "title132": "Girilen şifreler eşleşmiyor",
  "title133": "Lütfen anahtar şifrenizi girin",
  "title134": "Lütfen anahtar şifrenizi tekrar girin",
  "title135": "Girilen anahtar şifreleri eşleşmiyor",
  "title136": "Lütfen görsel doğrulama kodunu girin",
  "title137": "Kayıt başarısız",
  "title138": "Lütfen giriş şifrenizi girin",
  "title139": "Giriş yapılıyor",
  "title140": "Lütfen şifrenizi tekrar girin",
  "title141": "Lütfen anahtar şifrenizi girin",
  "title142": "Girilen şifreler eşleşmiyor",
  "title143": "Transfer Listesi",
  content1: `USDT ekibi, dijital varlık ticaret hizmet platformuna resmi olarak girmiştir.
Güvenilir ve ölçeklenebilir teknik tasarım, yüksek kaliteli hizmetler ve daha fazla destek ile USDT dijital varlık ticaret platformu, özel kullanıcılarına daha uygun, güvenli ve hızlı USDT takas hizmetleri ve yüksek kaliteli blockchain varlıkları sunmayı hedeflemektedir. Küresel VIP müşteriler, yatırımlar, transferler, çekimler, finansal yönetim ve yatırım hizmetleri sunulmaktadır. VIP özel finansal yönetim, yatırım ve 24 saat müşteri hizmetleri.
1. Platform, kullanıcı kaydı için açıktır. Herkes kendi hesabını kaydedebilir.
2. Yeni kaydedilen bir hesabın VIP seviyesi 0’dır (depolama, transfer, çekim vb.). Bunlar ücretsizdir ve hesabınızı yükseltmenize gerek yoktur. Hesabınız başkalarından transfer almak istiyorsa, ödeme limitlerini kullanmanız gerekmektedir (bu durumda, seviye 0 hesaplar ödeme alamaz), bu işlevi kullanabilmek için hesabınızı VIP seviyesine yükseltmeniz gerekmektedir; seviye arttıkça, her kullanıcının günlük ve aylık limitleri farklı olacaktır. İhtiyaca göre yükseltme yapın.
3. VIP müşteriler için döviz takası ve USDT ipotek kredileri gibi çeşitli hizmetler de sunulmaktadır.`,

content2: `1. VIP butonuna tıklayın
2. Satın almak istediğiniz VIP seviyesini seçin
3. VIP depozitosunu ödeyin ve başarılı bir şekilde satın alın
4. VIP seviyesi için gerekli depozito (istediğiniz zaman iptal edebilir ve depozitonuzu geri alabilirsiniz)
VIP1 depozitosu: 50 USDT (VIP1, 1 ay geçerli yeni başlayan deneyim hizmetidir, depozito geri iade edilemez ve iptal edilemez)
VIP2 depozitosu: 1000 USDT
VIP3 depozitosu: 3000 USDT
VIP4 depozitosu: 30000 USDT
VIP5 depozitosu: 50000 USDT
VIP6 depozitosu: 100000 USDT
Yeni kaydedilen bir hesabın VIP seviyesi 0’dır (depolama, transfer, çekim vb.). Bunlar ücretsizdir ve hesabınızı yükseltmenize gerek yoktur. Hesabınız başkalarından transfer almak istiyorsa, ödeme limitlerini kullanmanız gerekmektedir (bu durumda, seviye 0 hesaplar ödeme alamaz), bu işlevi kullanabilmek için hesabınızı VIP seviyesine yükseltmeniz gerekmektedir; seviye arttıkça, her kullanıcının günlük ve aylık limitleri farklı olacaktır. İhtiyaca göre yükseltme yapın.
VIP1: Günlük ödeme limiti 10 USDT, aylık ödeme limiti 30 USDT
VIP2: Günlük ödeme limiti 100 USDT, aylık ödeme limiti 3000 USDT
VIP3: Günlük ödeme limiti 30000 USDT, aylık ödeme limiti 900000 USDT
VIP4: Günlük ödeme limiti 100000 USDT, aylık ödeme limiti 3000000 USDT
VIP5: Günlük ödeme limiti 200000 USDT, aylık ödeme limiti 6000000 USDT
VIP6: Günlük ödeme limiti 2000000 USDT, aylık ödeme limiti 60000000 USDT`,

content3: `Not: VIP'ler platform transfer işlevini kullanabilir. Transferler yalnızca platformdaki VIP'ler arasında yapılabilir (başarılı transferler geri alınamaz)
1. Transfer butonuna tıklayın
2. Doğru VIP kullanıcı adı, e-posta adresi ve telefon numarasını doldurun
3. Transferi tamamlamak için Onayla'ya tıklayın (KEY girmenize gerek yoktur)
4. Hesabınız başkalarından transfer almak istiyorsa, ödeme limitlerini kullanmanız gerekmektedir
VIP1: Günlük ödeme limiti 10 USDT, aylık ödeme limiti 30 USDT
VIP2: Günlük ödeme limiti 100 USDT, aylık ödeme limiti 3000 USDT
VIP3: Günlük ödeme limiti 30000 USDT, aylık ödeme limiti 900000 USDT
VIP4: Günlük ödeme limiti 100000 USDT, aylık ödeme limiti 3000000 USDT
VIP5: Günlük ödeme limiti 200000 USDT, aylık ödeme limiti 6000000 USDT
VIP6: Günlük ödeme limiti 2000000 USDT, aylık ödeme limiti 60000000 USDT
Transfer limiti örneği: Hesabınız VIP5 ise
VIP5 VIP1'e transfer (10USDT)
VIP5 VIP2'ye transfer (100USDT)
VIP5 VIP3'e transfer (30000USDT)
VIP5 VIP4'e transfer (100000USDT)
VIP5 VIP5'e transfer (200000USDT)
VIP6 VIP6'ya transfer (2000000USDT)`,

content4: `1. Yatırma butonuna tıklayın
2. Yatırmak istediğiniz miktarı seçin
3. Onayla'ya tıklayın, (KEY) girin
4. Hesabınız için size özel bir depozito adresi alacaksınız, bu adresi herhangi bir borsadan veya cüzdandan transfer yapabilirsiniz.
5. Yatırdığınız tutar otomatik olarak hesabınıza eklenecektir`,

content5: `1. Çekim butonuna tıklayın
2. USDT çekim adresini girin (TRC20)
3. Çekmek istediğiniz miktarı girin
4. Onayla butonuna tıklayın ve KEY girin
5. Çekim başarılıdır ve çekiminiz 2 saat içinde hesabınıza ulaşacaktır.`,

content6: `1. KEY, kendiniz tarafından belirlenir ve başkaları tarafından elde edilemez. KEY benzersizdir ve sıfırlanamaz.
2. KEY, hesabınızın tek kanıtıdır ve değiştirilemez. Sıfırlanamaz
3. Eğer KEY'i unutur ve hesabınızda fonlar varsa, yeni bir hesap kaydedebilir ve VIP'ye yükseltebilir, ardından eski hesabınızdan yeni hesabınıza fonları transfer ederek çekim yapabilirsiniz. VIP platformu içinde transferler için KEY girmenize gerek yoktur
KEY, hesabınız için tek kimlik doğrulamanızdır ve değiştirilemez ya da sıfırlanamaz. Müşteri gizliliğini korumak için platform, kullanıcıların hesap numaralarını, şifrelerini, telefon numaralarını, e-posta adreslerini veya KEY'lerini kaydetmez. Lütfen giriş bilgilerinizi güvenli tutun`,

content7: `1. Ana sayfanıza tıklayın
2. Güvenlik Merkezi butonuna tıklayın
3. Değiştirilmesi gereken şifre veya e-postayı seçin
4. KEY girerek işlemi tamamlayın`,

content8: `(Not: Kayıt sırasında lütfen çeviri yazılımını açmayın. Çeviri yazılımını açarsanız buton onaylanmaz ve kayıt olamazsınız. Lütfen tarayıcınızı değiştirin)
1. Ana sayfanın kayıt butonuna tıklayın
2. Kayıt ol'a tıklayın
3. Kayıt bilgilerinizi doldurun
4. KEY'i ayarlarken dikkatlice saklayın, çünkü değiştirilemez veya sıfırlanamaz (kayıp, mal varlığı kaybına neden olabilir)`,

content9: `Güvenle saklayın ve kolayca kazanın
1. Minimum depozito miktarı: 5000 USDT (5000'den düşük hesap bakiyeleri finansal hizmetlere erişemez)
2. Varlıklarınız farklı seviyelere göre gelir elde edecek ve her seviyenin toplam geliri topluca hesaplanacaktır.
3. Beklenen yıllık getiri, madencilik havuzunun gerçek gelirine dayalı olarak her zaman ayarlanacaktır.
4. Getiri, PoS açıldıktan bir gün sonra hesaplanacaktır.
5. İpotekli varlıkları dondurmanıza gerek yoktur, istediğiniz zaman ticaret yapabilir veya coin çekebilirsiniz.
Bu, ipotek tarihini temsil eder. Gelir, T+1'de (ertesi gün) başlayacak ve T+2'de hesabınıza aktarılacaktır.
İpotekli varlıklarınızı serbest bıraktığınız gün gelir elde edilmeyecektir. Gelirler, günlük olarak spot hesabınıza aktarılacaktır. Mevcut finansal yönetim, istediğiniz zaman iptal edilebilir ve gelir, PoS'tan çıkış gününe kadar hesaplanır.
Diğer ürünler için, minimum saklama süresi tamamlandığında, talep edebilir veya saklamaya devam edebilirsiniz. Nihai gelir, saklama gün sayısına göre hesaplanacak ve ana para, geri alım gününde serbest bırakılacaktır.
Sunduğumuz finansal ürünler arasında, Bitcoin, Ethereum gibi dijital varlıklar ve sabit paralar arasından seçim yapabilirsiniz.`,

content10: `1. Üye olduğunuz için tekrar teşekkür ederiz, size daha iyi hizmet ve destek sunacağız!
2. Transfer ve toplama hizmetlerinden yararlanacaksınız
3. Özel VIP oranlarından ve daha avantajlı işlem ücretlerinden faydalanacaksınız.
4. Kimliğinizi ve statünüzü göstermek için VIP rozetinizi alacaksınız.
5. VIP hizmet grubumuza katılacak ve daha fazla müşteri desteği alacaksınız.
6. Piyasa dinamiklerini ve yatırım fırsatlarını anlamak için düzenli sektör raporları alacaksınız.
7. Özel VIP avantajlarından ve kişiselleştirilmiş hediye ayrıcalıklarından yararlanarak ilgimizi hissedeceksiniz.
8. Özel VIP etkinliklerine katılma fırsatınız olacak ve daha fazla başarılı yatırımcıyla tanışacaksınız.
9. 24 saat VIP müşteri hizmetleri kanalınız olacak ve daha hızlı ve öncelikli çözüm desteği alacaksınız.
10. Bağımsız bir yeşil kanalınız olacak ve daha rahat para yatırma ve çekme hizmetlerinden faydalanacaksınız.` 

},
id:{
  "menu_1": "Beranda",
  "menu_2": "Perdagangan",
  "menu_3": "Saya",
  "title1": "Mata Uang",
  "title2": "Harga Terbaru",
  "title3": "Mitra",
  "title4": "Bahasa",
  "title5": "Beranda",
  "welcome": "Selamat Datang",
  "hello": "Halo",
  "title6": "Saldo Tersedia",
  "title7": "Batas Terima Harian",
  "title8": "Batas Terima Bulanan",
  "title9": "Setoran",
  "title10": "Penarikan",
  "title11": "Transfer Internal",
  "title12": "Upgrade Keanggotaan",
  "title13": "Pendapatan Tahunan Referensi",
  "title14": "Periode Investasi",
  "title15": "Jumlah Awal",
  "title16": "Ikut Sekarang",
  "title17": "Rekaman Platform",
  "title18": "Penarikan",
  "title19": "Setoran",
  "title20": "Saya",
  "title21": "Aset Saya",
  "title22": "Setoran",
  "title23": "Penarikan",
  "title24": "Transfer",
  "title25": "VIP",
  "title26": "Notifikasi Pesan",
  "title27": "Beli Cepat",
  "title28": "Pusat Bantuan",
  "title29": "Keluar",
  "title30": "Pengaturan",
  "title31": "Pusat Keamanan",
  "title32": "Tentang Kami",
  "title33": "Autentikasi Email",
  "title34": "Ubah Kata Sandi",
  "title35": "Ubah Email",
  "context1": "Situs ini adalah platform keuangan DeFi terdesentralisasi dengan anonimitas dan keamanan tinggi. Anda tidak perlu khawatir tentang kebocoran informasi pribadi atau verifikasi KYC. Pengguna dapat dengan bebas menyimpan dan menarik aset mereka. Produk keuangan terdesentralisasi yang dibeli di situs ini menawarkan pengembalian yang tinggi dan stabilitas, dengan layanan yang mudah dan cepat.",
  "context2": "(Tentang aplikasi keuangan terdesentralisasi DeFi)",
  "context3": "Keuangan Terdesentralisasi (DeFi) adalah salah satu aplikasi teknologi blockchain dalam Fintech. DeFi menciptakan sistem keuangan yang terbuka dan transparan melalui platform blockchain terdesentralisasi dan open-source.",
  "context4": "DeFi merujuk pada penggunaan perangkat lunak open-source dan jaringan terdesentralisasi untuk mengubah produk keuangan tradisional menjadi protokol yang tidak bergantung pada pihak ketiga dan transparan, yang beroperasi tanpa perantara yang tidak perlu. DeFi sendiri adalah konsep dan arsitektur yang berfokus pada 'desentralisasi', artinya tidak ada administrator, setiap pengguna setara, dan memiliki hak yang sama. Jika ada yang ingin mengubah isi, itu harus disetujui oleh semua orang. Oleh karena itu, karakteristik DeFi mencakup kepemilikan terdistribusi, tidak dimiliki oleh siapa pun, dan sulit untuk dimanipulasi. DeFi juga memiliki keuntungan blockchain, seperti transparansi, ketahanan terhadap sensor, dan ketidakberubahan.",
  "context5": "DeFi memiliki banyak keuntungan, termasuk dasar yang tidak bergantung pada kepercayaan, tidak memerlukan izin institusional, dan kontrol aset ada di tangan individu. Keuntungan utamanya adalah menghilangkan 'pihak ketiga yang dapat dipercaya' melalui desentralisasi, menjaga transparansi buku besar dan ketidakberubahan.",
  "context6": "Perbedaan terbesar antara DeFi dan Fintech terletak pada mekanisme 'kepercayaan' mereka. Fintech menerapkan teknologi blockchain dalam sistem keuangan yang ada, namun tetap bergantung pada institusi terpusat, dan semua operasi dikendalikan oleh manusia, memperoleh kepercayaan pengguna melalui mekanisme pihak ketiga. Sebaliknya, fitur utama DeFi adalah desentralisasi, yang menggunakan kontrak pintar. Kode sumbernya terbuka dan logika inti dijalankan di blockchain, membuatnya tidak dapat diubah dan dapat dipaksakan, sehingga pengguna dapat langsung mempercayai strukturnya.",
  "context7": "Aplikasi DeFi yang terlihat termasuk protokol perdagangan terdesentralisasi, stablecoin, pinjaman, turunan, dana, pasar prediksi, protokol tokenisasi, KYC/AML, dan verifikasi identitas.",
  "title36": "Setoran",
  "title37": "Jaringan",
  "title38": "Jumlah",
  "title39": "Setoran",
  "title40": "Gunakan alamat transfer setoran dompet atau bursa. Setelah top-up selesai, jumlah akan otomatis ditambahkan ke akun Anda.",
  "title41": "Penarikan",
  "title42": "Alamat Dompet",
  "title43": "Jaringan",
  "title44": "Jumlah",
  "title45": "Saldo Tersedia",
  "title46": "Tips Keamanan: Harap isi alamat penarikan Anda dengan benar, aset tidak dapat dikembalikan setelah ditransfer.",
  "title47": "Penarikan",
  "title48": "Masukkan alamat dompet",
  "title49": "5-1000000",
  "title50": "Transfer Internal",
  "title51": "Akun",
  "title52": "Anggota VIP Dapat Menggunakan Transfer Satu Klik Tanpa Kunci",
  "title53": "Penerima memerlukan minimal VIP1",
  "title54": "Biaya Layanan",
  "title55": "Transfer Internal",
  "title56": "Beli",
  "title57": "Setoran",
  "title58": "Aturan Tingkat",
  "title59": "Setoran",
  "title60": "Batas Pengumpulan Harian",
  "title61": "Batas Pengumpulan Bulanan",
  "title62": "Pengguna Harus Membayar Setoran untuk Menggunakan Layanan VIP. VIP1 Berlaku Selama 1 Bulan. Setelah Kedaluwarsa, Akan Dibatalkan Secara Otomatis. Pengguna VIP2 dan Di Atas, Setelah 2 Bulan Penggunaan, Dapat Menghubungi Layanan Pelanggan untuk Mengembalikan Setoran. Untuk Detail Lebih Lanjut, Harap Lihat Pusat Bantuan.",
  "title63": "Rekaman",
  "title64": "Upgrade Keanggotaan",
  "title65": "VIP1 perlu menunggu 30 hari untuk pengembalian otomatis (tidak dapat mengajukan pengembalian).",
  "title66": "VIP2-VIP6 dapat menghubungi layanan pelanggan untuk mengajukan pengembalian dana. Setelah pengembalian berhasil, setoran akan dikembalikan ke saldo Anda dan tingkat VIP akan dibatalkan.",
  "title67": "Notifikasi Pesan",
  "title68": "Halo! Selamat datang masuk!",
  "title69": "Akun",
  "title70": "Kata Sandi",
  "title71": "Harap masukkan nama pengguna atau alamat email Anda",
  "title72": "Harap masukkan kata sandi Anda",
  "title73": "Masukkan kode captcha",
  "title74": "Masuk",
  "title75": "Tidak punya akun?",
  "title76": "DAFTAR",
  "title77": "LUPA KATA SANDI",
  "title78": "Kata Sandi",
  "title79": "Kata Sandi",
  "title80": "Konfirmasi Kata Sandi",
  "title81": "KUNCI",
  "title82": "Harap masukkan KUNCI Anda",
  "title83": "Harap konfirmasi kata sandi lagi",
  "title84": "Harap masukkan kata sandi Anda",
  "title85": "Konfirmasi",
  "title86": "Buat Akun",
  "title87": "Nama Pengguna",
  "title88": "Harap masukkan nama pengguna Anda",
  "title89": "Email",
  "title90": "Harap masukkan alamat email Anda",
  "title91": "Negara",
  "title92": "Seluler",
  "title93": "Harap masukkan nomor telepon",
  "title94": "Daftar",
  "title95": "MASUK",
  "title96": "Sudah punya akun?",
  "title97": "Beli Cepat",
  "title98": "Beli Tether (USDT) dengan kartu kredit atau debit di Paxful",
  "title99": "Beli Tether (USDT) dengan kartu kredit atau debit di ChangeNow",
  "title100": "Beli Tether (USDT) dengan kartu kredit atau debit di Changelly",
  "title101": "Beli Tether (USDT) dengan kartu kredit atau debit di Binance Exchange",
  "title102": "Beli Tether (USDT) dengan kartu kredit atau debit di Bybit Exchange",
  "title103": "Beli Tether (USDT) dengan kartu kredit atau debit di OKX Exchange",
  "title104": "Pusat Bantuan",
  "title105": "1. Instruksi penggunaan platform",
  "title106": "2. Cara mengaktifkan VIP",
  "title107": "3. Transfer VIP (tidak memerlukan Kunci)",
  "title108": "4. Bagaimana cara berinvestasi?",
  "title109": "5. Bagaimana cara menarik uang?",
  "title110": "6. Apa yang harus dilakukan jika saya lupa Kunci?",
  "title111": "7. Bagaimana cara mengubah kata sandi, email?",
  "title112": "8. Bagaimana cara mendaftar akun?",
  "title113": "9. Bagaimana cara berinvestasi?",
  "title114": "10. Layanan investasi VIP",
  "title115": "Kirim",
  "title116": "Harap Masukkan Kata Sandi Kunci Anda",
  "title117": "Harap masukkan alamat dompet Anda",
  "title118": "Harap masukkan jumlah penarikan",
  "title119": "Gunakan alamat setoran transfer dompet atau bursa. Setelah setoran selesai, jumlah akan otomatis ditambahkan ke akun Anda.",
  "title120": "Mengirim",
  "title121": "Jumlah setoran minimum tidak boleh kurang dari 50",
  "title122": "Ajukan Pengembalian Dana",
  "title123": "Harap masukkan jumlah yang ingin Anda transfer",
  "title124": "Harap masukkan nama pengguna",
  "title125": "Gunakan alamat setoran transfer dompet atau bursa. Setelah setoran selesai, jumlah akan otomatis ditambahkan ke akun Anda.",
  "title126": "Kode QR Dompet",
  "title127": "Menunggu",
  "title128": "Harap masukkan nomor telepon Anda",
  "title129": "Harap masukkan alamat email Anda",
  "title130": "Harap masukkan kata sandi",
  "title131": "Harap masukkan kata sandi lagi",
  "title132": "Kata sandi yang dimasukkan tidak cocok",
  "title133": "Harap masukkan kata sandi kunci",
  "title134": "Harap masukkan kata sandi kunci lagi",
  "title135": "Kata sandi kunci yang dimasukkan tidak cocok",
  "title136": "Harap masukkan kode verifikasi gambar",
  "title137": "Pendaftaran Gagal",
  "title138": "Harap masukkan kata sandi login",
  "title139": "Sedang login",
  "title140": "Harap masukkan kata sandi Anda lagi",
  "title141": "Harap masukkan kata sandi kunci",
  "title142": "Kata sandi yang dimasukkan tidak cocok",
  "title143": "Daftar Transfer",
  content1: `Tim USDT telah resmi memasuki platform layanan perdagangan aset digital.
Dengan desain teknis yang andal dan dapat diskalakan, layanan berkualitas tinggi, dan lebih banyak dukungan, platform perdagangan aset digital USDT bertujuan untuk memberikan pengguna khususnya layanan transaksi pertukaran USDT yang lebih nyaman, aman, dan berkualitas tinggi, serta aset blockchain yang lebih baik. Pelanggan VIP global menerima layanan setoran, transfer, penarikan, manajemen keuangan, dan investasi. Manajemen keuangan VIP eksklusif, investasi, dan layanan pelanggan 24 jam.
1. Platform terbuka untuk pendaftaran pengguna. Siapa saja dapat mendaftar akun mereka.
2. Level VIP dari akun yang baru terdaftar adalah 0 (penyimpanan, transfer, penarikan, dll.). Semua ini gratis, dan Anda tidak perlu meningkatkan akun untuk mengoperasikannya. Jika akun Anda perlu menerima transfer dari orang lain, Anda harus menggunakan batas pembayaran (pada saat ini, akun level 0 tidak dapat menerima pembayaran), dan Anda perlu meningkatkan level akun Anda ke VIP untuk menggunakan fungsi pembayaran; semakin tinggi levelnya, batas harian dan bulanan setiap orang akan berbeda. Silakan upgrade sesuai kebutuhan.
3. Kami juga dapat menyediakan berbagai layanan seperti pertukaran mata uang dan pinjaman hipotek USDT untuk pelanggan VIP.`,

content2: `1. Klik tombol VIP
2. Pilih level VIP yang ingin Anda beli
3. Bayar deposit VIP dan beli dengan sukses
4. Deposit yang diperlukan untuk level VIP (Anda dapat membatalkan kapan saja dan mendapatkan deposit Anda kembali)
Deposit VIP1: 50 USDT (VIP1 adalah layanan pengalaman pemula, berlaku selama 1 bulan, deposit tidak dapat dikembalikan dan tidak dapat dibatalkan)
Deposit VIP2: 1000 USDT
Deposit VIP3: 3000 USDT
Deposit VIP4: 30000 USDT
Deposit VIP5: 50000 USDT
Deposit VIP6: 100000 USDT
Level VIP dari akun yang baru terdaftar adalah 0 (penyimpanan, transfer, penarikan, dll.). Semua ini gratis, dan Anda tidak perlu meningkatkan akun untuk mengoperasikannya. Jika akun Anda perlu menerima transfer dari orang lain, Anda harus menggunakan batas pembayaran (pada saat ini, akun level 0 tidak dapat menerima pembayaran), dan Anda perlu meningkatkan level akun Anda ke VIP untuk menggunakan fungsi pembayaran; semakin tinggi levelnya, batas harian dan bulanan setiap orang akan berbeda. Silakan upgrade sesuai kebutuhan.
VIP1: Batas pembayaran 10 USDT per hari dan 30 USDT per bulan
VIP2: Batas pembayaran 100 USDT per hari dan 3000 USDT per bulan
VIP3: Batas pembayaran 30000 USDT per hari dan 900000 USDT per bulan
VIP4: Batas pembayaran 100000 USDT per hari dan 3000000 USDT per bulan
VIP5: Batas pembayaran 200000 USDT per hari dan 6000000 USDT per bulan
VIP6: Batas pembayaran 2000000 USDT per hari dan 60000000 USDT per bulan`,

content3: `Catatan: VIP dapat menggunakan fungsi transfer platform. Transfer hanya dapat dilakukan antar VIP platform (transfer yang berhasil tidak dapat dibatalkan)
1. Klik tombol transfer
2. Isi nama pengguna VIP, alamat email, dan nomor telepon dengan benar
3. Klik Konfirmasi untuk menyelesaikan transfer (tidak perlu memasukkan KEY)
4. Jika akun Anda perlu menerima transfer dari orang lain, Anda harus menggunakan batas pembayaran
VIP1: Batas pembayaran 10 USDT per hari dan 30 USDT per bulan
VIP2: Batas pembayaran 100 USDT per hari dan 3000 USDT per bulan
VIP3: Batas pembayaran 30000 USDT per hari dan 900000 USDT per bulan
VIP4: Batas pembayaran 100000 USDT per hari dan 3000000 USDT per bulan
VIP5: Batas pembayaran 200000 USDT per hari dan 6000000 USDT per bulan
VIP6: Batas pembayaran 2000000 USDT per hari dan 60000000 USDT per bulan
Contoh batas transfer: Jika akun Anda adalah VIP5
VIP5 transfer ke VIP1 (10USDT)
VIP5 transfer ke VIP2 (100USDT)
VIP5 transfer ke VIP3 (30000USDT)
VIP5 transfer ke VIP4 (100000USDT)
VIP5 transfer ke VIP5 (200000USDT)
VIP6 transfer ke VIP6 (2000000USDT)`,

content4: `1. Klik tombol deposit
2. Pilih jumlah yang akan disetor
3. Klik Konfirmasi, masukkan (KEY)
4. Anda akan mendapatkan alamat deposit khusus untuk akun Anda, yang dapat Anda transfer dari bursa atau dompet manapun.
5. Jumlah deposit Anda akan otomatis ditambahkan ke saldo akun Anda`,

content5: `1. Klik tombol Penarikan
2. Masukkan alamat penarikan USDT (TRC20)
3. Masukkan jumlah yang akan ditarik
4. Klik tombol Konfirmasi dan masukkan KEY
5. Penarikan berhasil dan dana Anda akan sampai dalam 2 jam.`,

content6: `1. KEY diatur oleh Anda sendiri dan tidak dapat diperoleh oleh orang lain. KEY bersifat unik dan tidak dapat direset.
2. KEY adalah satu-satunya bukti untuk akun Anda. Tidak dapat diubah. Tidak dapat dipulihkan.
3. Jika Anda lupa KEY dan akun Anda masih memiliki dana untuk ditarik, Anda dapat mendaftar akun baru dan upgrade VIP, kemudian mentransfer dana dari akun lama Anda ke akun baru untuk penarikan. Tidak diperlukan KEY untuk transfer antar platform VIP
KEY adalah satu-satunya identifikasi untuk akun Anda dan tidak dapat diubah atau dipulihkan. Untuk melindungi privasi pelanggan, platform tidak akan mencatat nomor akun, kata sandi, nomor telepon, alamat email, atau KEY Anda. Harap jaga informasi login Anda dengan aman`,

content7: `1. Klik beranda saya
2. Klik tombol Pusat Keamanan
3. Pilih kata sandi atau email yang perlu diubah
4. Masukkan KEY untuk menyelesaikan`,

content8: `(Catatan: Saat mendaftar, harap jangan membuka perangkat lunak terjemahan. Jika Anda membuka perangkat lunak terjemahan, tombol tidak akan dikonfirmasi. Jika Anda tidak dapat mendaftar, harap ubah browser Anda)
1. Klik tombol pendaftaran di beranda
2. Klik untuk mendaftar
3. Isi informasi pendaftaran Anda
4. Harap simpan KEY dengan baik saat pengaturannya, karena tidak dapat diubah atau dipulihkan (kehilangan dapat menyebabkan kerugian aset)`,

content9: `Simpan dengan aman dan hasilkan dengan mudah
1. Jumlah penyimpanan minimum: 5000 USDT (akun dengan saldo di bawah 5000 tidak dapat mengakses layanan keuangan)
2. Aset Anda akan menerima penghasilan sesuai dengan tingkat yang berbeda, dan total penghasilan di setiap tingkat akan dihitung bersama-sama.
3. Pendapatan tahunan yang diharapkan akan disesuaikan kapan saja berdasarkan pendapatan aktual dari pool pertambangan.
4. Pendapatan akan dihitung satu hari setelah PoS dibuka.
5. Tidak perlu membekukan aset hipotek, Anda dapat melakukan perdagangan atau menarik koin kapan saja.
Ini adalah tanggal jaminan. Penghasilan akan dimulai pada T+1 (hari berikutnya) dan akan didistribusikan ke akun Anda pada T+2.
Tidak ada pendapatan yang akan dihasilkan pada hari Anda melepaskan aset yang dijaminkan. Pendapatan akan didistribusikan ke akun spot Anda setiap hari. Manajemen keuangan saat ini dapat dibatalkan kapan saja, dan pendapatan akan diselesaikan hingga hari sebelum keluar dari PoS.
Untuk produk lain, ketika periode penyimpanan minimum tercapai, Anda dapat memilih untuk menebus atau melanjutkan penyimpanan. Pendapatan akhir akan dihitung berdasarkan jumlah hari penyimpanan yang sebenarnya, dan pokok akan dibuka pada hari pengembalian uang.
Di antara produk keuangan yang disediakan, Anda dapat memilih dari berbagai aset digital (seperti Bitcoin, Ethereum) dan stablecoin.`,

content10: `1. Terima kasih telah menjadi anggota kami, kami akan memberikan layanan dan dukungan yang lebih baik dengan sepenuh hati!
2. Anda akan mendapatkan layanan transfer dan koleksi
3. Anda akan menikmati tarif VIP eksklusif dan biaya transaksi yang lebih menguntungkan.
4. Anda akan menerima lencana VIP eksklusif untuk menunjukkan identitas dan status Anda.
5. Anda akan bergabung dengan grup layanan VIP eksklusif kami dan menerima dukungan pelanggan lebih lanjut.
6. Anda akan menerima laporan industri reguler untuk memahami dinamika pasar dan peluang investasi.
7. Anda akan menikmati manfaat VIP eksklusif dan keuntungan hadiah yang dipersonalisasi, memberi Anda perhatian kami.
8. Anda akan memiliki kesempatan untuk berpartisipasi dalam kegiatan VIP eksklusif dan bertemu lebih banyak investor sukses.
9. Anda akan memiliki saluran layanan pelanggan VIP 24 jam dan mendapatkan dukungan solusi yang lebih cepat dan prioritas.
10. Anda akan mendapatkan saluran hijau independen dan menikmati layanan deposit dan penarikan yang lebih mudah.` 

}









  
    
  
  };
  

  
  export default messages;