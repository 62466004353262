import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vant from "vant";
import 'vant/lib/index.less'
import { Locale } from 'vant';
// 引入英文语言包
import enUS from 'vant/es/locale/lang/en-US';
import setRem from './util/rem'
import VueI18n from 'vue-i18n';
import messages from '@/util/language.js'; 

console.log("messages",messages);
setRem();
window.addEventListener('resize', setRem);
Locale.use('en-US', enUS);
Vue.config.productionTip = false;

 Vue.use(vant);
 Vue.use(VueI18n);
console.log("messages",messages)

const i18n = new VueI18n({
  locale: 'en',  // 默认语言
  messages
});
new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
