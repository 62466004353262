import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/my",
    name: "My",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/my.vue"),
  },
  {
    path: "/settings",
    name: "Settings",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/setting.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/language",
    name: "language",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/language.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/login.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/register.vue"),
  },
  {
    path: "/rechargeview",
    name: "rechargeview",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/rechargeView.vue"),
  },
  {
    path: "/tranfer",
    name: "tranfer",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/tranfer.vue"),
  },
  {
    path: "/buynow",
    name: "buynow",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/buyNow.vue"),
  },
  {
    path: "/security",
    name: "security",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/security_center.vue"),
  },
  {
    path: "/updatepwd",
    name: "updatepwd",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/pwdSetting.vue"),
  },
  {
    path: "/updateEmail",
    name: "updateEmail",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/emailSetting.vue"),
  },
  // {
  //   path: "/taskhall",
  //   name: "Taskhall",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/TaskHall.vue"),
  // },
  // {
  //   path: "/businesshall",
  //   name: "BusinessHall",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/BusinessHall.vue"),
  //     children:[{
  //       path: 'step',
  //       name: "Step",
  //       component: () =>import(/* webpackChunkName: "about" */ "../views/BusinessHallStep.vue"),
  //     }]
  // },
  {
    path: "/vip",
    name: "VIP",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/vip2.vue"),
  }, 
  {
    path: "/vrecord",
    name: "VRECORD",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/vipRecord.vue"),
  }, 
  {
    path: "/message",
    name: "message",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/message.vue"),
  }, 
  // {
  //   path: "/task",
  //   name: "Task",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/task.vue"),
  // }, 
  // {
  //   path: "/person",
  //   name: "person",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/person.vue"),
  // },
  // {
  //   path:"/dailyStatement",
  //   name:"dailyStatement",
  //   component: () =>
  //   import(/* webpackChunkName: "about" */ "../views/dailyStatement.vue")
  // },
  // {
  //   path:"/team",
  //   name:"team",
  //   component: () =>
  //   import(/* webpackChunkName: "about" */ "../views/teamReport.vue")
  // },
  // {
  //   path:"/invite",
  //   name:"invite",
  //   component: () =>
  //   import(/* webpackChunkName: "about" */ "../views/invite.vue")
  // },
  {
    path:"/recharge",
    name:"recharge",
    component: () =>
    import(/* webpackChunkName: "about" */ "../views/recharge.vue")
  },
  {
    path:"/wallet",
    name:"wallet",
    component: () =>
    import(/* webpackChunkName: "about" */ "../views/wallet.vue")
  },
  {
    path:"/withdraw",
    name:"withdraw",
    component: () =>
    import(/* webpackChunkName: "about" */ "../views/withdraw.vue")
  },
  {
    path:"/investment",
    name:"Investment",
    component: () =>
    import(/* webpackChunkName: "about" */ "../views/investment.vue")
  },
  // {
  //   path:"/taskdetail",
  //   name:"Taskdetail",
  //   component: () =>
  //   import(/* webpackChunkName: "about" */ "../views/task_detail.vue")
  // },
  {
    path:"/record",
    name:"Record",
    component: () =>
    import(/* webpackChunkName: "about" */ "../views/invest_record.vue")
  },
  {
    path:"/help",
    name:"Help",
    component: () =>
    import(/* webpackChunkName: "about" */ "../views/help.vue")
  },
  {
    path:"/reset",
    name:"reset",
    component: () =>
    import(/* webpackChunkName: "about" */ "../views/retrieve_pwd.vue")
  },
  {
    path:"/rrecord",
    name:"rrecord",
    component: () =>
    import(/* webpackChunkName: "about" */ "../views/recharge_records.vue")
  },
  {
    path:"/wrecord",
    name:"wrecord",
    component: () =>
    import(/* webpackChunkName: "about" */ "../views/withdraw_records.vue")
  },
  // {
  //   path:"/profit",
  //   name:"profit",
  //   component: () =>
  //   import(/* webpackChunkName: "about" */ "../views/profit.vue")
  // },
  // {
  //   path:"/notice",
  //   name:"notice",
  //   component: () =>
  //   import(/* webpackChunkName: "about" */ "../views/notice.vue")
  // },
  // {
  //   path:"/noticedetail",
  //   name:"noticedetail",
  //   component: () =>
  //   import(/* webpackChunkName: "about" */ "../views/notice_detail.vue")
  // }                     
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
